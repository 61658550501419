/*export const  GLOBAL = {
    url: 'http://estadistica2.sepen.gob.mx/WSSIC/'
}*/

export let GLOBAL = {
  //Produccion
  //url: 'https://agenda.nayarit.gob.mx/SAG_V4/restful/index.php/',
  //urlPDF: 'https://agenda.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga.php',

  //Desarrollo
  //url: 'https://lab.cgti.nayarit.gob.mx/SAG_V4/restful/index.php/',
  //urlPDF: 'https://lab.cgti.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga.php',

  //Miuestras
  //url: 'http://localhost/SAG_V4/restful/index.php/',
  //urlPDF: 'http://localhost/SAG_V4/restful/reporteAgenda/descarga.php',

  //Local
  // url: 'http://localhost/SAG_V4/restful/index.php/',
  // urlPDF: 'http://localhost/SAG_V4/restful/reporteAgenda/descarga.php',

  //local XAMPP
  //url: 'http://192.168.64.2/SAG_V4/restful/index.php/',
  //urlPDF: 'http://192.168.64.2/SAG_V4/restful/reporteAgenda/descarga.php',

  //Produccion IPLANAY
  //url: 'https://sag.iplanay.gob.mx/SAG_V4/restful/index.php/',
  //urlPDF: 'https://sag.iplanay.gob.mx/SAG_V4/restful/reporteAgenda/descarga.php',

  //Produccion NAYARIT
  url: 'https://sag.nayarit.gob.mx/SAG_V4/restful/index.php/',
  urlPDF: 'https://sag.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga.php',

  //url: 'https://sag.nayarit.gob.mx/restful/index.php/'
  //url: 'http://tpo.nayarit.gob.mx/SAG_V4/restful/index.php/'
  //url: "http://localhost/SAG_V4/restful/index.php/"
};

export let imgGlobal = {
  //Produccion
  //url: 'https://agenda.nayarit.gob.mx/SAG_V4/restful/uploads/'

  //Desarrollo
  url: 'http://lab.cgti.nayarit.gob.mx/SAG_V4/restful/uploads/'

  //url: 'https://sag.nayarit.gob.mx/restful/uploads/'
  //url: 'http://tpo.nayarit.gob.mx/SAG_V4/restful/uploads/'
  //url: "http://localhost/SAG_V4/restful/uploads/"
};
