import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prensa-page',
  templateUrl: './prensa-page.component.html',
  styles: []
})
export class PrensaPageComponent implements OnInit { 
  titulo = 'Apoyo Técnico';
  control = 'apoyo';
  agendado = true;

  constructor() { }

  ngOnInit() {
  }

}
