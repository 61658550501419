import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracion-page',
  templateUrl: './configuracion-page.component.html'
})
export class ConfiguracionPageComponent implements OnInit {

  titulo: string = 'Pagina de Configuraciones';

  constructor() { }

  ngOnInit() {
  }

}
