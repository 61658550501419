import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-dia-privada-contenedor',
  templateUrl: './detalle-dia-privada-contenedor.component.html'
})
export class DetalleDiaPrivadaContenedorComponent implements OnInit {
  fecha: string;

  constructor() {
    this.fecha = localStorage.getItem("fechaA");
    //alert(this.fecha);
  }

  ngOnInit() {
  }

}
