import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { Funcionario } from '../../../models/Funcionario';
import { Estadocivil } from '../../../models/estadocivil';
import { respuesta } from '../../../models/respuesta';
import { FuncionarioService } from '../../../services/funcionario.service';
import { EstadoCivilService } from '../../../services/estadocivil.service';
import { Localidad } from '../../../models/localidad';
import { DependenciaService } from '../../../services/dependencia.service';
import { Dependencia } from '../../../models/dependencia';
import { Persona } from '../../../models/persona';
import { Usuario } from '../../../models/Usuario';
import { UsuarioService } from '../../../services/usuario.service';
import { Secretaria } from '../../../models/secretaria';
import { SecretariaService } from '../../../services/secretaria.service';

@Component({
  selector: 'app-alta-persona',
  templateUrl: './alta-persona.component.html'
})
export class AltaPersonaComponent implements OnInit, OnChanges {
  @Input() tipo: string;
  @Input() escojeTipo: boolean;
  @Input() modificar: boolean = false;
  @Input() usuario: Usuario = new Usuario();
  @Output() getPersona: EventEmitter<Usuario> = new EventEmitter();

  titulo = 'Control de Personas';
  subtitulo: string;
  activaTablePer = false;
  tablaPer: any;

  tipoPersona = '';

  persona: Usuario;

  funcionario: Funcionario;
  funcionarios: Funcionario[] = [];

  estadosciviles: Estadocivil[] = [];

  dependencia: Dependencia;
  dependencias: Dependencia[] = [];

  secretaria: Secretaria;
  secretarias: Secretaria[] = [];

  respuesta: respuesta;

  constructor(
              private _funcionarioService: FuncionarioService,
              private _usuarioServece: UsuarioService,
              private _estadoCivilService: EstadoCivilService,
              private _dependenciasService: DependenciaService,
              private _secretariaService: SecretariaService) {
    this.funcionario = new Funcionario();
    this.respuesta = new respuesta();
    this.dependencia = new Dependencia();
    this.secretaria = new Secretaria();
    this.persona = new Usuario();
  

  }

  ngOnInit() {
    this.getEstadosCiviles();
    this.getDependencias();
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(changes);
    let date = new Date(this.usuario.fechanac);
    if( !(date instanceof Date && !isNaN(date.valueOf()) ) ) {
      this.usuario.fechanac = new Date().toJSON().split('T')[0];
    }


    if(this.usuario.admin==='1'){
      this.usuario.admin = true;
    }else{
      this.usuario.admin = false;
    }

    if(this.usuario.oficialia==='1'){
      this.usuario.oficialia = true;
    }else{
      this.usuario.oficialia = false;
    }

    if(this.usuario.tecnica==='1'){
      this.usuario.tecnica = true;
    }else{
      this.usuario.tecnica = false;
    }

    if(this.usuario.privada==='1'){
      this.usuario.privada = true;
    }else{
      this.usuario.privada = false;
    }

    if(this.usuario.app==='1'){
      this.usuario.app = true;
    }else{
      this.usuario.app = false;
    }

    console.log(this.usuario);
  }

  getEstadosCiviles() {
    this._estadoCivilService.getEstados().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.estadosciviles = this.respuesta.data;
          //console.log(this.estadosciviles);
        }
      }
    );
  }

  getDependencias() {
    this._dependenciasService.getDependencias().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.dependencias = this.respuesta.data;
          
          this._secretariaService.secretaria.iddependencia = this.usuario.iddependencia;
          this.getSecretarias2();
          //console.log(this.dependencias);
        }
      }
    );
  }

  getSecretarias(event) {
    //console.log(event);
    this._secretariaService.secretaria.iddependencia = event.target.value;
    this.getSecretarias2();
  }

  getSecretarias2() {
    this._secretariaService.getSecretariasDependencia().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.secretarias = this.respuesta.data;
          //console.log(this.dependencias);
        }
      }
    );
  }

  guardaPersona() {
    
    if(this.modificar){
      //alert('Modificar');
      this.modificarUsu();
    }else{
      //alert('ALTA ');
      this.guardarUsu();
    }
    
  }

  guardarUsu(){
    if(this.tipo==='CIUDADANO'){
      this._funcionarioService.usuario = this.usuario;
      this._funcionarioService.guardaCiudadano().subscribe(
        data => {
          this.respuesta = data;
          if ( this.respuesta.error ) {
            //alert(this.respuesta.mensaje);
            swal("Error", this.respuesta.mensaje, "error");
          } else {
            swal("Guardado", this.respuesta.mensaje, "success");
            this.persona = this.respuesta.data[0];
            //console.log(this.funcionario);
            this.getPersonaCapturada();
          }
        }
      );
    }else if (this.tipo==='FUNCIONARIO'){
      this._funcionarioService.usuario = this.usuario;
      this._funcionarioService.guardaFuncionario().subscribe(
        data => {
          this.respuesta = data;
          if ( this.respuesta.error ) {
            //alert(this.respuesta.mensaje);
            swal("Error", this.respuesta.mensaje, "error");
          } else {
            swal("Guardado", this.respuesta.mensaje, "success");
            this.persona = this.respuesta.data[0];
            //console.log(this.funcionario);
            this.getPersonaCapturada();
          }
        }
      );
    }else{
      this._usuarioServece.usuario = this.usuario;
      this._usuarioServece.guardaUsuario().subscribe(
        data => {
          this.respuesta = data;
          if ( this.respuesta.error ) {
            //alert(this.respuesta.mensaje);
            swal("Error", this.respuesta.mensaje, "error");
          } else {
            swal("Guardado", this.respuesta.mensaje, "success");
            this.persona = this.respuesta.data[0];
            //console.log(this.funcionario);
            this.getPersonaCapturada();
          }
        }
      );
    }
  }

  modificarUsu(){
    if(this.tipo==='CIUDADANO'){
      this._funcionarioService.usuario = this.usuario;
      this._funcionarioService.guardaCiudadano().subscribe(
        data => {
          this.respuesta = data;
          if ( this.respuesta.error ) {
            //alert(this.respuesta.mensaje);
            swal("Error", this.respuesta.mensaje, "error");
          } else {
            swal("Guardado", this.respuesta.mensaje, "success");
            this.persona = this.respuesta.data[0];
            //console.log(this.funcionario);
            this.getPersonaCapturada();
          }
        }
      );
    }else if (this.tipo==='FUNCIONARIO'){
      this._funcionarioService.usuario = this.usuario;
      this._funcionarioService.guardaFuncionario().subscribe(
        data => {
          this.respuesta = data;
          if ( this.respuesta.error ) {
            //alert(this.respuesta.mensaje);
            swal("Error", this.respuesta.mensaje, "error");
          } else {
            swal("Guardado", this.respuesta.mensaje, "success");
            this.persona = this.respuesta.data[0];
            //console.log(this.funcionario);
            this.getPersonaCapturada();
          }
        }
      );
    }else{
      this._usuarioServece.usuario = this.usuario;
      this._usuarioServece.modificaUsuario().subscribe(
        data => {
          this.respuesta = data;
          if ( this.respuesta.error ) {
            //alert(this.respuesta.mensaje);
            swal("Error", this.respuesta.mensaje, "error");
          } else {
            swal("Guardado", this.respuesta.mensaje, "success");
            this.persona = this.respuesta.data[0];
            //console.log(this.funcionario);
            this.getPersonaCapturada();
          }
        },
        err =>{
          swal("Error " + err.status, err.statusText, "error");
          console.log(err);
        }
      );
    }
  }


  getPersonaCapturada() {
    this.getPersona.emit(this.persona);
  }

  localidadSeleccionada(event) {
    //console.log(event);
    this.funcionario.idestado = event.idestado;
    this.funcionario.idlocalidad = event.idlocalidad;
    this.funcionario.idmunicipio = event.idmunicipio;
    //console.log(this.funcionario);
  }
}
