
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { secretarioModel } from '../models/secretario';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';
import { Funcionario } from '../models/Funcionario';

@Injectable()
export class SecretarioService {
  url: string = "";
  funcionario: Funcionario;
  funcionarios: Funcionario[] = [];
  resp: respuesta;
  headers:Headers = new Headers;

  //login
  email:string;
  psw:string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.funcionario = new  Funcionario();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }


  getFuncionarios(): Observable<respuesta> {
    try {
      let params = "iddependencia=" + this.funcionario.iddependencia;
      return this.http.post(this.url + "Funcionarios/getFuncionarios", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getFuncionariosPorDependencia(): Observable<respuesta> {
    try {
      let params = "iddependencia=" + this.funcionario.iddependencia;
      return this.http.post(this.url + "Funcionarios/getFuncionariosPorDependencia", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  agregarFuncioarios(): Observable<respuesta> {
    try {
      let params = "nombre=" + this.funcionario.nombre;

      return this.http.post(this.url + "Funcionario/guardaFuncionario", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificarSecretario(): Observable<respuesta> {
    try {
      let params = '';
      //let params = "secretario=" + this.objSecretario.secretario;
      //params += "&idsecretario=" + this.objSecretario.idsecretario;

      return this.http.post(this.url + "Secretarios/modificaSecretario", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaSecretario(): Observable<respuesta>{
    try {
      let params = '';
      //let params = "idsecretario=" + this.objSecretario.idsecretario;
      return this.http.post(this.url + "Secretarios/borrarSecretario", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  activaSecretario(): Observable<respuesta>{
    try {
      let params = '';
      //let params = "idsecretario=" + this.objSecretario.idsecretario;
      return this.http.post(this.url + "Secretarios/activarSecretario", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }



}
