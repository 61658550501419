import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { respuesta } from '../../models/respuesta';
import { oficioModel } from '../../models/oficio';
import { OficiosService } from '../../services/oficio.service';
import { GLOBAL } from '../../services/global';
import { agendaModel } from '../../models/agenda';
import { AgendaService } from '../../services/agenda.service';
import { DependenciaService } from '../../services/dependencia.service';
import { SecretarioService } from '../../services/secretario.service';
import { notificacion } from '../../models/notificacion';
import { Ciudadano } from '../../models/Ciudadano';
import { Funcionario } from '../../models/Funcionario';
import { Persona } from '../../models/persona';
import { Localidad } from '../../models/localidad';
import { DomSanitizer } from '@angular/platform-browser';

//import * as swal from 'sweetalert';
import { Dependencia } from '../../models/dependencia';
import { secretarioModel } from '../../models/secretario';
import { FuncionarioService } from '../../services/funcionario.service';
import { Usuario } from '../../models/Usuario';
import { SecretariaService } from '../../services/secretaria.service';
import { Secretaria } from '../../models/secretaria';
import { Turno } from '../../models/turno';



@Component({
  selector: 'app-oficialia',
  templateUrl: './oficialia.component.html'
})
export class OficialiaComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() titulo: string;
  @Input() control: string;
  @Input() agendado: boolean;
  @Input() consulta: string='';
  @Input() idpersona: string='';
  @Input() idSecretariaUsuario: string='';

  asiste:string[] = ['SI', 'NO', 'SIN CONFIRMAR'];

  catOficios:string[] = ['GENERAL','INFORMATIVO', 'SOLICITUD', 'EVENTO', 'AUDITORIA', 'INVITACION', 'AUDIENCIA'];

  cargarLocalidad: boolean = true;

  escogeTipo: boolean =false;
  crearPersonas = true;

  dependencias: Dependencia[] = [];
  secretarias: Secretaria[] = [];
  funcionarios: Usuario[] = [];

  ciudadanoDesc = 'CIUDADANO';
  funcionarioDesc = 'FUNCIONARIO';

  ciudadado: Persona;
  funcionario: Usuario;
  representante: Usuario;
  funcionarioAsiste: Persona;

  tipoPersonas: string;

  idEstado = '18';
  idMunicipio = '0';
  idLocalidad = '0';

  tituloModal: string;
  imgModal: any = '';
  imgModalZan: any;
  imgModal2 = '';
  fecha: any;
  fechaF: any;
  muestraArchivo = false;

  fechaOficio: any;

  resp: respuesta;
  objOficio: oficioModel;
  arrOficios: oficioModel[] = [];

  objAgenda: agendaModel;

  imagenCargar: Array<File>;

  buscar: string;

  activa: boolean = false;
  tabla: any = null;

  horaAnte: string = '';

  dependencia = '';


  horaAux: Date;

  notificacion: notificacion;

  filtroTurnos: string = '';

  turno: string;

  turnos: Turno[] = [];

  guardando = false;
  consultando = false;

  usuarioActivo: Usuario;


  constructor(
    private oficioService: OficiosService,
    private _dependenciaService: DependenciaService,
    private _secretariaService: SecretariaService,
    private _funcionarioservice: FuncionarioService,
    private agendaService: AgendaService,
    public sanitizer: DomSanitizer) {

    let F = new Date();
    this.fecha  = F.getFullYear() + "/" + (F.getMonth()+1) + "/" + F.getDate();
    //alert(this.fecha + " -> " + new Date(this.fecha).toJSON());

    this.fechaOficio = new Date(this.fecha).toJSON().split('T')[0];
    this.fecha = new Date(this.fecha).toJSON().split('T')[0];

    let F2 = new Date();
    this.fechaF  = F2.getFullYear() + "/" + (F2.getMonth()+1) + "/" + F2.getDate();
    this.fechaF = new Date(this.fechaF).toJSON().split('T')[0];

    //this.fechaOficio = new Date();
    //this.fecha = new Date();

    this.objOficio = new oficioModel();
    this.objAgenda = new agendaModel();
    this.resp = new respuesta();
    this.objOficio.fecha = this.fecha;
    this.buscar = '';

    this.funcionario = new Usuario();
    this.funcionarioAsiste = new Persona();
    this.ciudadado = new Persona();
    this.representante = new Usuario();

    this.notificacion = new notificacion();

    this.imgModal2 = GLOBAL.url + '/Oficios/getImagenArchivoWeb/';

    this.usuarioActivo = new Usuario;
    this.usuarioActivo = JSON.parse(localStorage.getItem('usuario'));
    console.error(this.usuarioActivo);

    this.muestraArchivo = true;

    this.getSecretarias();


  }

  getImagenOficio(id) {
    //console.log('URL de Imagen: ' + this.imgModal2 + id);
    return this.imgModal2 + id;
  }

  getImagenOficio2(id) {
    // console.log('URL de Imagen: ' + this.imgModal2 + id + '?' + Math.random() * 100000000000000000000);
    //return this.imgModal2 + id + '?=' + Math.random() * 100000000000000000000;
    return this.imgModalZan = this.sanitizer.bypassSecurityTrustResourceUrl(this.imgModal2 + id + '?=' + Math.random() * 100000000000000000000);
  }

  ngOnInit() {

    //alert('Init');

    //console.log('Agendado: ' + this.agendado);
    this.activa=false;
    if(this.inicializaTabla()){
      this.consultaOficios();
      //this.consultaOficiosTodos();
      this.getDependencias();
      this.horaAux = new Date(this.objAgenda.hora);
    }

    $('#clockpickerHI1').clockpicker({
      placement: 'top',
      align: 'left',
      donetext: 'Seleccionar',
      'default': 'now',
      autoclose: true,
      afterDone: () => {
        console.log('done');
      }
    }).find('input').change((data) => {
      this.objAgenda.horaIni2 = data.currentTarget.value;
      console.log(this.objAgenda.horaIni2);

      this.objAgenda.horaFin2 = "";
    });

    $('#clockpickerHF1').clockpicker({
      placement: 'top',
      align: 'left',
      donetext: 'Seleccionar',
      'default': 'now',
      autoclose: true,
      afterDone: () => {
        console.log('done');
      }
    }).find('input').change((data) => {

      this.objAgenda.horaFin2 = data.currentTarget.value;
      console.log(this.objAgenda.horaFin2);
    });


  }

  ngOnChanges(changes: SimpleChanges) {
    //alert('changes');
    console.log(changes);
    //this.inicializaTabla();
    //alert('Changes');
    if(this.consulta===''){
      //this.consultaOficiosTodos();
      this.consultaOficios();
    }else{
      //alert('Faltantes');
      this.consultaOficiosFaltantes();
      this.fecha = '';
    }
    this.horaAux = new Date(this.objAgenda.hora);
    this.filtroTurnos = this.idSecretariaUsuario;
    console.log(this.idSecretariaUsuario);
  }

  ngAfterViewInit() {
    //alert('ya cargo');
    //init_plugins();
  }

  guardarOficio() {
    $('#altaModal').modal('hide');
    // alert(this.fechaOficio);
  }

  submitOficio(e){
    e.preventDefault();
    if(this.objOficio.idoficio==null){
      this.agregarOficio();
    }else{
      this.modificarOficio();
    }
  }

  altaOficio() {
    this.objOficio = new oficioModel();
    this.objOficio.fecha = this.fecha;
    this.funcionario = new Usuario();
    this.ciudadado = new Ciudadano();
    this.funcionarioAsiste = new Funcionario();
    this.tituloModal = 'Nuevo Oficio';
    $('#altaModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  vetTurnosDeOficio(id) {
    this.objOficio = this.arrOficios[id];
    this.oficioService.objOficio = this.objOficio;
    this.oficioService.getTurnos().subscribe(
      data => {
        this.resp = data;

        this.turnos = this.resp.data;

        if (this.resp.error) {
          swal("Turnos",  this.resp.mensaje, "info");
        }else{
          $('#turnosModal').modal('show');
        }

      },
      err => {
        swal("Error" + err.status, err.statusText, "error");
      }
    );
  }

  consultaOficios() {
    //console.log('Activa al Consultar: ' + this.activa);
    this.consulta = '';
    localStorage.removeItem('consulta');
    this.arrOficios = [];
    this.activa = false;
    if(this.tabla){
      this.tabla.destroy();
    }
    if (this.fecha == null) {
      this.fecha = this.fechaOficio;
    } else {
      this.fecha = new Date(this.fecha).toJSON().split('T')[0];
    }



    if(new Date(this.fecha).getTime() > new Date(this.fechaF).getTime()){
      this.fechaF = this.fecha;
    }
    this.fechaF = new Date(this.fechaF).toJSON().split('T')[0];
    this.objOficio.fecha = this.fecha;
    this.objOficio.fechaF = this.fechaF;
    this.oficioService.objOficio = this.objOficio;
    this.oficioService.buscar = this.buscar;
    this.oficioService.objOficio.turno = this.filtroTurnos;
    //alert("Consulta Oficios: " + this.filtroTurnos);
    //console.log('ArrOficios antes de Consultar: ' + this.arrOficios);

    this.consultando=true;
    this.oficioService.consultaOficios().subscribe(
      data => {
        this.resp = data;
        //this.arrOficios = [];
        this.arrOficios = this.resp.data;
        console.log('Oficios: ' + this.arrOficios);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.arrOficios.length === 0) {
          this.arrOficios = [];
          this.inicializaTabla();
        }*/
        this.getImagenes();
        //console.log('Datos: ' + data);
        setTimeout(() => {
          this.inicializaTabla();
        }, 300);
        this.consultando=false;
      },
      err => {
        swal("Error" + err.status, err.statusText, "error");
        this.consultando=false;
      }
    );
  }

  consultaOficiosFaltantes() {
    //history.pushState(null, "", "#/privada/oficios/");
    this.consulta = '';
    localStorage.removeItem('consulta');
    this.arrOficios = [];
    this.activa = false;
    if(this.tabla){
      this.tabla.destroy();
    }

    this.oficioService.objOficio = this.objOficio;
    this.oficioService.buscar = this.buscar;
    this.oficioService.objOficio.turno = this.filtroTurnos;
    //alert("Consulta Faltantes: " + this.filtroTurnos);
    this.consultando=true;
    this.oficioService.consultaOficiosSinAgendar().subscribe(
      data => {
        this.resp = data;
        this.arrOficios = this.resp.data;
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.arrOficios.length === 0) {
          this.arrOficios = [];
          this.inicializaTabla();
        }*/
        this.getImagenes();
        console.log('Datos: ' + this.arrOficios);
        this.consultando=false;
        setTimeout(() => {
          this.inicializaTabla();
        }, 1000);
        //console.log('Datos: ' + data);
      }
    );
  }

  consultaOficiosTodos() {
    //history.pushState(null, "", "#/privada/oficios/");
    this.consulta = '';
    localStorage.removeItem('consulta');
    this.arrOficios = [];
    this.activa = false;
    if(this.tabla){
      this.tabla.destroy();
    }

    this.oficioService.objOficio = this.objOficio;
    this.oficioService.buscar = this.buscar;
    this.oficioService.objOficio.turno = this.filtroTurnos;
    //alert("Consulta Todos: " + this.filtroTurnos);

    this.consultando= true;
    this.oficioService.consultaOficiosTodos().subscribe(
      data => {
        this.resp = data;
        this.arrOficios = this.resp.data;
        console.log(this.arrOficios);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.arrOficios.length === 0) {
          this.arrOficios = [];
          this.inicializaTabla();
        }*/
        this.getImagenes();
        //console.log('Datos: ' + data);
        setTimeout(() => {
          this.inicializaTabla();
        }, 1000);

        this.consultando=false;
        //console.log('Datos: ' + data);
      }
    );
  }

  getImagenes() {
    if (this.arrOficios != null ) {
      for ( const oficio of this.arrOficios ) {
        oficio.imagen = this.getImagenOficio2(oficio.idoficio) + "";
      }
    }
  }

  agregarOficio() {
    //alert('Agregando Oficio');
    this.objOficio.fecha = new Date(this.objOficio.fecha).toJSON().split('T')[0];
    if(new Date(this.objOficio.fechaevento).getTime()){
      this.objOficio.fechaevento = new Date(this.objOficio.fechaevento).toJSON().split('T')[0];
    }
    //this.objOficio.fechaevento = new Date(this.objOficio.fechaevento).toJSON().split('T')[0];
    this.objOficio.idusuario = this.usuarioActivo.idusuario;
    this.oficioService.objOficio = this.objOficio;
    this.oficioService.agregarOficio().subscribe(
      data => {
        this.resp = data;
        //alert(this.resp.mensaje);
        swal("Nuevo Oficio", this.resp.mensaje, "success");
        $('#altaModal').modal('hide');
        this.consultaOficios();
        // console.log(data);
      }
    );
  }

  modificarOficio() {
    //alert('Modificando Oficio');
    this.objOficio.fecha = new Date(this.objOficio.fecha).toJSON().split('T')[0];
    //console.log(this.objOficio.fechaevento);
    if(new Date(this.objOficio.fechaevento).getTime()){
      this.objOficio.fechaevento = new Date(this.objOficio.fechaevento).toJSON().split('T')[0];
    }
    // alert(this.objOficio.fecha);
    this.oficioService.objOficio = this.objOficio;
    this.oficioService.modificarOficio().subscribe(
      data => {
        this.resp = data;
        // alert(this.resp.mensaje);
        //new notificacion().simpleSuccess(this.resp.mensaje);
        swal("Modificacion", this.resp.mensaje, "success");
        if (!this.resp.error) {
          $('#altaModal').modal('hide');
        }

        this.consultaOficios();
        // console.log(data);
      }
    );
  }

  modificaOficio(index) {
    this.objOficio = this.arrOficios[index];

    this.funcionario = new Usuario();
    this.ciudadado = new Ciudadano();

    this.funcionario.idpersona = this.objOficio.dirigido;
    this.funcionario.nombreCompleto = this.objOficio.dirigidoNombre;
    this.ciudadado.idpersona = this.objOficio.enviado;
    this.ciudadado.nombreCompleto = this.objOficio.enviadoNombre;
    //console.log("Ciudadano en Oficio: " + JSON.stringify(this.ciudadado));
    //console.log("Funcionario en Oficio: " + JSON.stringify(this.funcionario));

    this.cargarLocalidad = true;

    this.objOficio.fecha = new Date(this.objOficio.fecha).toJSON().split('T')[0];
    //console.log(this.objOficio.fechaevento);
    if(new Date(this.objOficio.fechaevento).getTime()){
      this.objOficio.fechaevento = new Date(this.objOficio.fechaevento).toJSON().split('T')[0];
    }
    this.tituloModal = 'Modifica Oficio';

    $('#altaModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();

  }

  eliminaOficio(idoficio) {
    swal({
      title: "Quieres eliminar este Oficio?",
      text: "Una vez eliminado no podras recuperarlo!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.objOficio.idoficio = idoficio;
        this.oficioService.objOficio = this.objOficio;
        this.oficioService.eliminaOficio().subscribe(
          data => {
            this.resp = data;
            //alert(this.resp.mensaje);
            //new notificacion().simpleInfo(this.resp.mensaje);
             swal("Eliminado", this.resp.mensaje, "warning");
            $('#altaModal').modal('hide');

            this.consultaOficios();
            // console.log(data);
          }
          );
        }
    });

  }

  descartarOficio(idoficio) {
    swal({
      title: "Quieres descartar este Oficio?",
      text: "Una vez descartado no podras recuperarlo!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.objOficio.idoficio = idoficio;
        this.oficioService.objOficio = this.objOficio;
        this.oficioService.descartaOficio().subscribe(
          data => {
            this.resp = data;
            //alert(this.resp.mensaje);
            //new notificacion().simpleInfo(this.resp.mensaje);
             swal("Descartado", this.resp.mensaje, "warning");
            $('#altaModal').modal('hide');

            this.consultaOficios();
            // console.log(data);
          }
          );
        }
    });

  }

  agregarArchivo(archivo, index) {
    this.objOficio = this.arrOficios[index];
    localStorage.setItem('idoficio', this.objOficio.idoficio);
    if (archivo == null) {
      // alert('Agrear Archivo');
      this.muestraArchivo = false;
      this.tituloModal = 'Oficio';

    } else {
      this.muestraArchivo = true;
      this.tituloModal = 'Oficio';
      // this.imgModal = 'http://localhost/SAG/restful/uploads/' + archivo;
      //this.imgModal = GLOBAL.url + '/Oficios/getImagenArchivo/' + archivo;
      this.imgModal = this.getImagenOficio2(archivo);
      //this.imgModalZan = this.sanitizer.bypassSecurityTrustResourceUrl(this.imgModal);
      //console.log(this.imgModal);
      // this.imgModal = this.imgGlobal + archivo;

    }


    //Para Galeria
    /*$("a[class^='prettyPhoto']").prettyPhoto({
      overlay_gallery: false, social_tools: false
      });*/

    $('#imgModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();

  }



  cargarImagenes(archivo: any) {
    this.imagenCargar = <Array<File>>archivo.target.files;
    console.log(this.imagenCargar);
  }

  subirImagen() {
    //si muestra archivo es verdadero, entonces se esta subiendo otro archivo: MODIFICACION
    this.guardando = true;
    this.oficioService.modificacion = this.muestraArchivo;
    this.oficioService.subirArchivo([], this.imagenCargar, 'imagen', this.objOficio.archivo).then((result: any) => {
      this.resp = result;
      // console.log(result);
      // alert(this.resp.mensaje);
      //new notificacion().simpleInfo(this.resp.mensaje);
      swal("Cargado con Exito!", this.resp.mensaje, "success");
      if (!this.resp.error) {
        $('#imgModal').modal('hide');
        // this.arrOficios = [];
        // alert('Subida');
        this.consultaOficios();
        this.imgModal = '';
        // location.reload();
      }

      this.guardando = false;

    });
  }

  buscarOficio() {
    this.consultaOficios();
  }


  inicializaTabla() {
    //console.log('Activa al Inicializar:' + this.activa);
    if ( !this.activa ) {
      //alert('Inicializando Tabla');
      this.tabla = $('#data-table-1').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        "order": [],
        'buttons': [
          {
            extend:    'excelHtml5',
            text:      '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [ 1 ],

            },
            title: 'Oficialía de Partes ('+this.fecha+')'
          },
          {
            extend:    'print',
            text:      '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function ( win ) {
              $(win.document.body).css( 'font-size', '12pt' ).prepend('<img src="https://agenda.nayarit.gob.mx//ssai_v2_5/logoGobierno.png" width="60" style="position:absolute; top:0; left:0;" />');
              $(win.document.body).find( 'table' ).addClass( 'compact' ).css( 'font-size', 'inherit' );
            },
            exportOptions: {
              columns: [ 1 ],
              stripHtml: false

            },
            title: 'Oficialía de Partes ('+this.fecha+')'
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
      return true;
    }

  }

  inicializaTabla2() {
    if ( this.activa ) {
      console.log('Destruyendo tabla');
      this.tabla.destroy();
      this.activa = false;
    }
    console.log('Construyendo tabla');
      this.tabla = $('#data-table-1').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [
          'copy', 'csv', 'excel', 'pdf', 'print',
          {
              extend: 'pdf',
              orientation: 'landscape',
              pageSize: 'LEGAL'
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
          }
        }
      });
      this.activa = true;
  }



  // Invitaciones para Agendar


  altaInvitacion(tipo, index = '') {

    this.objOficio = new oficioModel();

    this.objOficio.idusuario = this.usuarioActivo.idusuario;
    // alert(index);
    if(index !== '') {
      this.objOficio = this.arrOficios[index];
      //console.log(this.objOficio);
      this.escogeTipo = true;
    } else {
      this.escogeTipo = false;
    }

    // alert(this.objOficio.idoficio);
    this.objAgenda = new agendaModel();
    this.objAgenda.idoficio = this.objOficio.idoficio;
    this.objAgenda.tipo = tipo;

    this.objAgenda.invita = this.objOficio.enviado;
    this.objAgenda.nombreenvia = this.objOficio.enviadoNombre;
    this.objAgenda.evento = this.objOficio.asunto;

    this.objAgenda.idestado = this.objOficio.idestado;
    this.objAgenda.idmunicipio = this.objOficio.idmunicipio;
    this.objAgenda.idlocalidad = this.objOficio.idlocalidad;

    this.objAgenda.lugar = this.objOficio.lugar;
    if(this.objOficio.categoria === 'EVENTO' || this.objOficio.categoria === 'INVITACION'){
      this.objAgenda.fecha = this.objOficio.fechaevento;
    } else {
      this.objAgenda.fecha = this.objOficio.fecha;
    }

    this.ciudadado = new Ciudadano();
    this.ciudadado.idpersona = this.objOficio.enviado;
    this.ciudadado.nombreCompleto = this.objOficio.enviadoNombre;

    if (this.objOficio.tipo == null) {

      this.tituloModal = "Nueva Invitación";

    } else {

      this.tituloModal = "Modifica Invitación";
      this.objAgenda.idoficio = this.objOficio.idoficio;
      this.consultaAgendaPorOficio();
    }

    this.dependencia = "";
    this.objAgenda.idgira = "";

    $('#altaInvitacionModal2').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  guardarInvitacion() {
    this.objAgenda.fecha = new Date(this.objAgenda.fecha).toJSON().split('T')[0];
    //alert(this.objAgenda.fecha);
    let d = this.horaAux;
    this.objAgenda.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
    //alert(d.getDate());
    this.agendaService.objAgenda = this.objAgenda;

    this.agendaService.agregarAgenda().subscribe(
      data => {
        this.resp = data;
        //alert(this.resp.mensaje);
        //new notificacion().simpleInfo(this.resp.mensaje);
        //swal("Guardada", this.resp.mensaje, "info");
        if (!this.resp.error) {
          $('#altaInvitacionModal2').modal('hide');
          swal("Guardada", this.resp.mensaje, "info");
        }else{
          swal("Error", this.resp.mensaje, "error");
        }
        //this.arrOficios = this.resp.data;
        //console.log(data);
        if(this.consulta===''){
          this.consultaOficios();
        }else{
          this.consultaOficiosFaltantes();
          this.fecha = '';
        }
      }
    );
    console.log(this.objAgenda);
  }

  modificaInvitacion() {

    this.objAgenda.fecha = new Date(this.objAgenda.fecha).toJSON().split('T')[0];
    if(this.horaAnte !== this.objAgenda.hora){
      //var d = new Date(this.objAgenda.hora);
      //this.objAgenda.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
      //alert(this.objAgenda.hora);
    }
    let d = this.horaAux;
    this.objAgenda.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
    //alert(this.objAgenda.hora);
    this.agendaService.objAgenda = this.objAgenda;

    this.agendaService.modificaAgenda().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          $('#altaInvitacionModal2').modal('hide');
        }
        //alert(this.resp.mensaje);
        swal("Modificado", this.resp.mensaje, "info");
        //this.arrOficios = this.resp.data;
        //console.log(data);
        //this.consultaOficios();
      }
    );
    console.log(this.objAgenda);
  }

  consultaAgendaPorOficio() {
    this.agendaService.objAgenda = this.objAgenda;
    this.agendaService.consultaAgendaPorOficio().subscribe(
      data => {
        this.resp = data;
        this.objAgenda = this.resp.data[0];
        this.horaAnte = this.objAgenda.hora;
        //console.log(this.objAgenda);
        this.validaHora();


        //Cargados cuando hacemos una modificacion
        this.funcionarioAsiste = new Persona();
        this.funcionarioAsiste.idpersona = this.objAgenda.asiste;
        this.funcionarioAsiste.nombreCompleto = this.objAgenda.nombreasiste;
        this.dependencia = this.objAgenda.iddependencia;
        this.representante.idpersona = this.objAgenda.representacion;
        this.representante.nombreCompleto = this.objAgenda.nombrerepresentacion;

        console.log(this.objAgenda);
        console.log(this.funcionarioAsiste);

      }
    );

  }

  validaHora() {
    if (new Date(this.objAgenda.hora).toString() === 'Invalid Date') {
      // alert('Fecha Invalida - ' + this.objAgenda.hora);
      this.horaAux = new Date();
      // alert(this.objAgenda.fecha);
    } else {
      // alert('Fecha Valida' + this.objAgenda.hora);
      this.horaAux = new Date(this.objAgenda.hora);
    }
  }




  altaEnviado(idUsuario) {
    // alert(idUsuario);
    this.tipoPersonas = 'CIUDADANO';
    this.objOficio.enviado = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  modificaEnviado(idUsuario) {
    this.tipoPersonas = 'CIUDADANO';
    // alert(idUsuario);
    this.objOficio.enviado = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  altaDirigido(idUsuario) {
    this.tipoPersonas = 'FUNCIONARIO';
    // alert(idUsuario);
    this.objOficio.dirigido = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  modificaDirigido(idUsuario) {
    this.tipoPersonas = 'FUNCIONARIO';
    // alert(idUsuario);
    this.objOficio.dirigido = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }

  recibirLocalidad(event: Localidad) {
    //console.log("Localidad Recibida: " + JSON.stringify(event));
    this.objOficio.idestado = event.idestado;
    this.objOficio.idmunicipio = event.idmunicipio;
    this.objOficio.idlocalidad = event.idlocalidad;
  }

  recibirLocalidadInvitacion(event: Localidad) {
    //console.log("Localidad Recibida: " + JSON.stringify(event));
    this.objAgenda.idestado = event.idestado;
    this.objAgenda.idmunicipio = event.idmunicipio;
    this.objAgenda.idlocalidad = event.idlocalidad;
  }

  onSubmit() {
    //alert();
  }


  getCiudadanoSeleccionado(ciudadado: Usuario) {
    //console.log('Persona Seleccionada: ' + ciudadado.nombreCompleto);
    this.ciudadado = ciudadado;
    this.objOficio.enviado = ciudadado.idpersona;
    // console.log('Ciudadano ya recibido: ' + this.ciudadado.nombreCompleto);
  }

  getFuncionarioSeleccionado(funcionario: Usuario) {
    // console.log('Persona Seleccionada: ' + funcionario);
    this.funcionario = funcionario;
    this.objOficio.dirigido = funcionario.idpersona;
  }

  getFuncionarioAsiste(funcionario: Usuario) {
    // console.log('Persona Seleccionada: ' + funcionario);
    this.funcionarioAsiste = funcionario;
    this.objAgenda.asiste = funcionario.idpersona;
  }



  personaCreada(event) {
    console.log(event);
  }


  valida(){
    //alert('valida');
    if(this.objAgenda.idagenda===''){
      this.guardarInvitacion();
    }else{
      this.modificaInvitacion();
    }
  }


  getDependencias() {

    this._dependenciaService.getDependencias().subscribe(
      data => {
        this.resp = data;

        this.dependencias = this.resp.data;
        //console.log(data);
      }
    );
  }

  getSecretarias() {
    this._secretariaService.secretaria.iddependencia='9';
    this._secretariaService.getSecretariasDependencia().subscribe(
      data => {
        this.resp = data;

        this.secretarias = this.resp.data;
        //console.log(data);
      }
    );
  }

  consultaSecretarios(event) {
    //console.log(event);
    //console.log(event.target.value);
    this.getSecretarioPorDependencia(event.target.value);
  }

  getSecretarioPorDependencia(dependencia) {
    this._funcionarioservice.usuario.iddependencia = dependencia;
    this._funcionarioservice.getFuncionariosPorDependencia().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          alert(this.resp.mensaje);
        }
        this.funcionarios = this.resp.data;
        console.log(data);
      }
    );
  }


  getAgendaGuardada(event: agendaModel){
    $('#altaInvitacionModal2').modal('hide');
    this.consultaOficiosTodos();
  }

  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }
}
