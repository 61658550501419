import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Secretaria } from '../../models/secretaria';
import { SecretariaService } from '../../services/secretaria.service';
import { AgendaService } from '../../services/agenda.service';
import { respuesta } from '../../models/respuesta';
import { agendaModel } from '../../models/agenda';
import { Dependencia } from '../../models/dependencia';
import { DependenciaService } from '../../services/dependencia.service';
import { Acompanante } from '../../models/acompanante';
import { Funcionario } from '../../models/Funcionario';

@Component({
  selector: 'app-lista-acompanantes',
  templateUrl: './lista-acompanantes.component.html'
})
export class ListaAcompanantesComponent implements OnInit, OnChanges {

  @Input() agenda: agendaModel;
  @Input() tipo: string = "A";

  resp: respuesta;
  dependencia: Dependencia;
  acompanantes: Dependencia[] = [];

  acompanante: Acompanante;
  acompanantes2: Acompanante[] = [];


  acompanante3: Funcionario;
  acompanantes3: Funcionario[] = [];

  constructor(private _dependenciaService: DependenciaService, private _agendaService: AgendaService) {
    this.resp = new respuesta();
    this.acompanante = new Acompanante();
    this.dependencia = new Dependencia();
    this.acompanante3 = new Funcionario();
  }

  ngOnInit() {

  }

  ngOnChanges(){
    if(this.tipo === 'A'){
      this.getAcompanantes3();
    }else{
      this.getRepresentante();
    }
  }

  getAcompanantes() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.getAcompanantes().subscribe(
      data => {
        this.resp = data;

        this.acompanantes = this.resp.data;
        console.log(data);
      }
    );
  }

  getAcompanantes2() {
    this._agendaService.acompanante = this.acompanante;
    this._agendaService.getAcompanantes2().subscribe(
      data => {
        this.resp = data;

        this.acompanantes2 = this.resp.data;
        console.log(data);
      }
    );
  }

  getAcompanantes3() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.getAcompanantes3().subscribe(
      data => {
        this.resp = data;

        this.acompanantes3 = this.resp.data;
        console.error(this.acompanante3);
      }
    );
  }

  getRepresentante() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.getRepresentante().subscribe(
      data => {
        this.resp = data;

        this.acompanantes3 = this.resp.data;
        console.log(data);
      }
    );
  }

  notificar(id){
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.tipo = this.tipo;
    this._agendaService.idfuncionario = this.acompanantes3[id].idfuncionario;
    this._agendaService.nofiticaAcompanante().subscribe(
      data => {
        this.resp = data;

        if(this.resp.error){
          swal("Error", this.resp.mensaje, "error");
        }else{
          swal("Notificación", this.resp.mensaje, "info");
        }
      }
    );

  }

}
