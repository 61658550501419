import { Component, OnInit } from '@angular/core';
import { respuesta } from '../../models/respuesta';
import { secretarioModel } from '../../models/secretario';
import { SecretarioService } from '../../services/secretario.service';
import { Dependencia } from '../../models/dependencia';
import { DependenciaService } from '../../services/dependencia.service';
import { Funcionario } from '../../models/Funcionario';
import { FuncionarioService } from '../../services/funcionario.service';


@Component({
  selector: 'app-secretarios',
  templateUrl: './secretarios.component.html',
  styles: []
})
export class SecretariosComponent implements OnInit {
  tituloModal = '';
  titulo = 'Catalogo de Secretarios';

  resp: respuesta;
  funcionario: Funcionario;
  funcionarios: Funcionario[] = [];

  dependencias: Dependencia[] = [];

  modificar = false;

  activa = false;

  constructor(private _funcionarioService:FuncionarioService, private _dependenciaService:DependenciaService) {
    this.funcionario = new Funcionario();
    this.resp = new respuesta();
  }

  ngOnInit() {
    this.getDependencias();
    this.getFuncionarios();
  }

  getFuncionarios() {
    this._funcionarioService.getFuncionarios().subscribe(
      data => {
        this.resp = data;
        if(this.resp.error) {
          alert(this.resp.mensaje);
        }
        this.funcionarios = this.resp.data;
        console.log(data);
      }
    );
  }

  getDependencias() {
    this._dependenciaService.getDependenciasActivas().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          alert(this.resp.mensaje);
        }
        this.dependencias = this.resp.data;
        console.log(data);
      }
    );
  }

  altaFuncionario() {
    this.modificar = false;
    this.tituloModal= 'Nuevo Secretario';
    $('#altaModal').modal('show');
  }

  modificaFuncionario(index) {
    this.modificar = true;
    this.funcionario = this.funcionarios[index];
    this.tituloModal = 'Modifica Funcionarios';
    $('#altaModal').modal('show');
  }


  /*modificarFuncionario() {
    this._funcionarioService.funcionario = this.funcionario;
    this._funcionarioService.modficaFuncionario().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if (!this.resp.error) {
          $('#altaModal').modal('hide');
        }

        this.getSecretarias();
        // console.log(data);
      }
    );
  }*/

  

  inicializaTabla() {
    if ( !this.activa ) {
      $('#data-table-1').dataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [
          {
            extend:    'excelHtml5',
            text:      '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [ 0, 1, 2, 3, 4, 5 ]
            }
          },
          {
            extend:    'print',
            text:      '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function ( win ) {
              //$(win.document.body).css( 'font-size', '10pt' );
              $(win.document.body).find( 'table' ).addClass( 'compact' ).css( 'font-size', 'inherit' );
            },
            exportOptions: {
              columns: [ 0, 1, 2, 3, 4, 5 ]
            }
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
    }
  }

}
