import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalizar'
})
export class CapitalizarPipe implements PipeTransform {

  transform(value: string): string {

    value = value.toLowerCase();
    let nombres = value.split(' ');

    // tslint:disable-next-line:forin
    for(let i in nombres){
      nombres[i] = nombres[i][0].toUpperCase() + nombres[i].substr(1);
    }
    return nombres.join(' ');
  }

}
