
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Ciudadano } from '../models/Ciudadano';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';



@Injectable()
export class CiudadanoService {
  url = '';
  funcionario: Ciudadano;
  resp: respuesta;
  headers: Headers = new Headers;


  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.funcionario = new  Ciudadano();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  getCiudadanos(): Observable<respuesta> {
    try {
      let params = '';
      return this.http.post(this.url + 'Ciudadanos/getCiudadanos', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
