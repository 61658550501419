export class agendaModel {
  idagenda: string;
  idoficio: string;
  invita: string;
  nombreenvia: string;
  evento: string;
  fecha: string;
  hora: string;
  horafin: string;
  lugar: string;
  representacion: string;
  asiste: string;
  oficiorep: string;
  tipo: string;
  asistegob: string;

  horaIni2: string;
  horaFin2: string;

  nombreasiste: string;
  nombrerepresentacion: string;

  idestado: string;
  estado: string;
  idmunicipio: string;
  municipio: string;
  idlocalidad: string;
  localidad: string;

  iddependencia: string;
  dependencia: string;

  idarchivo: string;

  archivo: string;


  observacion: string;
  observacioncance: string;


  idgira: string;
  gira: string;

  vestimenta: string;
  detTipo: string;

  sinhorario: string;
  todo_el_dia: string;
  idusuario: string;
  email: string;

  estatus_evento: string;
  para_conocimiento: string;
  ajustar_tiempo: string;
  sinHoraFin: string;


  duplicado = '';
  idagendaOld = '';


  constructor() {
    this.idagenda = "";
    this.idoficio = "";
    this.invita = "";
    this.evento = "";
    //this.fecha="";
    this.fecha = new Date().toJSON().split('T')[0];
    //this.hora="";
    let d = new Date();
    //this.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
    //this.horafin = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
    this.hora = d.getHours() + ":" + d.getMinutes();
    this.horafin = (d.getHours() + 1) + ":" + d.getMinutes();
    this.lugar = "";
    this.representacion = "";
    this.asiste = "";
    this.asistegob = "SIN CONFIRMAR";
    this.idestado = "0";
    this.idmunicipio = "0";
    this.idlocalidad = "0";

    this.vestimenta = 'SIN DEFINIR';
    this.detTipo = '';
    this.sinhorario = '0';
    this.todo_el_dia = '0';
    this.sinHoraFin = '1';



  }
}
