import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { agendaModel } from '../models/agenda';
import { respuesta } from '../models/respuesta';
import { eventoModel } from '../models/evento';

import { GLOBAL } from './global';
import { Gira } from '../models/Gira';
import { Acompanante } from '../models/acompanante';
import { Usuario } from '../models/Usuario';

@Injectable()
export class AgendaService {
  url: string = "";
  objAgenda: agendaModel;
  arrAgendas: agendaModel[] = [];
  arrEventos: eventoModel[] = [];
  resp: respuesta;
  headers: Headers = new Headers;
  control: string;
  mes: string;
  anio: string;
  idfuncionario: string;
  tipo: string;

  fechaIni: string;
  fechaFin: string;
  gira: Gira;
  txt: string = '';

  subcontrol: string;

  iddependencia: string;

  acompanante: Acompanante;

  filtro: string;

  buscar: string;



  //login
  email: string;
  psw: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.objAgenda = new agendaModel();
    this.resp = new respuesta();
    this.acompanante = new Acompanante();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  //Entradas en Agenda
  consultaAgendas(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&filtro=" + this.filtro;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendas", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasConAcompanante(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&filtro=" + this.filtro;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasConAcompanante", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEventos(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&filtro=" + this.filtro;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasEventos", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEventosTodos(): Observable<respuesta> {
    try {
      let params = 'subcontrol=' + this.subcontrol;
      //console.log(params);
      //alert(params);
      return this.http.post(this.url + "Agendas/getAgendasEventosTodos", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }


  getReporte(): Observable<respuesta> {
    try {
      let params = "fechaIni=" + this.fechaIni;
      params += "&fechaFin=" + this.fechaFin;
      params += '&txt=' + this.txt;
      console.log(params);
      return this.http.post(this.url + "Agendas/getReporte", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getReporteGira(): Observable<respuesta> {
    try {
      let params = "idgira=" + this.gira.idgira;
      console.log(params);
      return this.http.post(this.url + "Agendas/getReporteGira", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasTecnica(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasTecnica", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          console.log(this.resp);

          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgenda(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      return this.http.post(this.url + "Agendas/getAgenda", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }


  // Entradas en Agenda
  consultaAgendaPorOficio(): Observable<respuesta> {
    try {
      let params = 'idoficio=' + this.objAgenda.idoficio;
      console.log('Parametros consulta Agenda por Oficio: ' + params);
      return this.http.post(this.url + 'Agendas/getAgendaPorOficio', params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          // this.cct = res.json();
          this.resp = res.json();
          this.arrAgendas = this.resp.data;
          // console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEvento(): Observable<respuesta> {
    //alert('consultaAgendasEvento');
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&mes=" + this.mes;
      params += "&anio=" + this.anio;
      params += "&buscar=" + this.buscar;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasEvento", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          console.log(params);
          //this.cct = res.json();
          this.resp = res.json();
          if (this.resp.error) {
            this.resp.error = 'false';
            this.resp.mensaje = 'datos';
            this.resp.data = [];
          } else {
            this.arrEventos = this.resp.data;
            //console.log(this.arrEventos);
          }
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEventoConInvitados(): Observable<respuesta> {
    //alert('consultaAgendasEventoConInvitados');
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&mes=" + this.mes;
      params += "&anio=" + this.anio;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasEventosConInvitados", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrEventos = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEventoSecretario(): Observable<respuesta> {
    //alert('consultaAgendasEventoSecretario');
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&mes=" + this.mes;
      params += "&anio=" + this.anio;
      params += "&idfuncionario=" + this.idfuncionario;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasEventoSecretario", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrEventos = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEventoCumple(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      return this.http.post(this.url + "Agendas/getAgendasEventoCumple", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrEventos = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasEventoGobernador(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      return this.http.post(this.url + "Agendas/getAgendasEventoAsisteGob", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrEventos = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaAgendasSecretario(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objAgenda.fecha;
      params += "&idfuncionario=" + this.idfuncionario;
      console.log(params);
      return this.http.post(this.url + "Agendas/getAgendasSecretarioFecha", params, { headers: this.headers }).pipe(map(
        res => {

          console.log("Respuesta: " + res);
          //this.cct = res.json();
          this.resp = res.json();
          console.log(this.resp);
          return this.resp;
        }));
    } catch (err) {
      console.log("Se genero un Error: " + err);
    }
  }

  borrarAgenda(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      return this.http.post(this.url + "Agendas/borrarAgenda", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          this.resp = res.json();
          alert(this.resp.mensaje);

          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  cancelarAgenda(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&observacion=" + this.objAgenda.observacioncance;
      console.log(params);
      return this.http.post(this.url + "Agendas/cancelarAgenda", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          this.resp = res.json();
          //alert(this.resp.mensaje);

          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }


  eliminarAgenda(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      console.log(params);
      return this.http.post(this.url + "Agendas/eliminarAgenda", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          this.resp = res.json();
          //alert(this.resp.mensaje);

          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  agregarAgenda(): Observable<respuesta> {
    try {
      let params = "invita=" + this.objAgenda.invita;
      params += "&evento=" + this.objAgenda.evento;
      params += "&fecha=" + this.objAgenda.fecha;

      params += "&hora=" + this.objAgenda.fecha + " " + this.objAgenda.horaIni2;
      params += "&horafin=" + this.objAgenda.fecha + " " + this.objAgenda.horaFin2;

      params += "&lugar=" + this.objAgenda.lugar;
      params += "&representacion=" + this.objAgenda.representacion;
      params += "&asistegob=" + this.objAgenda.asistegob;
      params += "&tipo=" + this.objAgenda.tipo;
      params += "&idoficio=" + this.objAgenda.idoficio;
      params += "&idestado=" + this.objAgenda.idestado;

      params += "&idestado=" + this.objAgenda.idestado;
      params += "&idmunicipio=" + this.objAgenda.idmunicipio;
      params += "&idlocalidad=" + this.objAgenda.idlocalidad;
      params += "&observacion=" + this.objAgenda.observacion;
      params += "&idgira=" + this.objAgenda.idgira;

      params += "&asiste=" + this.objAgenda.asiste;
      params += "&vestimenta=" + this.objAgenda.vestimenta;
      params += "&detTipo=" + this.objAgenda.detTipo;
      params += "&sinhorario=" + this.objAgenda.sinhorario;
      params += "&todo_el_dia=" + this.objAgenda.todo_el_dia;

      params += "&sinHoraFin=" + this.objAgenda.sinHoraFin;
      params += "&duplicado=" + this.objAgenda.duplicado;
      params += "&idagendaOld=" + this.objAgenda.idagendaOld;

      let usuario: Usuario;
      usuario = new Usuario();
      usuario = JSON.parse(localStorage.getItem('usuario'));

      params += "&idusuario=" + usuario.idusuario;



      console.log(params);
      return this.http.post(this.url + "Agendas/guardaAgenda", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        this.arrAgendas = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  agregarAudiencia(): Observable<respuesta> {
    try {
      let params = "nombre=" + this.objAgenda.invita;
      params += "&asunto=" + this.objAgenda.evento;
      params += "&fecha=" + this.objAgenda.fecha;
      params += "&hora=" + this.objAgenda.hora;

      return this.http.post(this.url + "Agendas/guardaAudiencia", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        this.arrAgendas = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }


  modificaAgenda(): Observable<respuesta> {
    try {
      let params = "invita=" + this.objAgenda.invita;
      params += "&evento=" + this.objAgenda.evento;
      params += "&fecha=" + this.objAgenda.fecha;


      params += "&hora=" + this.objAgenda.fecha + " " + this.objAgenda.horaIni2;
      params += "&horafin=" + this.objAgenda.fecha + " " + this.objAgenda.horaFin2;

      params += "&lugar=" + this.objAgenda.lugar;
      params += "&representacion=" + this.objAgenda.representacion;
      params += "&asistegob=" + this.objAgenda.asistegob;
      params += "&tipo=" + this.objAgenda.tipo;
      params += "&idoficio=" + this.objAgenda.idoficio;
      params += "&idagenda=" + this.objAgenda.idagenda;
      params += "&observacion=" + this.objAgenda.observacion;

      params += "&idestado=" + this.objAgenda.idestado;
      params += "&idmunicipio=" + this.objAgenda.idmunicipio;
      params += "&idlocalidad=" + this.objAgenda.idlocalidad;

      params += "&asiste=" + this.objAgenda.asiste;

      params += "&idgira=" + this.objAgenda.idgira;
      params += "&vestimenta=" + this.objAgenda.vestimenta;
      params += "&detTipo=" + this.objAgenda.detTipo;
      params += "&sinhorario=" + this.objAgenda.sinhorario;
      params += "&todo_el_dia=" + this.objAgenda.todo_el_dia;
      params += "&sinHoraFin=" + this.objAgenda.sinHoraFin;

      console.log(params);
      return this.http.post(this.url + "Agendas/modificaAgenda", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        this.arrAgendas = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  subirArchivo(params: Array<string>, file: Array<File>, name: string) {
    var dire = this.url + "Agendas/subirArchivo";
    return new Promise(function (resolve, reject) {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      for (var i = 0; i < file.length; i++) {
        formData.append(name, file[i], file[i].name);
      }

      formData.append('idagenda', localStorage.getItem('idagenda'));

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            console.log(xhr.response);
            resolve(JSON.parse(xhr.response));

          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open('POST', dire, true);
      //xhr.setRequestHeader('Authorization', token);
      xhr.send(formData);
    });
  }



  getAcompanantes(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/getAcompanantesAgenda", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getAcompanantes3(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/getAcompanantesAgenda3", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getRepresentante(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/getRepresentante", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  guardaAcompanantes(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&iddependencia=" + this.iddependencia;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/guardaAcompanante", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  guardaAcompanantes3(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&idfuncionario=" + this.idfuncionario;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/guardaAcompanante3", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  guardaRepresentante(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&idfuncionario=" + this.idfuncionario;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/guardaRepresentante", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  nofiticaAcompanante(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&idfuncionario=" + this.idfuncionario;
      params += "&evento=" + this.objAgenda.evento;
      params += "&tipo=" + this.tipo;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/notificaAcompanante", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  nofiticaCumplenos(): Observable<respuesta> {
    try {
      let params = "subcontrol=" + this.subcontrol;
      console.log(params);
      return this.http.post(this.url + "Agendas/notificaCumpleanos", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  conocimientoEvento(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&para_conocimiento=" + this.objAgenda.para_conocimiento;
      console.log(params);
      return this.http.post(this.url + "Agendas/paraConocimientoEvento", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  ajustarTiempoEvento(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&ajustar_tiempo=" + this.objAgenda.ajustar_tiempo;
      console.log(params);
      return this.http.post(this.url + "Agendas/ajustarTiempoEvento", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  autorizaEvento(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&estatus_evento=" + this.objAgenda.estatus_evento;
      console.log(params);
      return this.http.post(this.url + "Agendas/autorizaEvento", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificaFechaEventos(fecha: string, agendas: string): Observable<respuesta> {
    try {
      let params = "agendas=" + agendas;
      params += "&fecha=" + fecha;
      console.log(params);
      return this.http.post(this.url + "Agendas/modificaFechaAgendas", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaAcompanante(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&iddependencia=" + this.iddependencia;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/eliminaAcompanante", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaAcompanante3(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAgenda.idagenda;
      params += "&idfuncionario=" + this.idfuncionario;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/eliminaAcompanante3", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }



  getAcompanantes2(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.acompanante.idagenda;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/getAcompanantesAgenda2", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  guardaAcompanantes2(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.acompanante.idagenda;
      params += "&acompanante=" + this.acompanante.acompanante;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/guardaAcompanante2", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  actualizaAcompanantes2(): Observable<respuesta> {
    try {
      let params = "idacompanante=" + this.acompanante.idacompanante;
      params += "&acompanante=" + this.acompanante.acompanante;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/actualizaAcompanante2", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaAcompanante2(): Observable<respuesta> {
    try {
      let params = "idacompanante=" + this.acompanante.idacompanante;
      console.log(params);
      return this.http.post(this.url + "Acompanantes/eliminaAcompanante2", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          console.log(this.resp.data);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

}
