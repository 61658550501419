import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mainbar',
  templateUrl: './mainbar.component.html'
})
export class MainbarComponent implements OnInit {

  @Input() titulo: string;
  contenido: 'app-catalogo';

  constructor() { }

  ngOnInit() {}

}
