
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Dependencia } from '../models/dependencia';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';

@Injectable()
export class DependenciaService {
  url: string = "";
  dependencia:Dependencia;
  dependencias: Dependencia[] = [];
  resp: respuesta;
  headers:Headers = new Headers;

  //login
  email:string;
  psw:string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.dependencia = new  Dependencia();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  //Centros Escolares
  getDependencias(): Observable<respuesta> {
    try {
      let params = "idsecretaria=" + this.dependencia.iddependencia;
      return this.http.post(this.url + "Dependencias/getDependencias", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getDependenciasActivas(): Observable<respuesta> {
    try {
      let params = "idsecretaria=" + this.dependencia.iddependencia;
      return this.http.post(this.url + "Dependencias/getDependenciasActivas", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  agregarDependencia(): Observable<respuesta> {
    try {
      let params = "dependencia=" + this.dependencia.dependencia;

      return this.http.post(this.url + "Dependencias/guardaDependencia", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificarDependencia(): Observable<respuesta> {
    try {
      let params = "dependencia=" + this.dependencia.dependencia;
      params += "&iddependencia=" + this.dependencia.iddependencia;

      return this.http.post(this.url + "Dependencias/modificaDependencia", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaDependencia(): Observable<respuesta>{
    try {
      let params = "iddependencia=" + this.dependencia.iddependencia;
      return this.http.post(this.url + "Dependencias/borraDependencia", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  activaDependencia(): Observable<respuesta>{
    try {
      let params = "iddependencia=" + this.dependencia.iddependencia;
      return this.http.post(this.url + "Dependencias/activarDependencia", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

}
