import { Persona } from './persona';


export class Ciudadano extends Persona {
    idciudadano: string;
    nombramiento: string;
    estatus: string;

    constructor() {
        super();
    }
}
