import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { adjuntoModel } from '../../models/adjunto';
import { tipoarchivoModel } from '../../models/tipoarchivo';
import { respuesta } from '../../models/respuesta';
import { TipoarchivoService } from '../../services/tipoarchivo.service';
import { AdjuntoService } from '../../services/adjunto.service';
import { DomSanitizer } from '@angular/platform-browser';
import { agendaModel } from '../../models/agenda';
import { GLOBAL } from '../../services/global';

@Component({
  selector: 'app-adjuntos-agenda',
  templateUrl: './adjuntos-agenda.component.html'
})
export class AdjuntosAgendaComponent implements OnInit, OnChanges {

  @Input() agenda: agendaModel;

  titulo: string = "Archivos Adjuntos";

  archivoCargar: Array<File>;

  objAdjunto: adjuntoModel;
  arrAdjuntos: adjuntoModel[] = [];
  arrTipo: tipoarchivoModel[] = [];
  resp: respuesta;

  verArchivo: boolean = false;
  //urlArchivo: any = "http://drive.google.com/viewerng/viewer?embedded=true&url=http://agenda.nayarit.gob.mx/SAG_V4/restful/index.php/Adjuntos/muestraArchivo/";
  //urlArchivo: any = "https://lab.cgti.nayarit.gob.mx/SAG_V4/restful/index.php/Adjuntos/muestraArchivoWeb/";
  urlArchivo: any = GLOBAL.url + "/Adjuntos/muestraArchivoWeb/";
  urlArchivoZan: any;

  constructor(
    private tipoarchivoService: TipoarchivoService,
    private adjuntoService: AdjuntoService,
    public sanitizer: DomSanitizer) {
    this.resp = new respuesta();
    this.objAdjunto = new adjuntoModel();

    //this.objAdjunto.idagenda = localStorage.getItem('idagenda');

  }

  ngOnInit() {
    this.consultaTipos();

  }

  ngOnChanges(){
    this.objAdjunto.idagenda = this.agenda.idagenda;
    console.log("Agenda Adjuntos: " + this.agenda);
    this.consultaAdjuntos();
  }

  consultaAdjuntos() {
    this.adjuntoService.objAdjunto = this.objAdjunto;
    this.adjuntoService.getAdjuntos().subscribe(
      data => {
        this.resp = data;
        this.arrAdjuntos = this.resp.data;
        console.log(this.resp.data);

      }
    );
  }

  consultaTipos() {
    this.tipoarchivoService.getTiposDeArchivo().subscribe(
      data => {
        this.resp = data;
        this.arrTipo = this.resp.data;
        console.log(this.resp.data);
      }
    );
  }

  cargarImagenes(archivo: any) {
    this.archivoCargar = <Array<File>>archivo.target.files;
    console.log(this.archivoCargar);
  }

  subirImagen() {

    this.adjuntoService.subirArchivo([], this.archivoCargar, this.objAdjunto.idagenda, this.objAdjunto.idtipoarchivo, this.objAdjunto.descripcion).then((result: any) => {
      this.resp = result;
      //console.log(result);
      //alert(this.resp.mensaje);
      if (!this.resp.error) {
        swal("Archivo", this.resp.mensaje, "success");
        $('#imgModal').modal('hide');
        this.consultaAdjuntos();
        //this.imgModal = "";
        //location.reload();
        //this.objAdjunto = new adjuntoModel();
        this.objAdjunto.descripcion='';
        this.objAdjunto.idtipoarchivo='';
      }else{
        swal("Error", this.resp.mensaje, "error");
      }

    });

  }

  mostrarDocumento(index) {
    this.objAdjunto = this.arrAdjuntos[index];
    //this.objAdjunto.idadjuntos = this.arrAdjuntos[index].idadjuntos;
    this.urlArchivoZan = this.urlArchivo + this.objAdjunto.idarchivo;
    this.urlArchivoZan = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlArchivoZan);
    this.verArchivo = true;
    $('#fileModal').modal('show');
  }

  borrarDocumento(index) {
    swal({
      title: "Quieres eliminar este Archivo?",
      text: "Una vez eliminado no podras recuperarlo!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        this.adjuntoService.objAdjunto = this.arrAdjuntos[index];
        this.adjuntoService.eliminaAdjunto().subscribe(
          data => {
            this.resp = data;
            this.arrTipo = this.resp.data;
            console.log(this.resp.data);
            this.consultaAdjuntos();
          }
        );
      }
    });
  }


  cerrarModalFile(){
    $('#fileModal').modal('hide');
  }


  obtenDescTipo(event){
    console.log(event);
    this.objAdjunto.descripcion = event.target.options[event.target.options.selectedIndex].innerText;
  }
}
