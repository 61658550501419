import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { contactoModel } from '../../models/contacto';
import { respuesta } from '../../models/respuesta';
import { ContactoService } from '../../services/contacto.service';
import { agendaModel } from '../../models/agenda';
import { Ciudadano } from '../../models/Ciudadano';
import { Persona } from '../../models/persona';

@Component({
  selector: 'app-contactos-agenda',
  templateUrl: './contactos-agenda.component.html',
})
export class ContactosAgendaComponent implements OnInit, OnChanges {
  @Input() agenda: agendaModel;

  @ViewChild('modalAltaPersona') modalAltaPersona: ElementRef;

  titulo:string = "Contactos de Evento";
  objContacto:contactoModel;
  arrContactos:contactoModel[] = [];
  resp:respuesta;
  persona: Persona;


  constructor(private contactoService: ContactoService) {
    this.objContacto = new contactoModel();
    this.persona = new Persona();
    this.resp = new respuesta();
    //this.objContacto.idagenda=localStorage.getItem('idagenda');
    
  }

  ngOnInit() {
    //this.getContactos();
  }

  ngOnChanges(){
    this.objContacto.idagenda=this.agenda.idagenda;
    this.getContactos();
  }

  guardarContacto() {
    this.contactoService.objContacto = this.objContacto;
    this.contactoService.agregarContacto().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          //alert(this.resp.mensaje);
          swal("Contacto", this.resp.mensaje, "Error");
        }else{
          swal("Contacto", this.resp.mensaje, "success");
        }
        //this.arrContactos = this.resp.data;
        //console.log(data);
        this.getContactos();
      }
    );
  }

  getContactos() {
    this.contactoService.objContacto = this.objContacto;
    this.contactoService.getContactos().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          //alert(this.resp.mensaje);
         
        }
        this.arrContactos = this.resp.data;
        console.log(data);
        $('#contactoModal').modal('show');
      }
    );
  }

  modificaContacto(i) {
    this.objContacto = this.arrContactos[i];
    this.altaPersona();
  }

  borrarContacto(i) {
    if (confirm("Estas seguro de eliminar este Contacto de Evento?")) {
      this.contactoService.objContacto = this.arrContactos[i];
      this.contactoService.eliminaContacto().subscribe(
        data => {
          this.resp = data;
          if (this.resp.error) {
            alert(this.resp.mensaje);
          }
          this.getContactos();
          console.log(data);
          $('#contactoModal').modal('show');
        }
      );
    }
  }

  tomaPersona(event:Persona){
    this.persona = event;
    console.log(this.persona);
    this.objContacto.idcontacto = this.persona.idpersona;
    //this.objContacto.idagenda = this.agenda.idagenda;
  }

  altaPersona() {
    
      $(this.modalAltaPersona.nativeElement).modal('show');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    
  }

}
