import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { CatalogoComponent } from './components/catalogo/catalogo.component';
import { HomeComponent } from './components/home/home.component';
import { Error404Component } from './components/error404/error404.component';
import { SecretariosComponent } from './components/secretarios/secretarios.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { EstadosComponent } from './components/estados/estados.component';
import { MunicipiosComponent } from './components/municipios/municipios.component';
import { LocalidadesComponent } from './components/localidades/localidades.component';


import { OficialiaPageComponent } from './pages/oficialia-page/oficialia-page.component';
import { PrincipalPageComponent } from './pages/principal-page/principal-page.component';
import { CatalogosPageComponent } from './pages/catalogos-page/catalogos-page.component';
import { ConfiguracionPageComponent } from './pages/configuracion-page/configuracion-page.component';
import { PerfilPageComponent } from './pages/perfil-page/perfil-page.component';
import { AgendaPageComponent } from './pages/agenda-page/agenda-page.component';
import { AgendaprivadaPageComponent } from './pages/privada/agendaprivada-page/agendaprivada-page.component';
import { OficiosPrivadaPageComponent } from './pages/privada/oficios-privada-page/oficios-privada-page.component';
import { PrivadaPageComponent } from './pages/privada/privada-page/privada-page.component';
import { TecnicaPageComponent } from './pages/tecnica/tecnica-page/tecnica-page.component';
import { AgendatecnicaPageComponent } from './pages/tecnica/agendatecnica-page/agendatecnica-page.component';
import { OficiosTecnicaPageComponent } from './pages/tecnica/oficios-tecnica-page/oficios-tecnica-page.component';
import { AgendaTecnicaContenedorComponent } from './pages/tecnica/agenda-tecnica-contenedor/agenda-tecnica-contenedor.component';
import { AgendaPrivadaContenedorComponent } from './pages/privada/agenda-privada-contenedor/agenda-privada-contenedor.component';
import { DetalleDiaTecnicaContenedorComponent } from './pages/tecnica/detalle-dia-tecnica-contenedor/detalle-dia-tecnica-contenedor.component';
import { DetalleDiaPrivadaContenedorComponent } from './pages/privada/detalle-dia-privada-contenedor/detalle-dia-privada-contenedor.component';
import { DetallediaPageComponent } from './pages/detalledia-page/detalledia-page.component';
import { DependenciaComponent } from './components/dependencias/dependencia.component';
import { DetalleAgendaComponent } from './components/detalle-agenda/detalle-agenda.component';


import { LoginGuard } from './services/guards/login.guard';
import { PrivadaGuard } from './services/guards/privada.guard';
import { TecnicaGuard } from './services/guards/tecnica.guard';
import { OficialiaGuard } from './services/guards/oficialia.guard';
import { SeguimientoGuard } from './services/guards/seguimiento.guard';


import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { PrensaPageComponent } from './pages/prensa/prensa-page/prensa-page.component';
import { AgendaPrensaContenedorComponent } from './pages/prensa/agenda-prensa-contenedor/agenda-prensa-contenedor.component';
import { DetalleDiaPrensaContenedorComponent } from './pages/prensa/detalle-dia-prensa-contenedor/detalle-dia-prensa-contenedor.component';
import { AgendaprensaPageComponent } from './pages/prensa/agendaprensa-page/agendaprensa-page.component';
import { OficiosPrensaPageComponent } from './pages/prensa/oficios-prensa-page/oficios-prensa-page.component';
import { PrensaGuard } from './services/guards/prensa.guard';
import { GirasComponent } from './components/giras/giras.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { CalendarioMovilComponent } from './components/calendario-movil/calendario-movil.component';
import { MovilComponent } from './pages/movil/movil.component';
import { AgendaeventosPageComponent } from './pages/eventos/agendaeventos-page/agendaeventos-page.component';
import { DetalleDiaEventoContenedorComponent } from './pages/eventos/detalle-dia-eventos-contenedor/detalle-dia-eventos-contenedor.component';
import { EventosPageComponent } from './pages/eventos/eventos-page/eventos-page.component';
import { AgendaEventoContenedorComponent } from './pages/eventos/agenda-evento-contenedor/agenda-evento-contenedor.component';
import { AtencioncComponent } from './components/atencionc/atencionc.component';
import { EventosContenedorComponent } from './pages/eventos/eventos-contenedor/eventos-contenedor.component';
import { AgendaeventosHoyPageComponent } from './pages/eventos/agendaeventoshoy-page/agendaeventoshoy-page.component';
import { AgendaeventosMananaPageComponent } from './pages/eventos/agendaeventosmanana-page/agendaeventosmanana-page.component';
import { SeguimientoOficiosComponent } from './components/seguimiento-oficios/seguimiento-oficios.component';


const app_routes: Routes = [
    {
      path: '',
      component: PrincipalPageComponent,
      canActivate: [LoginGuard],
      children: [
        { path: 'home', component: HomeComponent },
        { path: 'atencion', component: AtencioncComponent },
        { path: 'segoficios', component: SeguimientoOficiosComponent, canActivate: [SeguimientoGuard] },
        { path: 'catalogo', component: CatalogoComponent },
        {
          path: 'oficialia',
          component: OficialiaPageComponent,
          canActivate: [OficialiaGuard]
        },
        {
          path: 'privada',
          component: PrivadaPageComponent,
          canActivate: [PrivadaGuard],
          children: [
            {
              path: 'agenda',
              component: AgendaPrivadaContenedorComponent,
              children: [
                {
                  path: 'agendaDia',
                  component: DetalleDiaPrivadaContenedorComponent,
                  children: [
                    //{ path: 'detalle/:id', component: DetalleAgendaComponent },

                    //{ path: '', component: DetalleAgendaComponent }
                  ]
                },
                { path: '', component: AgendaprivadaPageComponent }
              ]
            },
            { path: 'oficios', component: OficiosPrivadaPageComponent },
            { path: 'giras', component: GirasComponent },
            { path: 'reportes', component: ReportesComponent },
            { path: 'oficios/:consulta', component: OficiosPrivadaPageComponent },
            { path: '', component: AgendaprivadaPageComponent }
          ]
        },
        {
          path: 'tecnica',
          component: TecnicaPageComponent,
          canActivate: [TecnicaGuard],
          children: [
            {
              path: 'agenda',
              component: AgendaTecnicaContenedorComponent,
              children: [
                {
                  path: 'agendaDia',
                  component: DetalleDiaTecnicaContenedorComponent,
                  children: [
                    //{ path: 'detalle/:id', component: DetalleAgendaComponent },

                    //{ path: '', component: DetalleAgendaComponent }
                  ]
                },
                { path: '', component: AgendatecnicaPageComponent }
              ]
            },
            { path: 'oficios', component: OficiosTecnicaPageComponent },
            { path: '', component: TecnicaPageComponent }
          ]
        },

        {
          path: 'eventos',
          component: EventosPageComponent,
          children: [
            {
              path: 'agendaHoy',
              component: AgendaeventosHoyPageComponent,
              children: [
                { path: 'agendaDia', component: DetalleDiaEventoContenedorComponent },

                { path: '', component: AgendaeventosPageComponent }
              ]
            },
            {
              path: 'agendaManana',
              component: AgendaeventosMananaPageComponent,
              children: [
                { path: 'agendaDia', component: DetalleDiaEventoContenedorComponent },

                { path: '', component: AgendaeventosPageComponent }
              ]
            },
            {
              path: 'agendaTodos',
              component: AgendaEventoContenedorComponent,
              children: [
                { path: 'agendaDia', component: DetalleDiaEventoContenedorComponent },

                { path: '', component: AgendaeventosPageComponent }
              ]
            },
            {
              path: 'cumple',
              component: EventosContenedorComponent,
              children: [
                { path: 'todos', component: AgendaeventosPageComponent },
                { path: 'hoy', component: AgendaeventosHoyPageComponent },
                { path: 'manana', component: AgendaeventosMananaPageComponent },

                { path: '', component: EventosContenedorComponent }
              ]
            }
          ]
        },

        {
          path: 'prensa',
          component: PrensaPageComponent,
          canActivate: [PrensaGuard],
          children: [
            {
              path: 'agenda',
              component: AgendaPrensaContenedorComponent,
              children: [
                {
                  path: 'agendaDia',
                  component: DetalleDiaPrensaContenedorComponent,
                  children: [
                    { path: 'detalle/:id', component: DetalleAgendaComponent },

                    { path: '', component: DetalleAgendaComponent }
                  ]
                },
                { path: '', component: AgendaprensaPageComponent }
              ]
            },
            { path: 'oficios', component: OficiosPrensaPageComponent },
            { path: '', component: PrensaPageComponent }
          ]
        },

        {
          path: 'agenda',
          component: AgendaPageComponent,
          children: [
            {
              path: 'agendaDia',
              component: DetallediaPageComponent,
              children: [
                { path: 'detalle', component: DetalleAgendaComponent },

                { path: '', component: DetalleAgendaComponent }
              ]
            },
            { path: '', component: CalendarioComponent }
          ]
        },
        { path: 'dependencias', component: DependenciaComponent },
        { path: 'secretarios', component: SecretariosComponent },
        { path: 'estados', component: EstadosComponent },
        { path: 'municipios', component: MunicipiosComponent },
        { path: 'localidades', component: LocalidadesComponent },
        {
          path: 'configuracion',
          component: ConfiguracionPageComponent,
          children:[
            { path: 'detalle', component: ConfiguracionComponent },
            { path: 'usuarios', component: UsuariosComponent },
            { path: '', component: ConfiguracionComponent },
          ]
        },
        { path: 'perfil', component: PerfilPageComponent },
        {
          path: 'catalogos',
          component: CatalogosPageComponent,
          children: [
            { path: 'dependencias', component: DependenciaComponent },
            { path: 'secretarios', component: SecretariosComponent }
          ]
        },

        { path: '', pathMatch: 'full', redirectTo: 'home' }
      ]
    },
    { path: 'movil', component: MovilComponent },
    { path: 'login', component: LoginComponent },

    { path: '**', component: Error404Component }

  /*{ path: 'secretarias', component: SecretariasComponent, canActivate: [ GuardLoginGuard ]  },
  { path: 'secretarios', component: SecretariosComponent, canActivate: [ GuardLoginGuard ]  },
  { path: 'tiposarchivo', component: TiposarchivoComponent, canActivate: [ GuardLoginGuard ]  },
  { path: 'principal', component: PrincipalComponent, canActivate: [ GuardLoginGuard ]  },
  { path: 'home', component: HomeComponent },
  {
    path: 'tecnica', component: TecnicaComponent, canActivate: [ GuardLoginGuard ],
    children: [ // rutas hijas, se verán dentro del componente padre
      { path: 'agenda', component: AgendaComponent },
      { path: 'agenda2', component: FullcalendarComponent },
      { path: 'invitaciones', component: InvitacionesComponent },

      { path: '**', pathMatch: 'full', redirectTo: 'agenda2' }
    ]
  },
  {
    path: 'particular', component: ParticularComponent, canActivate: [ GuardLoginGuard ],
    children: [ // rutas hijas, se verán dentro del componente padre
      { path: 'agenda', component: AgendaComponent },
      { path: 'agenda2', component: FullcalendarComponent },
      { path: 'invitaciones', component: InvitacionesComponent },

      { path: '**', pathMatch: 'full', redirectTo: 'agenda2' }
    ]
  },

  {
    path: 'detalleAge', component: ContenidodetalleecentoComponent,
    children: [ // rutas hijas, se verán dentro del componente padre
      { path: 'detalleAgenda', component: DetalleagendaComponent },
      { path: 'adjuntos', component: AdjuntosComponent },
      { path: 'contactos', component: ContactosagendaComponent },
      { path: 'oficioinvitacion', component: OficioinvitacionComponent },
      { path: '**', pathMatch: 'full', redirectTo: 'detalleAgenda' }
    ]
  },
  {
    path: 'particular',
    component: ParticularComponent
  },*/



];


//export const app_routing = RouterModule.forRoot(app_routes);
export const app_routing = RouterModule.forRoot(app_routes, { useHash: true });
