import { Component, OnInit, OnChanges } from '@angular/core';
import { CctModel } from '../../models/cct';
import { CctService } from '../../services/cct.service';

@Component({
  selector: 'app-catalogo',
  templateUrl: './catalogo.component.html'
})
export class CatalogoComponent implements OnInit, OnChanges {

  titulo = 'Catalogo de Centros de Trabajo';

  centros: CctModel[] = [];
  activa = false;

  constructor(private _cctService: CctService) {
    this.getCentros();
  }

  ngOnInit() {
    // this.inicializaTabla();
    // setTimeout(this.inicializaTabla, 300);
  }

  ngOnChanges() {
  }

  getCentros() {
    this._cctService.getCentros().subscribe(
      data => {
        this.centros = data;
        console.log(this.centros);
      }
    );
  }

  inicializaTabla() {
    if ( !this.activa ) {
      $('#data-table-1').dataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [
          'copy', 'csv', 'excel', 'pdf', 'print',
          {
              extend: 'pdf',
              orientation: 'landscape',
              pageSize: 'LEGAL'
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
    }
  }

  saludar() {
    alert('Hola');
  }

}
