import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  usuario:Usuario;

  constructor() {
    this.usuario = new Usuario();
  }

  ngOnInit() {
    setTimeout(() => {
      init_plugins();
      //location.reload();
    }, 500);

    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  cierraMenu() {
    $('#menu').click();
  }

}
