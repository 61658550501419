import { Component, OnInit, Input, Output, EventEmitter, OnChanges, AfterViewInit } from '@angular/core';
import { DependenciaService } from '../../../services/dependencia.service';
import { AgendaService } from '../../../services/agenda.service';
import { FuncionarioService } from '../../../services/funcionario.service';
import { oficioModel } from '../../../models/oficio';
import { agendaModel } from '../../../models/agenda';
import { respuesta } from '../../../models/respuesta';
import { Funcionario } from '../../../models/Funcionario';
import { Persona } from '../../../models/persona';
import { Ciudadano } from '../../../models/Ciudadano';
import { Usuario } from '../../../models/Usuario';
import { Dependencia } from '../../../models/dependencia';
import { Localidad } from '../../../models/localidad';
import { Gira } from '../../../models/Gira';
import localeEs from '@angular/common/locales/es';

@Component({
  selector: 'app-alta-agenda',
  templateUrl: './alta-agenda.component.html'
})
export class AltaAgendaComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() objOficio: oficioModel;
  @Input() tipo: string = 'OFICIO';
  @Input() consulta: string = '';
  @Input() tipoPersonas: string = '';
  @Input() escogeTipo: boolean = true;
  @Input() escogeGira: boolean = true;
  @Input() objAgenda: agendaModel = new agendaModel();
  @Input() control: string = '';
  @Input() duplica: string = '';


  @Output() getAgenda: EventEmitter<agendaModel> = new EventEmitter();

  sinhorario: boolean = false;
  todo_el_dia: boolean = false;
  sinHoraFin: boolean = false;


  resp: respuesta;

  tituloModal: string;
  dependencia: string;

  horaAux: Date;
  horaAnte: string = '';

  asiste: string[] = ['SI', 'NO', 'SIN CONFIRMAR'];
  vestimentas = [
    'SIN DEFINIR',
    'FORMAL',
    'FORMAL - CASUAL',
    'INSTITUCIONAL',
    'CASUAL',
    'CASUAL (BOTAS DE TRABAJO)',
    'GUAYABERA',
    'TRAJE CHARRO'];

  funcionario: Persona;
  funcionarios: Usuario[] = [];
  funcionarioAsiste: Persona;
  ciudadado: Persona;
  representante: Usuario;
  gira: Gira;

  dependencias: Dependencia[] = [];

  bloqueo: boolean = false;
  nuevoTipo: string = '';

  constructor(
    private _dependenciaService: DependenciaService,
    private _funcionarioservice: FuncionarioService,
    private agendaService: AgendaService) {


    this.objOficio = new oficioModel();
    //this.objAgenda = new agendaModel();
    this.resp = new respuesta();

    this.funcionario = new Persona();
    this.funcionarioAsiste = new Persona();
    this.ciudadado = new Persona();
    this.representante = new Usuario();
    this.gira = new Gira();

  }

  ngOnInit() {
    this.horaAux = new Date();
    this.getDependencias();
    this.inicializaHoras();
  }

  ngAfterViewInit() {
    /*$('.clockpicker').clockpicker().find('#horaIni').change(function(){
      console.log(this.value);
      this.objAgenda = this.value;
    });*/
    this.inicializaHoras();
  }

  inicializaHoras() {

    console.log('Inicializando Relojes');
    $('#clockpickerHI').clockpicker({
      placement: 'top',
      align: 'left',
      donetext: 'Seleccionar',
      'default': 'now',
      autoclose: true,
      afterDone: () => {
        console.log('done');
      }
    }).find('input').change((data) => {
      this.objAgenda.horaIni2 = data.currentTarget.value;
      console.log("Hora de Inicio: " + this.objAgenda.horaIni2);

      this.objAgenda.horaFin2 = "";
    });

    $('#clockpickerHF').clockpicker({
      placement: 'top',
      align: 'left',
      donetext: 'Seleccionar',
      'default': 'now',
      autoclose: true,
      afterDone: () => {
        console.log('done');
      }
    }).find('input').change((data) => {

      this.objAgenda.horaFin2 = data.currentTarget.value;
      console.log("Hora de Fin: " + this.objAgenda.horaFin2);
    });
  }


  verHora(event) {
    alert('hola');
    console.log(event);
  }

  ngOnChanges() {
    //alert(this.objAgenda.nombreenvia);
    this.ciudadado = new Persona();
    this.ciudadado.idpersona = this.objAgenda.invita;
    this.ciudadado.nombreCompleto = this.objAgenda.nombreenvia;
    this.horaAux = new Date(this.objAgenda.hora);
    this.dependencia = this.objAgenda.iddependencia;
    this.getSecretarioPorDependencia(this.dependencia);
    this.inicializaHoras();

    if (this.control === 'cumpleaños') {
      this.objAgenda.tipo = 'CUMPLEAÑOS';
      this.escogeTipo = false;
    }

    if (this.objAgenda.sinhorario === '1') {
      this.sinhorario = true;
    } else {
      this.sinhorario = false;
    }

    if (this.objAgenda.todo_el_dia === '1') {
      this.todo_el_dia = true;
    } else {
      this.todo_el_dia = false;
    }

    if (this.objAgenda.sinHoraFin === '1') {
      this.sinHoraFin = true;
    } else {
      this.sinHoraFin = false;
    }
    //console.log(this.ciudadado);
  }

  // Invitaciones para Agendar


  altaInvitacion() {

    // alert(this.objOficio.idoficio);
    this.objAgenda = new agendaModel();
    this.objAgenda.idoficio = this.objOficio.idoficio;
    this.objAgenda.tipo = this.tipo;

    this.objAgenda.invita = this.objOficio.enviado;
    this.objAgenda.nombreenvia = this.objOficio.enviadoNombre;
    this.objAgenda.evento = this.objOficio.asunto;

    this.objAgenda.idestado = this.objOficio.idestado;
    this.objAgenda.idmunicipio = this.objOficio.idmunicipio;
    this.objAgenda.idlocalidad = this.objOficio.idlocalidad;

    this.ciudadado = new Ciudadano();
    this.ciudadado.idpersona = this.objOficio.enviado;
    this.ciudadado.nombreCompleto = this.objOficio.enviadoNombre;

    this.objAgenda.lugar = this.objOficio.lugar;
    this.objAgenda.fecha = this.objOficio.fechaevento;

    if (this.objOficio.tipo == null) {

      this.tituloModal = "Nueva Invitación";

    } else {

      this.tituloModal = "Modifica Invitación";
      this.objAgenda.idoficio = this.objOficio.idoficio;
      this.consultaAgendaPorOficio();




    }

    this.dependencia = "";

    $('#altaInvitacionModal2').modal('show');
  }

  guardarInvitacion() {

    this.objAgenda.invita = null;
    this.objAgenda.fecha = new Date(this.objAgenda.fecha).toJSON().split('T')[0];
    //alert(this.objAgenda.fecha);
    let d = this.horaAux;
    this.objAgenda.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
    //alert(d.getDate());
    this.agendaService.objAgenda = this.objAgenda;



    this.agendaService.agregarAgenda().subscribe(
      data => {
        this.resp = data;
        //alert(this.resp.mensaje);
        //new notificacion().simpleInfo(this.resp.mensaje);
        //swal("Guardada", this.resp.mensaje, "info");
        if (!this.resp.error) {
          $('#altaInvitacionModal').modal('hide');
          swal("Guardada", this.resp.mensaje, "info");
        } else {
          swal("Error", this.resp.mensaje, "error");
        }
        //this.arrOficios = this.resp.data;
        //console.log(data);

        this.emitirAgenda();
      }
    );
    console.log(this.objAgenda);
  }

  modificaInvitacion() {

    this.objAgenda.fecha = new Date(this.objAgenda.fecha).toJSON().split('T')[0];
    if (this.horaAnte !== this.objAgenda.hora) {
      //var d = new Date(this.objAgenda.hora);
      //this.objAgenda.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
      //alert(this.objAgenda.hora);
    }
    let d = this.horaAux;
    this.objAgenda.hora = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() + " " + d.getHours() + ":" + d.getMinutes();
    //alert(this.objAgenda.hora);

    this.agendaService.objAgenda = this.objAgenda;

    this.agendaService.modificaAgenda().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          $('#altaInvitacionModal').modal('hide');
        }
        //alert(this.resp.mensaje);
        swal("Modificado", this.resp.mensaje, "info");
        //this.arrOficios = this.resp.data;
        //console.log(data);
        //this.consultaOficios();
        this.emitirAgenda();
      }
    );
    console.log(this.objAgenda);
  }



  consultaAgendaPorOficio() {
    this.agendaService.objAgenda = this.objAgenda;
    this.agendaService.consultaAgendaPorOficio().subscribe(
      data => {
        this.resp = data;
        this.objAgenda = this.resp.data[0];
        this.horaAnte = this.objAgenda.hora;
        //console.log(this.objAgenda);
        this.validaHora();


        //Cargados cuando hacemos una modificacion
        this.funcionarioAsiste = new Persona();
        this.funcionarioAsiste.idpersona = this.objAgenda.asiste;
        this.funcionarioAsiste.nombreCompleto = this.objAgenda.nombreasiste;
        this.dependencia = this.objAgenda.iddependencia;
        this.representante.idpersona = this.objAgenda.representacion;
        this.representante.nombreCompleto = this.objAgenda.nombrerepresentacion;

        this.gira.idgira = this.objAgenda.idgira;
        this.gira.gira = this.objAgenda.gira;

        console.log(this.objAgenda);
        console.log(this.funcionarioAsiste);

      }
    );

  }

  validaHora() {
    if (new Date(this.objAgenda.hora).toString() === 'Invalid Date') {
      // alert('Fecha Invalida - ' + this.objAgenda.hora);
      this.horaAux = new Date();
      // alert(this.objAgenda.fecha);
    } else {
      // alert('Fecha Valida' + this.objAgenda.hora);
      this.horaAux = new Date(this.objAgenda.hora);
    }
  }




  altaEnviado(idUsuario) {
    // alert(idUsuario);
    this.tipoPersonas = 'CIUDADANO';
    this.objOficio.enviado = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
  }

  modificaEnviado(idUsuario) {
    this.tipoPersonas = 'CIUDADANO';
    // alert(idUsuario);
    this.objOficio.enviado = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
  }

  altaDirigido(idUsuario) {
    this.tipoPersonas = 'FUNCIONARIO';
    // alert(idUsuario);
    this.objOficio.dirigido = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
  }

  modificaDirigido(idUsuario) {
    this.tipoPersonas = 'FUNCIONARIO';
    // alert(idUsuario);
    this.objOficio.dirigido = idUsuario;
    this.escogeTipo = false;
    $('#usrModal').modal('show');
  }

  recibirLocalidad(event: Localidad) {
    //console.log("Localidad Recibida: " + JSON.stringify(event));
    this.objOficio.idestado = event.idestado;
    this.objOficio.idmunicipio = event.idmunicipio;
    this.objOficio.idlocalidad = event.idlocalidad;
  }

  recibirLocalidadInvitacion(event: Localidad) {
    //console.log("Localidad Recibida: " + JSON.stringify(event));
    this.objAgenda.idestado = event.idestado;
    this.objAgenda.idmunicipio = event.idmunicipio;
    this.objAgenda.idlocalidad = event.idlocalidad;
  }

  onSubmit() {
    //alert();
  }


  getCiudadanoSeleccionado(ciudadado: Usuario) {
    //console.log('Persona Seleccionada: ' + ciudadado.nombreCompleto);
    //this.ciudadado = ciudadado;
    //this.objOficio.enviado = ciudadado.idpersona;
    // console.log('Ciudadano ya recibido: ' + this.ciudadado.nombreCompleto);

    this.objAgenda.invita = ciudadado.idpersona;
  }

  getFuncionarioSeleccionado(funcionario: Usuario) {
    // console.log('Persona Seleccionada: ' + funcionario);
    this.funcionario = funcionario;
    this.objOficio.dirigido = funcionario.idpersona;
  }

  getFuncionarioAsiste(funcionario: Usuario) {
    // console.log('Persona Seleccionada: ' + funcionario);
    this.funcionarioAsiste = funcionario;
    this.objAgenda.asiste = funcionario.idpersona;
  }



  personaCreada(event) {
    console.log(event);
  }


  valida() {
    //alert('valida');


    if (this.objAgenda.idagenda === '') {
      this.guardarInvitacion();
    } else {
      this.modificaInvitacion();

    }
  }




  getDependencias() {

    this._dependenciaService.getDependencias().subscribe(
      data => {
        this.resp = data;

        this.dependencias = this.resp.data;
        //console.log(data);
      }
    );
  }

  consultaSecretarios(event) {
    //console.log(event);
    //console.log(event.target.value);
    this.getSecretarioPorDependencia(event.target.value);
  }

  getSecretarioPorDependencia(dependencia) {
    this._funcionarioservice.usuario.iddependencia = dependencia;
    this._funcionarioservice.getFuncionariosPorDependencia().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          alert(this.resp.mensaje);
        }
        this.funcionarios = this.resp.data;
        console.log(data);
      }
    );
  }

  getGiraSeleccionada(event: Gira) {
    this.gira = event;
    this.objAgenda.idgira = event.idgira;
  }

  getTipoSeleccionado(event: string) {
    this.objAgenda.tipo = event;
    if (this.objAgenda.tipo === 'BLOQUEADO') {
      this.bloqueo = true;
      this.objAgenda.evento = 'BLOQUEADO';
      this.objAgenda.lugar = 'BLOQUEADO';

      this.objAgenda.horaIni2 = '00:00:00';
      this.objAgenda.horaFin2 = '23:59:59';
    } else {
      this.bloqueo = false;
      if (this.objAgenda.tipo === 'AJUSTE DE TIEMPO') {
        this.objAgenda.evento = 'AJUSTE DE TIEMPO';
        this.objAgenda.lugar = 'SIN DEFINIR';

      }
    }
  }

  emitirAgenda() {

    this.getAgenda.emit(this.objAgenda);

  }

  horarioPorDefinir(event: any) {
    //console.log(event);
    if (this.sinhorario) {
      this.objAgenda.sinhorario = '1';
      this.objAgenda.horaIni2 = "";
      this.objAgenda.horaFin2 = "";
    } else {
      this.objAgenda.sinhorario = '0';
    }
  }

  horarioTodoElDia(event: any) {
    //console.log(event);
    if (this.todo_el_dia) {
      this.objAgenda.todo_el_dia = '1';
      this.objAgenda.horaIni2 = "";
      this.objAgenda.horaFin2 = "";
    } else {
      this.objAgenda.todo_el_dia = '0';
    }
  }

  horarioSinFin(event: any) {
    //console.log(event);
    if (this.sinHoraFin) {
      this.objAgenda.sinHoraFin = '1';
      //this.objAgenda.horaIni2 = "";
      //this.objAgenda.horaFin2 = "";
    } else {
      this.objAgenda.sinHoraFin = '0';
      this.objAgenda.horaFin2 = '';
    }
  }


}
