import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-localidades',
  templateUrl: './localidades.component.html',
  styleUrls: ['./localidades.component.css']
})
export class LocalidadesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
