import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Usuario } from '../../models/Usuario';

@Injectable()
export class PrivadaGuard implements CanActivate {

  usuario: Usuario;


  constructor(public router:Router){
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  canActivate() {
    

    if(this.usuario.privada==='1'){
      console.log("Acceso a Secretaria Privada");
      return true;
    }else{
      console.log("Usuario Bloqueado");
      //this.router.navigate(["/login"]);
      return false;
    }
  }
}
