import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Persona } from '../../models/persona';
import { FuncionarioService } from '../../services/funcionario.service';
import { CiudadanoService } from '../../services/ciudadano.service';
import { respuesta } from '../../models/respuesta';
import { viewClassName } from '@angular/compiler';
import { Usuario } from '../../models/Usuario';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';



@Component({
  selector: 'app-autocomplet-persona',
  templateUrl: './autocomplet-persona.component.html'
})
export class AutocompletPersonaComponent implements OnInit, OnChanges {
  @Input() tipoPersona: string;
  @Input() persona: Usuario;
  @Input() escogeTipo: boolean;
  @Input() crearPersonas: boolean;
  @Input() muestraModal: boolean = true;
  @Input() requerido: boolean = true;
  @Output() getPersonaSelecionada: EventEmitter<Usuario> = new EventEmitter();

  @ViewChild('personaID') personaID: any;
  @ViewChild('modalAltaPersona') modalAltaPersona: ElementRef;

  nuevoUsu:boolean = false;

  // persona: Persona;
  personas: Persona[] = [];
  personasFiltro: Persona[] = [];
  respuesta: respuesta;
  nuevaPersona = false;

  constructor(private _funcionarioService: FuncionarioService, private _ciudadanoService: CiudadanoService) {
    this.persona = new Usuario();
    this.respuesta = new respuesta();
  }

  ngOnInit() {
    if (this.tipoPersona === 'CIUDADANO') {
      this.getCiudadanos();
    } else {
      this.getFuncionarios();
    }

    //console.log(this.personaID);
  }

  ngOnChanges() {
    //alert(this.persona.nombreCompleto);
    console.log(this.persona.idpersona + " " + this.persona.nombreCompleto);
    // this.persona.nombreCompleto = this.nombre;
    // this.filtrarPersonas({query: this.nombre});
    //console.log('Nombre Completo al Cargar: ' + this.persona.nombreCompleto);
  }

  getFuncionarios() {
    this._funcionarioService.getFuncionarios().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.personas = this.respuesta.data;
          //console.log(this.personas);
        }
      }
    );
  }

  getCiudadanos() {
    this._ciudadanoService.getCiudadanos().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.personas = this.respuesta.data;
          //console.log(this.personas);
        }
      }
    );
  }


  lanzaPersona() {
    //console.log(JSON.stringify(this.persona));
    this.getPersonaSelecionada.emit(this.persona);
  }


  filtrarPersonas(event) {
    //console.log('Evento: ' + JSON.stringify(event));
    this.personasFiltro = [];
    for (let i = 0; i < this.personas.length; i++) {
      let per = new Persona();
      per = this.personas[i];
      per.nombreCompleto = per.prefijonombre + ' ' + per.nombre + ' ' + per.appaterno + ' ' + per.apmaterno;
      // const nomCom = per.getNombreCompleto();

      const cad = per.nombreCompleto + ' ' + per.dependencia + ' ' + per.secretaria;
      if (cad.toLowerCase().indexOf(event.query.toLowerCase()) !== -1) {
          this.personasFiltro.push(per);
      }
    }
    // console.log(this.personasFiltro);
  }

  muestraEstado() {
    alert('Persona Seleccionado: ' + this.persona.nombreCompleto);
  }

  altaPersona() {
    if(this.muestraModal){
      $(this.modalAltaPersona.nativeElement).modal('show');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
    }else{
      this.nuevoUsu=true;
    }
  }

  personaCreada(event: Usuario) {
    //console.log("Persona Creda sin Parse: " + event);
    //console.log("Persona Creda" + JSON.stringify(event));
    this.persona = event;
    //console.log("Persona ya pasada: " + this.persona);
    if (this.tipoPersona === 'CIUDADANO') {
      this.getCiudadanos();
    } else {
      this.getFuncionarios();
    }
    $(this.modalAltaPersona.nativeElement).modal('hide');
    this.lanzaPersona();
  }



  cerrarAltaPersona() {
    //$('#modalAltaPersona-' + this.tipoPersona).modal('hide');
    $(this.modalAltaPersona.nativeElement).modal('hide');
    
  }


}
