import { Persona } from './persona';


export class contactoModel extends Persona{
  idcontacto: string;
  //nombre: string;
  telefono: string;
  idagenda: string;

}
