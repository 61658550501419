import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../models/Usuario';
import { UsuarioService } from '../../services/usuario.service';
import { respuesta } from '../../models/respuesta';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  logueado: boolean = false;
  usuario: Usuario;
  resp: respuesta;


  constructor(private router: Router, private _usuarioService: UsuarioService, private cookieService: CookieService) {
    this.usuario = new Usuario();
    this.resp = new respuesta();
  }

  ngOnInit() {
  }

  login() {
    this._usuarioService.usuario = this.usuario;
    this._usuarioService.loginWeb().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          //new notificacion().simpleError(this.resp.mensaje);
          swal("Error!", this.resp.mensaje, "error");
          this.usuario.psw = '';
        }else{
          const d = new Date();
          console.log(this.resp.data);
          this.usuario = this.resp.data[0];
          localStorage.setItem('usuario', JSON.stringify(this.usuario));
          this.logueado = true;
          localStorage.setItem('logueado', JSON.stringify(this.logueado));

          //this.registraToken();

          //this.cookieService.set('SAG_Atencion', this.usuario.atencion, 3000, '/', 'nayarit.gob.mx', true);

          this.setCookie('SAG_Atencion', this.usuario.atencion, 1);


          this.router.navigate(['/home']);
        }
      }
    );
  }

  setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    //document.cookie = cname + "=" + cvalue + ";max-age=120;path=/;";
    document.cookie = "SAG_Atencion="+this.usuario.atencion+";max-age=1800;path=/;domain=*.nayarit.gob.mx;";
}

  registraToken(){
    this._usuarioService.tk = this.usuario.tk;
    this._usuarioService.idatencion = this.usuario.idatencion;
    this._usuarioService.registraToken().subscribe(
      data => {
        this.resp = data;
        console.log(this.resp);
        //this.url = "http://acconsulta.nayarit.gob.mx/login.aspx?idd=" + this.usuario.idatencion + "&tk=" + this.usuario.tk;
      }
    );
  }

}
