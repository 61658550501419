import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../../models/Usuario';

@Component({
  selector: 'app-oficios-tecnica-page',
  templateUrl: './oficios-tecnica-page.component.html'
})
export class OficiosTecnicaPageComponent implements OnInit {

  usuario: Usuario;

  constructor() { 
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit() {
  }

}
