import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(public router:Router){

  }

  canActivate() {
    if(JSON.parse(localStorage.getItem('logueado'))){
      console.log("Usuario Logueado");
      return true;
    }else{
      console.log("Usuario Bloqueado, no Logueado");
      this.router.navigate(["/login"]);
      return false;
    }
  }
}
