import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { agendaModel } from '../../models/agenda';
import { respuesta } from '../../models/respuesta';
import { Acompanante } from '../../models/acompanante';
import { DependenciaService } from '../../services/dependencia.service';
import { AgendaService } from '../../services/agenda.service';

@Component({
  selector: 'app-acompanantes2',
  templateUrl: './acompanantes2.component.html'
})
export class Acompanantes2Component implements OnInit, OnChanges {

  @Input() agenda: agendaModel;


  resp: respuesta;
  acompanante2: Acompanante;
  acompanantes2: Acompanante[] = [];

  editando: boolean = false;
  idagenda: string;



  constructor(private _agendaService: AgendaService) {
    this.resp = new respuesta();
    this.acompanante2 = new Acompanante();

  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.acompanante2.idagenda = this.agenda.idagenda;
    this.idagenda = this.agenda.idagenda;
    this.getAcompanantes2();

  }

  getAcompanantes2() {
    this.acompanante2.idagenda = this.idagenda;
    this._agendaService.acompanante = this.acompanante2;
    this._agendaService.getAcompanantes2().subscribe(
      data => {
        this.resp = data;

        this.acompanantes2 = this.resp.data;
        console.log(data);

        this.editando = false;
        this.acompanante2 = new Acompanante();
      }
    );
  }

  guardaAcompanante2() {
    this._agendaService.acompanante = this.acompanante2;
    this._agendaService.acompanante.idagenda = this.agenda.idagenda;
    this._agendaService.guardaAcompanantes2().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          swal("Guardada", this.resp.mensaje, "info");
        } else {
          swal("Error", this.resp.mensaje, "error");
        }
        this.getAcompanantes2();

      }
    );
  }

  actualizaAcompanante2() {
    this._agendaService.acompanante = this.acompanante2;
    this._agendaService.actualizaAcompanantes2().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          swal("Actualizado", this.resp.mensaje, "info");
        } else {
          swal("Error", this.resp.mensaje, "error");
        }
        this.getAcompanantes2();

      }
    );
  }

  eliminaAcompanante2(acom) {
    console.log(acom);
    if (confirm("Estas seguro de eliminar este Acompañante de Evento?")) {
      this._agendaService.acompanante = acom;
      this._agendaService.eliminaAcompanante2().subscribe(
        data => {
          this.resp = data;
          if (this.resp.error) {
            alert(this.resp.mensaje);
          }
          this.getAcompanantes2();
          console.log(data);
        }
      );
    }
  }

  editaAcompanante2(acom) {
    console.log(acom);
    this.acompanante2 = acom;
    this.editando = true;
    console.log(this.editando);

  }

  cancelaEditaAcompanante2(acom) {
    this.acompanante2 = new Acompanante();
    this.editando = false;

  }

}
