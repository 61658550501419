export class oficioModel {
  idoficio: string;
  enviado: string;
  enviadoNombre: string;
  dirigido: string;
  dirigidoNombre: string;
  asunto: string;
  telefono: string;
  fecha: string;
  fechaF: string;
  oficio: string;
  archivo: string;
  tipo: string;
  imagen: string;
  estatus: string;

  idestado: string;
  idmunicipio: string;
  idlocalidad: string;

  fechaAgenda: string;

  categoria: string;
  posible: string;

  dependencia: string;
  secretaria: string;

  turno: string;
  turnoAnte: string;
  secretariaTurno: string;

  lugar: string;
  fechaevento:string;

  idusuario:string;

  email:string;


  constructor(){
    this.idestado = '18';
    this.idmunicipio = '0';
    this.idlocalidad = '0';
    this.categoria = 'GENERAL';
  }
}
