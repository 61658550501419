import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-municipios',
  templateUrl: './municipios.component.html',
  styleUrls: ['./municipios.component.css']
})
export class MunicipiosComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
