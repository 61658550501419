import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos-contenedor',
  templateUrl: './eventos-contenedor.component.html'
})
export class EventosContenedorComponent implements OnInit {

  titulo = "Cumpleaños";

  constructor() { }

  ngOnInit() {
  }

}
