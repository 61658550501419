import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FuncionarioService } from '../../services/funcionario.service';
import { Funcionario } from '../../models/Funcionario';
import { respuesta } from '../../models/respuesta';
import { Persona } from '../../models/persona';
import { Estadocivil } from '../../models/estadocivil';
import { EstadoCivilService } from '../../services/estadocivil.service';

@Component({
  selector: 'app-persona',
  templateUrl: './persona.component.html'
})
export class PersonaComponent implements OnInit, OnChanges {
  @Input() tipo: string;

  titulo = 'Control de Personas';
  subtitulo: string;
  activaTablePer = false;
  tablaPer: any;

  persona: Persona;
  tipoPersona = '';

  funcionario: Funcionario;
  funcionarios: Funcionario[] = [];

  estadosciviles: Estadocivil[] = [];

  respuesta: respuesta;

  constructor(private _funcionarioService: FuncionarioService, private _estadoCivilService: EstadoCivilService) {
    this.funcionario = new Funcionario();
    this.persona = new Persona();
    this.respuesta = new respuesta();

  }

  ngOnInit() {
    console.log('TIPO: ' + this.tipo);
    if (this.tipo === 'FUNCIONARIO') {
      this.getFuncionarios();
    } else {
      this.getCiudadanos();
    }
    this.subtitulo = this.tipo;
  }

  ngOnChanges() {
    console.log(this.tipo);
  }

  getFuncionarios() {
    this._funcionarioService.getFuncionarios().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.funcionarios = this.respuesta.data;
          console.log(this.funcionarios);
        }
      }
    );
  }

  getCiudadanos() {
    this._funcionarioService.getFuncionarios().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.funcionarios = this.respuesta.data;
          console.log(this.funcionarios);
        }
      }
    );
  }

  getEstadosCiviles() {
    this._estadoCivilService.getEstados().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.estadosciviles = this.respuesta.data;
          console.log(this.funcionarios);
        }
      }
    );
  }

  inicializaTabla() {
    if ( !this.activaTablePer ) {
      //alert("Ini tabla per!!");
      this.tablaPer = $('#tablaPersonas').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activaTablePer = true;
    }
  }

  inicializaTabla2() {
    if ( !this.activaTablePer ) {
      //alert("Ini tabla per2!!");
      this.tablaPer = $('#tablaPersonas').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [
          'copy', 'csv', 'excel', 'pdf', 'print',
          {
              extend: 'pdf',
              orientation: 'landscape',
              pageSize: 'LEGAL'
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activaTablePer = true;
    }
  }

  nuevoUsuario() {
    this.getEstadosCiviles();
    $('#altaUsrModal').modal('handleUpdate');
    $('#altaUsrModal').modal('show');

  }

}
