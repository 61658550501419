
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';


import { GLOBAL } from './global';
import { respuesta } from '../models/respuesta';
import { Usuario } from '../models/Usuario';


@Injectable()
export class UsuarioService {
  url = '';
  resp: respuesta;
  usuario: Usuario;
  headers: Headers = new Headers;

  tk: string;
  idatencion: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.usuario = new Usuario();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  getUsuarios(): Observable<respuesta> {
    try {
      const params = '';

      return this.http.post(this.url + 'Usuarios/getUsuarios', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        //console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  inactivaUsuarios(): Observable<respuesta> {
    try {
      const params = 'idusuario=' + this.usuario.idusuario;

      return this.http.post(this.url + 'Usuarios/cancelaUsuario', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        //console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  activaUsuarios(): Observable<respuesta> {
    try {
      const params = 'idusuario=' + this.usuario.idusuario;

      return this.http.post(this.url + 'Usuarios/activaUsuario', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        //console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  activaAppUsuarios(tipo: string): Observable<respuesta> {
    try {
      let params = 'idusuario=' + this.usuario.idusuario;
      params += '&tipo=' + tipo;

      console.log (params);
      return this.http.post(this.url + 'Usuarios/activaAppUsuario', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        //console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  desactivaAppUsuarios(): Observable<respuesta> {
    try {
      const params = 'idusuario=' + this.usuario.idusuario;

      return this.http.post(this.url + 'Usuarios/desactivaAppUsuario', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        //console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }


  loginWeb(): Observable<respuesta> {
    try {
      let params = 'email=' + this.usuario.email;
      params += '&psw=' + this.usuario.psw;
      //console.log(params);

      return this.http.post(this.url + 'Usuarios/loginWeb', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  guardaUsuario(): Observable<respuesta> {
    try {
      let params = "prefijonombre=" + this.usuario.prefijonombre;
      params += "&nombre=" + this.usuario.nombre;
      params += "&appaterno=" + this.usuario.appaterno;
      params += "&apmaterno=" + this.usuario.apmaterno;
      params += "&sexo=" + this.usuario.sexo;
      params += "&fechanac=" + this.usuario.fechanac;
      params += "&idestadocivil=" + this.usuario.idestadocivil;
      params += "&calleynumero=" + this.usuario.calleynumero;
      params += "&telefono=" + this.usuario.telefono;
      params += "&email=" + this.usuario.email;
      params += "&idestado=" + this.usuario.idestado;
      params += "&idmunicipio=" + this.usuario.idmunicipio;
      params += "&idlocalidad=" + this.usuario.idlocalidad;
      //para funcionarios
      params += "&iddependencia=" + this.usuario.iddependencia;
      params += "&idsecretaria=" + this.usuario.idsecretaria;
      params += "&titular=" + this.usuario.titular;
      //para Usuario
      if(this.usuario.oficialia){
        params += "&oficialia=1";
      }else{
        params += "&oficialia=0";
      }

      if(this.usuario.privada){
        params += "&privada=1";
      }else{
        params += "&privada=0";
      }

      if(this.usuario.tecnica){
        params += "&tecnica=1";
      }else{
        params += "&tecnica=0";
      }

      if(this.usuario.admin){
        params += "&admin=1";
      }else{
        params += "&admin=0";
      }

      params += "&psw=" + this.usuario.psw;

      console.log(params);
      return this.http.post(this.url + "Usuarios/guardaUsuario", params, {headers: this.headers}).pipe(map(res => {
        console.log(res);
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificaUsuario(): Observable<respuesta> {
    try {
      let params = "prefijonombre=" + this.usuario.prefijonombre;
      params += "&nombre=" + this.usuario.nombre;
      params += "&appaterno=" + this.usuario.appaterno;
      params += "&apmaterno=" + this.usuario.apmaterno;
      params += "&sexo=" + this.usuario.sexo;
      params += "&fechanac=" + this.usuario.fechanac;
      params += "&idestadocivil=" + this.usuario.idestadocivil;
      params += "&calleynumero=" + this.usuario.calleynumero;
      params += "&telefono=" + this.usuario.telefono;
      params += "&email=" + this.usuario.email;
      params += "&idestado=" + this.usuario.idestado;
      params += "&idmunicipio=" + this.usuario.idmunicipio;
      params += "&idlocalidad=" + this.usuario.idlocalidad;
      //para funcionarios
      params += "&iddependencia=" + this.usuario.iddependencia;
      params += "&idsecretaria=" + this.usuario.idsecretaria;
      params += "&titular=" + this.usuario.titular;
      //para Usuario
      if(this.usuario.oficialia){
        params += "&oficialia=1";
      }else{
        params += "&oficialia=0";
      }

      if(this.usuario.privada){
        params += "&privada=1";
      }else{
        params += "&privada=0";
      }

      if(this.usuario.tecnica){
        params += "&tecnica=1";
      }else{
        params += "&tecnica=0";
      }

      if(this.usuario.admin){
        params += "&admin=1";
      }else{
        params += "&admin=0";
      }

      params += "&psw=" + this.usuario.psw;
      params += "&idusuario=" + this.usuario.idusuario;
      params += "&idpersona=" + this.usuario.idpersona;

      console.log(params);
      return this.http.post(this.url + "Usuarios/modificaUsuario", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);
        this.resp = res.json();

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificaPsw(): Observable<respuesta> {
    try {
      let params = "idusuario=" + this.usuario.idusuario;
      params += "&psw=" + this.usuario.psw;

      console.log(params);
      return this.http.post(this.url + "Usuarios/modificaPsw", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);
        this.resp = res.json();

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }



  registraToken(): Observable<respuesta> {
    try {
      let params = 'idd=' + this.idatencion;
      //let params = 'idd=22';
      params += '&tk=' + this.tk;
      console.log(params);

      return this.http.post('https://wbserv.nayarit.gob.mx/wbs.asmx/ctoken', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        //console.log(this.resp);
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  getTokenSeguimiento(token:string): Observable<any> {
    try {
      //this.headers.append('Authorization', token);
      this.headers.append('Authorization', 'Bearer ' + token);
      //let params = '_token=' + token;
      //let params = 'idd=22';

      const headers2 = new Headers({
        'Authorization': 'Bearer ' + token
      });

      //console.log(params);
      let url = 'https://seguimiento.nayarit.gob.mx/oficios?_token=' + token;
      //let url = 'http://10.20.5.57:8000/oficios/';

      return this.http.get( url, {headers: headers2}).pipe(map(res => {
        //this.resp = res.json();
        console.log("URL: " + url);
        console.log(res);
        return res;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
