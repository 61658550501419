import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';
import { UsuarioService } from '../../services/usuario.service';
import { respuesta } from '../../models/respuesta';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html'
})
export class PerfilComponent implements OnInit {
  titulo = 'Perfil';

  usuario: Usuario;
  resp: respuesta;

  pswValid: boolean;

  psw:string;
  psw2:string;

  constructor(private _usuarioService: UsuarioService) {
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.resp = new respuesta();
  }

  ngOnInit() {
  }

  cambiaContra(){
    //$('#cambiaPswModal').modal('show');
    $('#validaPswActualModal').modal('show');
  }


  validaPswActual(){
    this.login();
    /*if(this.login()){
      this.psw='';
      $('#validaPswActualModal').modal('hide');
      $('#cambiaPswModal').modal('show');
    }*/
  }

  cambiaPsw(){
    if(this.psw !== this.psw2){
      swal("Error", 'Las Contraseñas no coinciden', "error");
    }else{
      this.usuario.psw = this.psw;
      this._usuarioService.usuario = this.usuario;
      this._usuarioService.modificaPsw().subscribe(
        data => {
          this.resp = data;
          if (!this.resp.error) {
            swal("Correcto!", this.resp.mensaje, "success");
            this.pswValid = false;
            $('#cambiaPswModal').modal('hide');
            this.psw='';
            this.psw2='';
          }else{
            swal("Error!", this.resp.mensaje, "error");
          }
        }
      );
    }
  }

  login() {
    this._usuarioService.usuario = this.usuario;
    this._usuarioService.loginWeb().subscribe(
      data => {
        this.resp = data;
        if (this.resp.error) {
          swal("Error!", this.resp.mensaje, "error");
          this.pswValid = false;
          return false;
        }else{
          $('#validaPswActualModal').modal('hide');
          $('#cambiaPswModal').modal('show');
          this.pswValid = true;
          return true;


        }
      }
    );
  }

}
