
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Localidad } from '../models/localidad';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';



@Injectable()
export class LocalidadService {
  url = '';
  localidad: Localidad;
  localidades: Localidad[] = [];
  resp: respuesta;
  headers: Headers = new Headers;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.localidad = new  Localidad();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  getLocalidades(): Observable<respuesta> {
    try {
      let params = 'idestado=' + this.localidad.idestado;
      params += '&idmunicipio=' + this.localidad.idmunicipio;
      return this.http.post(this.url + 'Localidades/getLocalidades', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
