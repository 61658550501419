import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tecnica-page',
  templateUrl: './tecnica-page.component.html',
  styles: []
})
export class TecnicaPageComponent implements OnInit { 
  titulo = 'Secretaría Tecnica';
  control = 'privada';
  agendado = true;

  constructor() { }

  ngOnInit() {
  }

}
