import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agendatecnica-page',
  templateUrl: './agendatecnica-page.component.html'
})
export class AgendatecnicaPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
