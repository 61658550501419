import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalle-dia-eventos-contenedor',
  templateUrl: './detalle-dia-eventos-contenedor.component.html'
})
export class DetalleDiaEventoContenedorComponent implements OnInit {
  fecha: string;

  constructor() {
    this.fecha = localStorage.getItem("fechaA");
  }

  ngOnInit() {
  }

}
