import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html'
})
export class ConfiguracionComponent implements OnInit {

  titulo = 'Configuraciones';
  constructor() { }

  ngOnInit() {
  }

}
