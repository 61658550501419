import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-colores-agenda',
  templateUrl: './colores-agenda.component.html'
})
export class ColoresAgendaComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
