import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { agendaModel } from '../../models/agenda';
import { GLOBAL } from '../../services/global';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-oficio-invitacion',
  templateUrl: './oficio-invitacion.component.html'
})
export class OficioInvitacionComponent implements OnInit, OnChanges {
  titulo:string = "Oficio de Invitación";
  imgModal:string;
  imgModalZan:any;
  tipoInvitacion:string;
  @Input() agenda: agendaModel;

  constructor(public sanitizer: DomSanitizer) {
    this.tipoInvitacion = localStorage.getItem('tipo');
  }

  ngOnInit() {}

  ngOnChanges(){
    this.imgModal = GLOBAL.url + '/Agendas/getImagenOficioAgendaWeb/' + this.agenda.idoficio + '?=' +  Math.random() * 100000000000000000000;
    this.imgModalZan = this.sanitizer.bypassSecurityTrustResourceUrl(this.imgModal);
  }

}
