import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Gira } from '../../models/Gira';
import { GiraService } from '../../services/gira.service';
import { respuesta } from '../../models/respuesta';
import localeEs from '@angular/common/locales/es';

@Component({
  selector: 'app-select-ruta',
  templateUrl: './select-ruta.component.html'
})
export class SelectRutaComponent implements OnInit {

  @Input() gira: string;
  @Input() escogeGira: boolean;
  @Input() activas: boolean = true;
  @Output() giraSeleccionada: EventEmitter<Gira> = new EventEmitter();

  giras: Gira[] = [];
  resp: respuesta;

  giraSel: Gira;

  constructor(private _giraService: GiraService) {
    this.resp = new respuesta();
  }

  ngOnInit() {
    if(this.activas){
      this.getGirasActivas();
    }else{
      this.getGirasCompletas();
    }
  }

  getGirasActivas() {
    this._giraService.getGirasActivas().subscribe(
      data => {
        this.resp = data;
        this.giras = this.resp.data;
        console.log(this.giras);
      }
    );
  }

  getGirasCompletas() {
    this._giraService.getGiras().subscribe(
      data => {
        this.resp = data;
        this.giras = this.resp.data;
        console.log(this.giras);
      }
    );
  }

  seleccionaRuta(){
    if(this.giras.find((x => x.idgira === this.gira))){
      this.giraSel = this.giras.find((x => x.idgira === this.gira));
    }else{
      this.giraSel = new Gira();
    }
    console.log(this.gira);
    this.giraSeleccionada.emit(this.giraSel);
  }

}
