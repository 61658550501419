import { Component, OnInit } from '@angular/core';
import { OficiosService } from '../../services/oficio.service';
import { oficioModel } from '../../models/oficio';
import { respuesta } from '../../models/respuesta';
import { Router } from '@angular/router';
import { Usuario } from '../../models/Usuario';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  resp: respuesta;
  oficio: oficioModel;
  oficios: oficioModel[] = [];
  fecha: any;
  usuario:Usuario;

  num: number = 0;

  constructor(private oficioService: OficiosService, private router: Router) {
    this.oficio = new oficioModel();
    this.resp = new respuesta();
    this.usuario = new Usuario();
  }

  ngOnInit() {
    //this.consultaOficiosSinAgendar();
    setInterval(() => {
      //this.consultaOficiosSinAgendar();
    }, 30000);

    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    
  }

  saluda(){
    this.num += 1;
  }

  consultaOficiosSinAgendar() {
    //alert('Consulta sin Agendar');
    const F = new Date();
    this.fecha  = F.getFullYear() + "/" + (F.getMonth()+1) + "/" + F.getDate();
    
    this.fecha = new Date(this.fecha).toJSON().split('T')[0];

    this.oficio.fecha = this.fecha;
    this.oficioService.objOficio = this.oficio;
    this.oficioService.consultaOficiosSinAgendar().subscribe(
      data => {
        this.resp = data;
        this.oficios = this.resp.data;
      }
    );
  }

  verFaltantes(){
    localStorage.setItem('consulta', 'faltantes');
    this.router.navigate(['/privada/oficios/' + Math.random() * 100000000000000000000]);
  }

  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }

}
