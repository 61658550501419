export class Persona {
  idpersona: string;
  prefijonombre: string;
  nombre: string;
  appaterno: string;
  apmaterno: string;
  sexo: string;
  fechanac: string;
  idestadocivil: string;
  calleynumero: string;
  telefono: string;
  email: string;
  perfil_ciudadano: string;
  perfil_funcionario: string;
  idestado: string;
  estado: string;
  idmunicipio: string;
  municipio: string;
  idlocalidad: string;
  localidad: string;
  nombreCompleto: string;
  
  dependencia: string;
  secretaria: string;
  nombramiento: string;

  constructor() {
    this.prefijonombre = 'C.';
    this.idestadocivil='1';
    this.idestado = '0';
    this.idmunicipio = '0';
    this.idlocalidad = '0';
  }

  public getNombreCompleto() {
    return (this.prefijonombre + ' ' + this.nombre + ' ' + this.appaterno + ' ' + this.apmaterno);
  }
}
