import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privada-page',
  templateUrl: './privada-page.component.html',
  styles: []
})
export class PrivadaPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
