import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html?v=${new Date().getTime()}',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  logueado: boolean = false;

  constructor(private router: Router){
    this.logueado = JSON.parse(localStorage.getItem('logueado'));
    if(!this.logueado){
      this.router.navigate(['/login']);
    }
  }

  ngOnInit(){

  }


}
