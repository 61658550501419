
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Municipio } from '../models/municipio';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';


@Injectable()
export class MunicipioService {
  url = '';
  municipio: Municipio;
  municipios: Municipio[] = [];
  resp: respuesta;
  headers: Headers = new Headers;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.municipio = new  Municipio();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  getMunicipios(): Observable<respuesta> {
    try {
      let params = 'idestado=' + this.municipio.idestado;
      return this.http.post(this.url + 'Municipios/getMunicipios', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
