import { respuesta } from './../../models/respuesta';

import { AgendaService } from './../../services/agenda.service';
import { Acompanante } from './../../models/acompanante';
import { agendaModel } from './../../models/agenda';
import { Component, OnInit, Input, OnChanges } from '@angular/core';


@Component({
  selector: 'app-lista-acompanantes-externos',
  templateUrl: './lista-acompanantes-externos.component.html'
})
export class ListaAcompanantesExternosComponent implements OnInit, OnChanges {

  @Input() agenda: agendaModel;


  resp: respuesta;
  acompanante2: Acompanante;
  acompanantes2: Acompanante[] = [];



  constructor(private _agendaService: AgendaService) {
    this.resp = new respuesta();
    this.acompanante2 = new Acompanante();
  }

  ngOnInit() {

  }

  ngOnChanges(){
    this.acompanante2.idagenda = this.agenda.idagenda;
    this.getAcompanantes2();
  }

  getAcompanantes2() {
    this._agendaService.acompanante = this.acompanante2;
    this._agendaService.getAcompanantes2().subscribe(
      data => {
        this.resp = data;

        this.acompanantes2 = this.resp.data;
        console.log(data);
      }
    );
  }


}
