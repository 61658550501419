import { Component, OnInit } from '@angular/core';
import { respuesta } from '../../models/respuesta';
import { DependenciaService } from '../../services/dependencia.service';
import { Dependencia } from '../../models/dependencia';

@Component({
  selector: 'app-dependencia',
  templateUrl: './dependencia.component.html',
  styles: []
})
export class DependenciaComponent implements OnInit {
  tituloModal:string= '';
  titulo: string = 'Catalogo de Dependencias';

  resp:respuesta;
  dependencia: Dependencia;
  dependencias: Dependencia[] = [];

  modificar: boolean = false;

  activa: boolean = false;

  constructor(private _dependenciaService:DependenciaService) {
    this.dependencia = new Dependencia();
    this.resp = new respuesta();
  }

  ngOnInit() {
    this.getDependencias();
  }

  inicializaTabla() {
    if ( !this.activa ) {
      $('#data-table-1').dataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [
          {
            extend:    'excelHtml5',
            text:      '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [ 0, 1 ]
            }
          },
          {
            extend:    'print',
            text:      '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function ( win ) {
              //$(win.document.body).css( 'font-size', '10pt' );
              $(win.document.body).find( 'table' ).addClass( 'compact' ).css( 'font-size', 'inherit' );
            },
            exportOptions: {
              columns: [ 0, 1 ]
            }
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
    }
  }

  altaDependencia(){
    this.dependencia = new Dependencia();
    this.modificar=false;
    this.tituloModal="Nueva Dependencia";
    $('#altaModal').modal('show');
  }

  getDependencias(){
    this._dependenciaService.getDependencias().subscribe(
      data => {
        this.resp = data;
        if(this.resp.error){
          alert(this.resp.mensaje);
        }
        this.dependencias = this.resp.data;
        console.log(data);
      }
    );
  }

  modificaDependencia(index) {
    this.modificar=true;
    this.dependencia = this.dependencias[index];
    this.tituloModal = "Modifica Dependencia";
    $('#altaModal').modal('show');

  }

  modificarDependencia() {

    this._dependenciaService.dependencia = this.dependencia;
    this._dependenciaService.modificarDependencia().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if(!this.resp.error){
          $('#altaModal').modal('hide');
        }

        this.getDependencias();
        //console.log(data);
      }
    );
  }

  eliminaDependencia(index) {
    this._dependenciaService.dependencia = this.dependencias[index];
    if (confirm("¿Desea eliminar la Dependencia?")) {
      this._dependenciaService.eliminaDependencia().subscribe(
        data => {
          this.resp = data;
          alert(this.resp.mensaje);
          //$('#altaModal').modal('hide');
          this.getDependencias();
          //console.log(data);
        }
      );
    }
  }

  activaDependencia(index) {
    this._dependenciaService.dependencia = this.dependencias[index];
    if (confirm("¿Desea Activar la Dependencia?")) {
      this._dependenciaService.activaDependencia().subscribe(
        data => {
          this.resp = data;
          alert(this.resp.mensaje);
          //$('#altaModal').modal('hide');
          this.getDependencias();
          //console.log(data);
        }
      );
    }
  }

}
