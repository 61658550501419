import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventos-page',
  templateUrl: './eventos-page.component.html',
  styles: []
})
export class EventosPageComponent implements OnInit { 
  titulo = 'Eventos';
  control = 'eventos';
  agendado = true;

  constructor() { }

  ngOnInit() {
  }

}
