import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oficialia-page',
  templateUrl: './oficialia-page.component.html',
  styles: []
})
export class OficialiaPageComponent implements OnInit {
  titulo = 'Oficialía de Partes';
  control = 'oficialia';
  agendado = false;

  constructor() { }

  ngOnInit() {
  }

}
