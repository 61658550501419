import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';
import { UsuarioService } from '../../services/usuario.service';
import { respuesta } from '../../models/respuesta';

@Component({
  selector: 'app-atencionc',
  templateUrl: './atencionc.component.html'
})
export class AtencioncComponent implements OnInit {
  usuario: Usuario;
  url: string;
  titulo = "Atención Ciudadana";
  resp: respuesta;

  constructor(private _usuarioService: UsuarioService) {
    this.usuario = new Usuario();
    this.resp = new respuesta();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    console.log(this.usuario);

    if(this.usuario.privada === '1'){
      this.url = "https://acconsulta.nayarit.gob.mx/login.aspx?idd=0&tk=" + this.usuario.tk;
    }else{
      this.url = "https://acconsulta.nayarit.gob.mx/login.aspx?idd=" + this.usuario.idatencion + "&tk=" + this.usuario.tk;
    }
    //this.url = "http://acconsulta.nayarit.gob.mx/login.aspx?idd=22&tk=" + this.usuario.tk;

  }

  ngOnInit() {
    this.registraToken();

  }


  registraToken(){
    if(this.usuario.privada === '1'){
      this._usuarioService.idatencion = '0';
    }else{
      this._usuarioService.idatencion = this.usuario.idatencion;
    }
    this._usuarioService.tk = this.usuario.tk;

    this._usuarioService.registraToken().subscribe(
      data => {
        this.resp = data;
        console.log(this.resp);

        if(this.usuario.privada === '1'){
          this.url = "https://acconsulta.nayarit.gob.mx/login.aspx?idd=0&tk=" + this.usuario.tk;
        }else{
          this.url = "https://acconsulta.nayarit.gob.mx/login.aspx?idd=" + this.usuario.idatencion + "&tk=" + this.usuario.tk;
        }
      }
    );
  }

}
