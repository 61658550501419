import { Component, OnInit } from '@angular/core';
import { respuesta } from '../../models/respuesta';

import { Usuario } from '../../models/Usuario';
import { UsuarioService } from '../../services/usuario.service';
import { Persona } from '../../models/persona';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
})
export class UsuariosComponent implements OnInit {
  tituloModal = '';
  titulo = 'Catalogo de Usuarios';
  altausuario: boolean = false;

  resp: respuesta;
  usuario: Usuario;
  usuarios: Usuario[] = [];

  tipoApp: string;


  modificar = false;

  activa: boolean = false;
  tabla: any = null;

  constructor(private _usuarioService:UsuarioService) {
    this.usuario = new Usuario();
    this.resp = new respuesta();
  }

  ngOnInit() {
    this.activa = false;
    //this.usuarios = [];
    this.getUsuarios();
  }

  getUsuarios() {

    this.activa = false;
    this.usuarios = [];
    if(this.tabla){
      this.tabla.destroy();
    }

    this._usuarioService.getUsuarios().subscribe(
      data => {
        this.resp = data;
        if(this.resp.error) {
          swal(this.resp.mensaje);
        }

        this.usuarios = this.resp.data;
        //console.log(data);
        setTimeout(() => {
          this.inicializaTabla();
        }, 1000);
      }
    );
  }

  altaUsuario() {
    this.usuario = new Usuario();
    this.modificar = false;
    this.tituloModal= 'Nuevo Usuario';
    //$('#altaUsuarioModal').modal('show');
    this.altausuario = true;
  }

  editarUsuario(index) {
    this.modificar = true;
    this.usuario = this.usuarios[index];
    this.tituloModal = 'Modifica Usuario';
    this.altausuario = true;
    console.log(this.usuario);
    //$('#altaUsuarioModal').modal('show');
  }


  guardaModificacionUsuario() {
    this._usuarioService.usuario = this.usuario;
    this._usuarioService.modificaUsuario().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if (!this.resp.error) {
          //$('#altaUsuarioModal').modal('hide');
          this.altausuario = false;
          this.getUsuarios();
        }
      }
    );
  }

  inactivaUsuario(i) {
    this._usuarioService.usuario = this.usuarios[i];
    this._usuarioService.inactivaUsuarios().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if (!this.resp.error) {
          //$('#altaUsuarioModal').modal('hide');
          this.altausuario = false;
          this.getUsuarios();
        }
      }
    );
  }

  activaUsuario(i) {
    this._usuarioService.usuario = this.usuarios[i];
    this._usuarioService.activaUsuarios().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if (!this.resp.error) {
          //$('#altaUsuarioModal').modal('hide');
          this.altausuario = false;
          this.getUsuarios();
        }
      }
    );
  }

  activaAppUsuario(i) {
    swal("Tipo de Privilegios de Usuario", {
      buttons: {
        cancel: "Cancelar",
        admin: {
          text: "Administrador",
          value: "1",
        },
        ejec: {
          text: "Ejecutivo",
          value: "2",
        },
        secre: {
          text: "Secretario",
          value: "3",
        },
      },
    })
    .then((value) => {
      this.tipoApp = value;
      this._usuarioService.usuario = this.usuarios[i];
      this._usuarioService.activaAppUsuarios(this.tipoApp).subscribe(
        data => {
          this.resp = data;
          swal(this.resp.mensaje);
          if (!this.resp.error) {
            this.getUsuarios();
          }
        }
      );
    });

  }

  desactivaAppUsuario(i) {
    this._usuarioService.usuario = this.usuarios[i];
    this._usuarioService.desactivaAppUsuarios().subscribe(
      data => {
        this.resp = data;
        swal(this.resp.mensaje);
        if (!this.resp.error) {
          this.getUsuarios();
        }
      }
    );
  }


  inicializaTabla() {

    if ( !this.activa ) {
      this.tabla = $('#tablaUsu').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        'buttons': [
          {
            extend:    'excelHtml5',
            text:      '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [ 0, 1, 2, 3, 4, 5, 6, 7 ]
            }
          },
          {
            extend:    'print',
            text:      '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function ( win ) {
              //$(win.document.body).css( 'font-size', '10pt' );
              $(win.document.body).find( 'table' ).addClass( 'compact' ).css( 'font-size', 'inherit' );
            },
            exportOptions: {
              columns: [ 0, 1, 2, 3, 4, 5, 6, 7 ]
            }
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
      return true;
    }
  }

  cancelarAltaUsuario(){

    this.altausuario=false;
    this.activa = false;
    this.getUsuarios();

  }


  getPersona(persona: Persona) {
    //console.log('Persona Seleccionada: ' + ciudadado.nombreCompleto);
    //this.usuario.idpersona = persona.idpersona;
    this.altausuario = false;
    this.activa = false;
    this.getUsuarios();

    // console.log('Ciudadano ya recibido: ' + this.ciudadado.nombreCompleto);
  }

}
