
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';
import { Secretaria } from '../models/secretaria';

@Injectable()
export class SecretariaService {
  url: string = "";
  secretaria:Secretaria;
  secretarias: Secretaria[] = [];
  resp: respuesta;
  headers:Headers = new Headers;

  //login
  email:string;
  psw:string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.secretaria = new  Secretaria();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  
  getSecretariasDependencia(): Observable<respuesta> {
    try {
      const params = "iddependencia=" + this.secretaria.iddependencia;
      return this.http.post(this.url + "Secretarias/getSecretariasDependencia", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getSecretarias(): Observable<respuesta> {
    try {
      const params = "iddependencia=" + this.secretaria.iddependencia;
      return this.http.post(this.url + "Secretarias/getSecretarias", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  

  agregarDependencia(): Observable<respuesta> {
    try {
      let params = "secretaria=" + this.secretaria.secretaria;
      params += "&iddependencia=" + this.secretaria.iddependencia;

      return this.http.post(this.url + "Secretarias/guardaSecretaria", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificarSeretaria(): Observable<respuesta> {
    try {
      let params = "secretaria=" + this.secretaria.secretaria;
      params += "&idsecretaria=" + this.secretaria.idsecretaria;

      return this.http.post(this.url + "Secretarias/modificaSecretaria", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaSecretaria(): Observable<respuesta>{
    try {
      const params = "idsecretaria=" + this.secretaria.idsecretaria;
      return this.http.post(this.url + "Secretarias/borraSecretarias", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  activaSecretaria(): Observable<respuesta>{
    try {
      const params = "idsecretaria=" + this.secretaria.idsecretaria;
      return this.http.post(this.url + "Secretarias/activarSecretaria", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

}
