
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { GLOBAL } from './global';
import { CctModel } from '../models/cct';


@Injectable()
export class CctService {
  url: string;
  headers: Headers = new Headers;
  centros: CctModel[] = [];

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    // this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  // Centros Escolares
  getCentros(): Observable<CctModel[]> {
    try {
      const params = 'clavecct=18d';
      return this.http.post(this.url + 'consultaCentros.php', params, { headers: this.headers }).pipe(map(res => {
        this.centros = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

}
