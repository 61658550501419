import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import localeEs from '@angular/common/locales/es';
import { Usuario } from '../../../models/Usuario';

@Component({
  selector: 'app-oficios-privada-page',
  templateUrl: './oficios-privada-page.component.html'
})
export class OficiosPrivadaPageComponent implements OnInit {
  usuario: Usuario;
  consulta: string = '';

  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    
    this.route.params.subscribe(params => {
      this.usuario = new Usuario();
      this.usuario = JSON.parse(localStorage.getItem('usuario'));
      
      if(params['consulta']!=null){
        if(localStorage.getItem('consulta')){
          //this.consulta = localStorage.getItem('consulta');
          this.consulta = params['consulta'];
        }else{
          this.consulta='';
        }
      }
      //alert(this.consulta);
    });
    //history.pushState(null, "", "#/privada/oficios/");
    
  
  }

}
