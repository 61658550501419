
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { adjuntoModel } from '../models/adjunto';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';

@Injectable()
export class AdjuntoService {
  url: string = "";
  objAdjunto: adjuntoModel;
  arrAdjunto: adjuntoModel[] = [];
  resp: respuesta;
  headers: Headers = new Headers;

  //login
  email: string;
  psw: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.objAdjunto = new adjuntoModel();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }


  getAdjuntos(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objAdjunto.idagenda;
      return this.http.post(this.url + "Adjuntos/getAdjuntos", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaAdjunto(): Observable<respuesta>{
    try {
      let params = "idarchivo=" + this.objAdjunto.idarchivo;
      return this.http.post(this.url + "Adjuntos/eliminaAdjunto", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  subirArchivo(params: Array<string>, file: Array<File>, idagenda:string, idtipo:string, descripcion:string) {
    console.log("Archivos: " + file.length);
    var dire = this.url + "Adjuntos/guardaAdjunto";
    return new Promise(function(resolve, reject) {
      var formData: any = new FormData();
      var xhr = new XMLHttpRequest();
      for (var i = 0; i < file.length; i++) {
        formData.append('archivo', file[i], file[i].name);
        //console.log(file[i].name);
      }

      formData.append('idagenda', idagenda);
      formData.append('idtipoarchivo', idtipo);
      formData.append('descripcion', descripcion);

      console.log(formData.get('archivo'));

      xhr.onreadystatechange = function() {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            console.log(xhr.response);
            resolve(JSON.parse(xhr.response));

          } else {
            reject(xhr.response);
          }
        }
      }

      xhr.open('POST', dire, true);
      //xhr.setRequestHeader('Authorization', token);
      xhr.send(formData);
    });
  }





}
