
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Funcionario } from '../models/Funcionario';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';
import { Usuario } from '../models/Usuario';


@Injectable()
export class FuncionarioService {
  url = '';
  usuario: Usuario;
  resp: respuesta;
  headers: Headers = new Headers;

  tipopersona: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.usuario = new  Usuario();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  getFuncionarios(): Observable<respuesta> {
    try {
      let params = '';
      return this.http.post(this.url + 'Funcionarios/getFuncionarios', params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log('Error:  ' + err);
    }
  }

  getFuncionariosPorDependencia(): Observable<respuesta> {
    try {
      let params = "iddependencia=" + this.usuario.iddependencia;
      return this.http.post(this.url + "Funcionarios/getFuncionarioPorDependencia", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  guardaCiudadano(): Observable<respuesta> {
    try {
      let params = "prefijonombre=" + this.usuario.prefijonombre;
      params += "&nombre=" + this.usuario.nombre;
      params += "&appaterno=" + this.usuario.appaterno;
      params += "&apmaterno=" + this.usuario.apmaterno;
      params += "&sexo=" + this.usuario.sexo;
      params += "&fechanac=" + this.usuario.fechanac;
      params += "&idestadocivil=" + this.usuario.idestadocivil;
      params += "&calleynumero=" + this.usuario.calleynumero;
      params += "&telefono=" + this.usuario.telefono;
      params += "&email=" + this.usuario.email;
      params += "&idestado=" + this.usuario.idestado;
      params += "&idmunicipio=" + this.usuario.idmunicipio;
      params += "&idlocalidad=" + this.usuario.idlocalidad;
      //para ciudadanos
      params += "&nombramiento=" + this.usuario.nombramiento;

      return this.http.post(this.url + "Ciudadanos/guardaCiudadano", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }



  guardaFuncionario(): Observable<respuesta> {
    try {
      let params = "prefijonombre=" + this.usuario.prefijonombre;
      params += "&nombre=" + this.usuario.nombre;
      params += "&appaterno=" + this.usuario.appaterno;
      params += "&apmaterno=" + this.usuario.apmaterno;
      params += "&sexo=" + this.usuario.sexo;
      params += "&fechanac=" + this.usuario.fechanac;
      params += "&idestadocivil=" + this.usuario.idestadocivil;
      params += "&calleynumero=" + this.usuario.calleynumero;
      params += "&telefono=" + this.usuario.telefono;
      params += "&email=" + this.usuario.email;
      params += "&idestado=" + this.usuario.idestado;
      params += "&idmunicipio=" + this.usuario.idmunicipio;
      params += "&idlocalidad=" + this.usuario.idlocalidad;
      //para funcionarios
      params += "&iddependencia=" + this.usuario.iddependencia;
      params += "&idsecretaria=" + this.usuario.idsecretaria;
      params += "&titular=" + this.usuario.titular;

      params += "&tipopersona=" + this.tipopersona;


      return this.http.post(this.url + "Funcionarios/guardaFuncionario", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }



}
