import { Funcionario } from './Funcionario';
import { Dependencia } from './dependencia';


export class Usuario extends Funcionario {
    idusuario: string;
    token: string;
    sistema: string;
    email: string;
    psw: string;
    estatus: string;

    oficialia: any;
    privada: any;
    tecnica: any;
    admin: any;

    app: any;

    iddependencia: string;
    dependencia: string;
    idsecretaria: string;
    secretaria: string;
    prensa: string;
    logistica: string;
    discurso: string;
    atencion: string;
    apoyo: string;
    consulta: string;
    cumple: string;
    seguimiento: string;

    idatencion: string;
    tk: string;
    tkSeg: string;
    AccessToken: string;
}
