import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agendaeventos-page',
  templateUrl: './agendaeventosmanana-page.component.html'
})
export class AgendaeventosMananaPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
