
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { GLOBAL } from './global';
import { Gira } from '../models/Gira';
import { respuesta } from '../models/respuesta';


@Injectable()
export class GiraService {
  url: string;
  headers: Headers = new Headers;
  gira: Gira;
  giras: Gira[] = [];
  resp: respuesta;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.resp = new respuesta();
    this.gira = new Gira();
    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }


  getGiras(): Observable<respuesta> {
    try {
      let params = '';
      return this.http.post(this.url + 'Giras/getGiras', params, { headers: this.headers }).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  getGirasActivas(): Observable<respuesta> {
    try {
      let params = '';
      return this.http.post(this.url + 'Giras/getGirasActivas', params, { headers: this.headers }).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log('Error: ' + err);
    }
  }

  guardarGira(): Observable<respuesta> {
    try {
      let params = "gira=" + this.gira.gira;
      params += "&fechaInicio=" + this.gira.fechaInicio;
      params += "&fechaFin=" + this.gira.fechaFin;
      console.log(params);

      return this.http.post(this.url + "Giras/guardarGira", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificarGira(): Observable<respuesta> {
    try {
      let params = "gira=" + this.gira.gira;
      params += "&fechaInicio=" + this.gira.fechaInicio;
      params += "&fechaFin=" + this.gira.fechaFin;
      params += "&idgira=" + this.gira.idgira;

      console.log(params);
      return this.http.post(this.url + "Giras/modificarGira", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  cancelarGira(): Observable<respuesta>{
    try {
      let params = "idgira=" + this.gira.idgira;
      return this.http.post(this.url + "Giras/cancelarGira", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  activarGira(): Observable<respuesta>{
    try {
      let params = "idgira=" + this.gira.idgira;
      return this.http.post(this.url + "Giras/activarGira", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

}
