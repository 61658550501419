import { Ciudadano } from './Ciudadano';


export class Funcionario extends Ciudadano {
    idfuncionario: string;
    iddependencia: string;
    titular: string;
    fechaalta: string;
    fechabaja: string;
    estatus: string;

    dependencia: string;

    app: string;
    idusuario: string;
    confirmar: string;
    observacion: string;

    constructor() {
        super();
    }
}
