import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alta-oficio',
  templateUrl: './alta-oficio.component.html'
})
export class AltaOficioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
