import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter, DoCheck } from '@angular/core';
import { Estado } from '../../models/estado';
import { EstadoService } from '../../services/estado.service';
import { respuesta } from '../../models/respuesta';
import { MunicipioService } from '../../services/municipio.service';
import { Municipio } from '../../models/municipio';
import { LocalidadService } from '../../services/localidad.service';
import { Localidad } from '../../models/localidad';


@Component({
  selector: 'app-get-localidades',
  templateUrl: './get-localidades.component.html'
})
export class GetLocalidadesComponent implements OnInit, OnChanges {

   @Input() idEstado: string;
   @Input() idMunicipio: string;
   @Input() idLocalidad: string;


   indexEstado: string;
   indexMunicipio: string;
   indexLocalidad: string;

   idestado: string;

   @Output() getLocalidadSelecionada = new EventEmitter();

  respuesta: respuesta;

  estado: Estado;
  estados: Estado[] = [];
  estadosFiltro: Estado[] = [];

  municipio: Municipio;
  municipios: Municipio[] = [];

  localidad: Localidad;
  localidades: Localidad[] = [];

  banRe: boolean = false;

  constructor(
    private _estadoService: EstadoService,
    private _municipioService: MunicipioService,
    private _localidadService: LocalidadService) {
    this.respuesta = new respuesta();
    this.estado = new Estado();
    this.municipio = new Municipio();
    this.localidad = new Localidad();

    //console.log('Valores por Input: ' + this.idEstado + ' - ' + this.idMunicipio + ' - ' + this.idMunicipio);
  }

  ngOnInit() {

    this.getEstados();
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log('Cambio Detectado: ' + changes);

    this.getEstados();

  }



  getEstados() {
    //alert('Dentro de Estados');
    this.municipios = [];

    this._estadoService.getEstados().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.estados = this.respuesta.data;
          if (this.idEstado !== '') {
            this.getMunicipios();
            this.lanzaLocalidadSeleccionada();
          }
        }
      }
    );
  }

  getMunicipios() {
    //alert('Dentro de Municipios');
    this.localidades = [];
    //this.idMunicipio = "";
    //this.idLocalidad = "";
    this._municipioService.municipio.idestado = this.idEstado;
    this._municipioService.getMunicipios().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.municipios = this.respuesta.data;
          if (this.idMunicipio !== '') {
            this.getLocalidades();
            //this.idMunicipio = "";
            //this.idLocalidad = "";
            this.lanzaLocalidadSeleccionada();
          }
        }
      }
    );
  }

  getMunicipios2() {
    //alert('Dentro de Municipios');
    this.localidades = [];
    this.idMunicipio = "0";
    this.idLocalidad = "0";
    this._municipioService.municipio.idestado = this.idEstado;
    this._municipioService.getMunicipios().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.municipios = this.respuesta.data;
          if (this.idMunicipio !== '') {
            this.getLocalidades2();

            this.lanzaLocalidadSeleccionada();
          }

        }
      }
    );
  }

  getLocalidades() {
    //alert('Dentro de Localidades');
    //this.idLocalidad='0';
    this._localidadService.localidad.idestado = this.idEstado;
    this._localidadService.localidad.idmunicipio = this.idMunicipio;
    this._localidadService.getLocalidades().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.localidades = this.respuesta.data;
          //this.idLocalidad='';
          this.lanzaLocalidadSeleccionada();
        }
      }
    );
  }

  getLocalidades2() {
    //alert('Dentro de Localidades');
    //this.idLocalidad='0';
    this._localidadService.localidad.idestado = this.idEstado;
    this._localidadService.localidad.idmunicipio = this.idMunicipio;
    this._localidadService.getLocalidades().subscribe(
      data => {
        this.respuesta = data;
        if ( this.respuesta.error ) {
          alert(this.respuesta.mensaje);
        } else {
          this.localidades = this.respuesta.data;
          //this.idLocalidad='';
          this.lanzaLocalidadSeleccionada();
        }
      }
    );
  }

  lanzaLocalidadSeleccionada() {

    this.getLocalidadSelecionada.emit({
      idestado: this.idEstado,
      estado: $('#idEstado option:selected').text(),
      idmunicipio: this.idMunicipio,
      municipio: $('#idMunicipio option:selected').text(),
      idlocalidad: this.idLocalidad,
      localidad: $('#idLocalidad option:selected').text()
    });
    //this.getLocalidadSelecionada.emit(this.localidad);
  }


  filtrarEstados(event) {
    this.estadosFiltro = [];
    for (let i = 0; i < this.estados.length; i++) {
      let est = new Estado();
      est = this.estados[i];
      if (est.estado.toLowerCase().indexOf(event.query.toLowerCase()) !== -1) {
          this.estadosFiltro.push(est);
      }
    }
    //console.log(this.estadosFiltro);
  }

  muestraEstado() {
    alert('Estado Seleccionado: ' + this.estado.estado);
  }



}
