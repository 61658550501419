
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { oficioModel } from '../models/oficio';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';

@Injectable()
export class OficiosService {
  modificacion: boolean = false;

  url: string = "";
  objOficio: oficioModel;
  arrOficios: oficioModel[] = [];
  resp: respuesta;
  headers: Headers = new Headers;

  buscar:string;

  //login
  email: string;
  psw: string;

  turno: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.objOficio = new oficioModel();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }


  consultaOficios(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objOficio.fecha;
      params += "&fechaF=" + this.objOficio.fechaF;
      params += "&buscar=" + this.buscar;
      params += "&dirigido=" + this.objOficio.dirigido;
      params += "&turno=" + this.objOficio.turno;
      console.log(params);
      return this.http.post(this.url + "Oficios/getOficios", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrOficios = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  getTurnos(): Observable<respuesta> {
    try {
      let params = "idoficio=" + this.objOficio.idoficio;

      //console.log(params);
      return this.http.post(this.url + "Oficios/getTurnos", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  consultaOficiosSinAgendar(): Observable<respuesta> {
    try {
      let params = "fecha=" + this.objOficio.fecha;
      params += "&buscar=" + this.buscar;
      //console.log(params);
      return this.http.post(this.url + "Oficios/getOficiosSinAgendar", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          //this.cct = res.json();
          this.resp = res.json();
          this.arrOficios = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {

      console.log("Error: " + err);
    }
  }

  consultaOficiosTodos(): Observable<respuesta> {
    try {
      const params = "turno=" + this.objOficio.turno;


      console.log(params);
      return this.http.post(this.url + "Oficios/getOficiosTodos", params, { headers: this.headers }).pipe(map(
        res => {

          //console.log(res);
          this.resp = res.json();
          console.log(res);
          this.arrOficios = this.resp.data;
          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  agregarOficio(): Observable<respuesta> {
    try {
      let params = "enviado=" + this.objOficio.enviado;
      params += "&dirigido=" + this.objOficio.dirigido;
      params += "&asunto=" + this.objOficio.asunto;
      params += "&telefono=" + this.objOficio.telefono;
      params += "&oficio=" + this.objOficio.oficio;
      params += "&fecha=" + this.objOficio.fecha;
      params += "&idestado=" + this.objOficio.idestado;
      params += "&idmunicipio=" + this.objOficio.idmunicipio;
      params += "&idlocalidad=" + this.objOficio.idlocalidad;
      params += "&posible=" + this.objOficio.posible;
      params += "&categoria=" + this.objOficio.categoria;
      params += "&turno=" + this.objOficio.turno;
      params += "&lugar=" + this.objOficio.lugar;
      params += "&fechaevento=" + this.objOficio.fechaevento;
      params += "&idusuario=" + this.objOficio.idusuario;
      console.log(params);

      return this.http.post(this.url + "Oficios/guardaOficio", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        this.arrOficios = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificarOficio(): Observable<respuesta> {
    try {
      let params = "enviado=" + this.objOficio.enviado;
      params += "&dirigido=" + this.objOficio.dirigido;
      params += "&asunto=" + this.objOficio.asunto;
      params += "&telefono=" + this.objOficio.telefono;
      params += "&oficio=" + this.objOficio.oficio;
      params += "&fecha=" + this.objOficio.fecha;
      params += "&idoficio=" + this.objOficio.idoficio;
      params += "&idestado=" + this.objOficio.idestado;
      params += "&idmunicipio=" + this.objOficio.idmunicipio;
      params += "&idlocalidad=" + this.objOficio.idlocalidad;
      params += "&idoficio=" + this.objOficio.idoficio;
      params += "&lugar=" + this.objOficio.lugar;
      params += "&fechaevento=" + this.objOficio.fechaevento;


      params += "&posible=" + this.objOficio.posible;
      params += "&categoria=" + this.objOficio.categoria;
      params += "&turno=" + this.objOficio.turno;
      params += "&turnoAnte=" + this.objOficio.turnoAnte;
      console.log(params);
      return this.http.post(this.url + "Oficios/modificaOficio", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        this.arrOficios = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaOficio(): Observable<respuesta>{

    try {
      let params = "idoficio=" + this.objOficio.idoficio;
      return this.http.post(this.url + "Oficios/eliminaOficio", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  descartaOficio(): Observable<respuesta>{

    try {
      let params = "idoficio=" + this.objOficio.idoficio;
      return this.http.post(this.url + "Oficios/descartaOficio", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  subirArchivo(params: Array<string>, file: Array<File>, name: string, idarchivo: string) {
    //alert(idarchivo);
    let dire = this.url + "Oficios/guardaArchivoOficio";
    return new Promise(function(resolve, reject) {
      let formData: any = new FormData();
      let xhr = new XMLHttpRequest();
      for (let i = 0; i < file.length; i++) {
        formData.append('archivo', file[i], file[i].name);
      }

      formData.append('idoficio', localStorage.getItem('idoficio'));
      formData.append('idtipoarchivo', '8');
      formData.append('idarchivo', idarchivo);
      formData.append('modificacion', idarchivo);

      xhr.onreadystatechange = function() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            console.log(xhr.response);
            resolve(JSON.parse(xhr.response));

          } else {
            reject(xhr.response);
          }
        }
      };

      xhr.open('POST', dire, true);
      //xhr.setRequestHeader('Authorization', token);
      xhr.send(formData);
    });
  }



  /*
    guardarCentroEscolar(): Observable<respuesta>{
      try {
        let params = "nombre=" + this.centroEscolar.nombre;
        return this.http.post(this.url + "Centros/insertaCentro", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }

    modificaCentroEscolar(): Observable<respuesta>{
      try {
        let params = "nombre=" + this.centroEscolar.nombre;
        params = params  + "&idcentro=" + this.centroEscolar.idcentro;
        return this.http.post(this.url + "Centros/modificaCentro", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }

    eliminaCentroEscolar(): Observable<respuesta>{

      try {
        let params = "idcentro=" + this.centroEscolar.idcentro;
        return this.http.post(this.url + "Centros/cancelarCentro", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }

    login(): Observable<respuesta>{
      try {
        //alert("Email: " +this.email + " PSW: " + this.psw);
        let params = "email=" + this.email;
        params += "&psw=" + this.psw;
        params += "&tipolog=WEB";
        params += "&tipo=ADMINISTRADOR";
        params += "&token=token";
        params += "&sistema=WEB";

        return this.http.post(this.url + "Usuarios/login", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          console.log(res);
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }
  */

}
