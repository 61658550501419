
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { tipoarchivoModel } from '../models/tipoarchivo';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';

@Injectable()
export class TipoarchivoService {
  url: string = "";
  objTipo:tipoarchivoModel;
  arrTipos: tipoarchivoModel[] = [];
  resp: respuesta;
  headers:Headers = new Headers;

  //login
  email:string;
  psw:string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.objTipo = new  tipoarchivoModel();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }


  getTiposDeArchivo(): Observable<respuesta> {
    try {
      let params = "idtipoarchivo=" + this.objTipo.idtipoarchivo;
      return this.http.post(this.url + "Tiposdearchivo/getTiposDeArchivo", params, {headers: this.headers}).pipe(map(res => {

        //console.log(res.json());
        //this.cct = res.json();
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        //console.log(this.centros);
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }


  agregarTipoDeArchivo(): Observable<respuesta> {
    try {
      let params = "tipoarchivo=" + this.objTipo.tipoarchivo;

      return this.http.post(this.url + "Tiposdearchivo/insertaTipoDeArchivo", params, {headers: this.headers}).pipe(map(res => {

        console.log(res);

        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;

        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  modificarTipoDeArchivo(): Observable<respuesta> {
    try {
      let params = "tipoarchivo=" + this.objTipo.tipoarchivo;
      params += "&idtipoarchivo=" + this.objTipo.idtipoarchivo;

      return this.http.post(this.url + "Tiposdearchivo/modificaTipoDeArchivo", params, { headers: this.headers }).pipe(map(res => {

        console.log(res);
        this.resp = res.json();
        //this.arrSecretarias = this.resp.data;
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaTipoarchivo(): Observable<respuesta>{
    try {
      let params = "idtipoarchivo=" + this.objTipo.idtipoarchivo;
      return this.http.post(this.url + "Tiposdearchivo/borrarTipoArchivo", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  activaTipoarchivo(): Observable<respuesta>{
    try {
      let params = "idtipoarchivo=" + this.objTipo.idtipoarchivo;
      return this.http.post(this.url + "iposdearchivo/activarTipoArchivo", params, {headers: this.headers}).pipe(map(res => {
        this.resp = res.json();
        return res.json();
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

}
