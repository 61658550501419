import { Component, OnInit, OnChanges } from '@angular/core';
import { Usuario } from '../../../models/Usuario';

@Component({
  selector: 'app-prensa-tecnica-page',
  templateUrl: './oficios-prensa-page.component.html'
})
export class OficiosPrensaPageComponent implements OnInit, OnChanges {

  usuario: Usuario;

  constructor() {
    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit() {
    console.log(this.usuario.idsecretaria);
  }

  ngOnChanges(){
  
  }

}
