import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SecretariaService } from '../../services/secretaria.service';
import { respuesta } from '../../models/respuesta';
import { secretarioModel } from '../../models/secretario';
import { Secretaria } from '../../models/secretaria';
import { agendaModel } from '../../models/agenda';
import { AgendaService } from '../../services/agenda.service';
import { app_routing } from '../../app.routes';
import { Dependencia } from '../../models/dependencia';
import { DependenciaService } from '../../services/dependencia.service';

@Component({
  selector: 'app-acompanantes',
  templateUrl: './acompanantes.component.html'
})
export class AcompanantesComponent implements OnInit, OnChanges {

  @Input() agenda: agendaModel;

  resp: respuesta;
  dependencia: Dependencia;
  dependencias: Dependencia[] = [];
  acompanantes: Dependencia[] = [];
  tipoPersona = 'FUNCIONARIO';



  constructor(private _dependenciaService: DependenciaService, private _agendaService: AgendaService) { }

  ngOnInit() {
    this.resp = new respuesta();
    this.dependencia = new Dependencia();
    this.getDependencias();
  }

  ngOnChanges(){
    this.getAcompanantes();
  }



  getDependencias() {
    //this._secretariaService.secretaria.iddependencia='9';
    this._dependenciaService.getDependencias().subscribe(
      data => {
        this.resp = data;

        this.dependencias = this.resp.data;
        console.log(data);
      }
    );
  }

  getAcompanantes() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.getAcompanantes().subscribe(
      data => {
        this.resp = data;

        this.acompanantes = this.resp.data;
        console.log(data);
      }
    );
  }

  guardaAcompanante() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.iddependencia = this.dependencia.iddependencia;
    this._agendaService.guardaAcompanantes().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          swal("Guardada", this.resp.mensaje, "info");
        }else{
          swal("Error", this.resp.mensaje, "error");
        }
        this.getAcompanantes();
        
      }
    );
  }

  eliminaAcompanante(id){
    if (confirm("Estas seguro de eliminar este Acompañante de Evento?")) {
      this._agendaService.iddependencia = id;
      this._agendaService.objAgenda = this.agenda;
      this._agendaService.eliminaAcompanante().subscribe(
        data => {
          this.resp = data;
          if (this.resp.error) {
            alert(this.resp.mensaje);
          }
          this.getAcompanantes();
          console.log(data);
        }
      );
    }
  }

}
