import { OnChanges } from '@angular/core';
import { Component,
  OnInit,
  Input,
  Output,
  EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-tipo-invitacion',
  templateUrl: './select-tipo-invitacion.component.html'
})
export class SelectTipoInvitacionComponent implements OnInit {

  @Input() tipoInvitacion: string;
  @Input() escogeTipo: boolean = true;

  @Output() tipoInvitacionSeleccionado: EventEmitter<string> = new EventEmitter();

  tipos = [
    'BLOQUEADO',
    'REUNION DE GABINETE',
    'REUNION ORDINARIA',
    'INVITACION PERSONAL',
    'AUDIENCIA',
    'VISITA',
    'SUPERVISION',
    'INAUGURACION',
    'ARRANQUE',
    'CLAUSURA',
    'EVENTO',
    'ANUNCIO',
    'CONFERENCIA',
    'GIRA DE TRABAJO',
    'ENTREGA DE OBRA',
    'AJUSTE DE TIEMPO',
    'AJUSTE DE TIEMPO ADMINISTRATIVO',
    'CUMPLEAÑOS',
    'OTRO'];

  constructor() { }

  ngOnInit() {
  }



  seleccionaTipo(){
    console.log(this.tipoInvitacion);
    this.tipoInvitacionSeleccionado.emit(this.tipoInvitacion);
  }

}
