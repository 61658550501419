export class eventoModel{
  id: number;
  title: string;
  description: string;
  start: string;
  end: string;
  url:string;
  color:string;
  borderColor:string;


  constructor(){
    this.title="";
    this.description="";
    this.start="";
    this.end="";
    this.url="";
    this.color="";
    this.borderColor="";

  }
}
