import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { AgendaService } from '../../services/agenda.service';
import { agendaModel } from '../../models/agenda';
import { respuesta } from '../../models/respuesta';
import { notificacion } from '../../models/notificacion';
import { Router } from '@angular/router';
import { Usuario } from '../../models/Usuario';
import { GLOBAL } from '../../services/global';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-agenda-dia',
  templateUrl: './agenda-dia.component.html'
})
export class AgendaDiaComponent implements OnInit, OnChanges {

  titulo: string = 'Agenda del Día';
  @Input() fecha: string;
  @Input() control: string;
  @Input() subcontrol: string = 'todos';

  leyenda = '';

  descargar: string;
  descargarC: string;

  filtro = "";

  titleCaption: String;

  activa: boolean = false;
  tabla: any;

  agenda: agendaModel;
  agendas: agendaModel[] = [];
  agendasSeleccionadas: string[] = [];
  resp: respuesta;

  fechaMod: string;

  usuario: Usuario;

  tipoAcompa: string;

  impresion: boolean = false;

  tituloFormulario = 'Alta Agenda';
  duplicarEvento = '';


  constructor(private _agendaService: AgendaService, private router: Router) {
    this.agenda = new agendaModel();
    console.log(this.agenda);
    this.resp = new respuesta();

    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));

  }

  ngOnInit() {
    //alert(this.control);
    //this.consultaAgendas();
    //this.fechaEspanol();
  }

  ngOnChanges() {
    this.titleCaption = "C.P. Antonio Echevarría García <br> Agenda de Gobieno (" + this.fecha + ")";
    //this.descargar = GLOBAL.urlPDF + "?descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString();
    this.consultaAgendas();
    this.fechaEspanol();
    this.fechaMod = new Date(this.fecha).toJSON().split('T')[0];
  }

  consultaAgendas() {
    //alert("Control:" + this.control);
    switch (this.control) {
      case 'privada':
      case 'tecnica':
      case '':

        if (this.usuario.app === '4') {
          this.getAgendasConAcompanante();
        } else {
          this.getAgendas();
        }
        break;
      case 'cumpleaños':
        //this.fecha = new Date().toString().replace(/\s/g, "T");
        this.fecha = new Date().toJSON().split('T')[0];
        this.getAgendasEventosTodos();
        break;
      case 'apoyo':
        //this.fecha = new Date().toString().replace(/\s/g, "T");
        //this.fecha = new Date().toJSON().split('T')[0];
        this.getAgendasSecretario();
        break;

    }

  }

  getAgendas() {
    console.log(this.fecha);
    this.agenda.fecha = this.fecha;
    this._agendaService.objAgenda = this.agenda;

    this.agendas = [];
    this.activa = false;
    if (this.tabla) {
      this.tabla.destroy();
    }

    this._agendaService.filtro = this.filtro;
    this._agendaService.consultaAgendas().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/

        this.cargarArregloAgendas();
        setTimeout(() => {
          this.inicializaTabla();
        }, 2000);

      }
    );
  }

  getAgendasConAcompanante() {
    console.log(this.fecha);
    this.agenda.fecha = this.fecha;
    this._agendaService.objAgenda = this.agenda;

    this.agendas = [];
    this.activa = false;
    if (this.tabla) {
      this.tabla.destroy();
    }

    this._agendaService.filtro = this.filtro;
    this._agendaService.consultaAgendasConAcompanante().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/

        this.cargarArregloAgendas();
        setTimeout(() => {
          this.inicializaTabla();
        }, 2000);

      }
    );
  }

  getAgendasSecretario() {
    console.log(this.fecha);
    this.agenda.fecha = this.fecha;
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.idfuncionario = this.usuario.idfuncionario;

    this.agendas = [];
    this.activa = false;
    if (this.tabla) {
      this.tabla.destroy();
    }

    this._agendaService.filtro = this.filtro;
    this._agendaService.consultaAgendasSecretario().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/

        this.cargarArregloAgendas();
        setTimeout(() => {
          this.inicializaTabla();
        }, 2000);

      }
    );
  }

  getAgendasEventos() {
    console.log(this.fecha);
    this.agenda.fecha = this.fecha;
    this._agendaService.objAgenda = this.agenda;

    this.agendas = [];
    this.activa = false;
    if (this.tabla) {
      this.tabla.destroy();
    }

    this._agendaService.filtro = this.filtro;
    this._agendaService.consultaAgendasEventos().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/

        this.cargarArregloAgendas();
        setTimeout(() => {
          this.inicializaTabla();
        }, 2000);

      }
    );
  }

  getAgendasEventosTodos() {
    console.log(this.fecha);
    this.agenda.fecha = this.fecha;
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.subcontrol = this.subcontrol;

    this.agendas = [];
    this.activa = false;
    if (this.tabla) {
      this.tabla.destroy();
    }

    this._agendaService.filtro = this.filtro;
    this._agendaService.consultaAgendasEventosTodos().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/
        setTimeout(() => {
          this.inicializaTabla();
        }, 2000);

      }
    );
  }

  getAgendasTecnica() {
    console.log(this.fecha);
    this.agenda.fecha = this.fecha;
    this._agendaService.objAgenda = this.agenda;

    this.activa = false;
    if (this.tabla) {
      this.tabla.destroy();
    }

    this._agendaService.consultaAgendasTecnica().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/
        setTimeout(() => {
          this.inicializaTabla();
        }, 200);

      }
    );
  }

  detalleAgenda(index: number) {
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    console.log('Agenda:' + this.agenda);

    $('#detalleAgendaModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }


  cancelarAgenda(id) {
    swal({
      title: "Quieres cancelar este Evento?",
      text: "Una vez cancelado no podras recuperarlo!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          this.agenda = this.agendas[id];
          $('#observacionCancelarModal').modal('show');
        }
      });

  }


  cancelaAgendaBD() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.cancelarAgenda().subscribe(
      data => {
        this.resp = data;
        //alert(this.resp.mensaje);
        //new notificacion().simpleInfo(this.resp.mensaje);
        swal("Cancelado", this.resp.mensaje, "warning");
        //$('#altaModal').modal('hide');
        $('#observacionCancelarModal').modal('hide');

        this.consultaAgendas();
        // console.log(data);
      });
  }


  eliminarAgenda(id) {
    swal({
      title: "Quieres eliminar este Evento?",
      text: "Una vez ELIMINADO no podras recuperarlo!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        if (willDelete) {

          this.agenda.idagenda = id;
          this._agendaService.objAgenda.idagenda = id;
          this._agendaService.eliminarAgenda().subscribe(
            data => {
              this.resp = data;
              //alert(this.resp.mensaje);
              //new notificacion().simpleInfo(this.resp.mensaje);
              swal("Eliminado", this.resp.mensaje, "warning");
              //$('#altaModal').modal('hide');

              this.consultaAgendas();
              // console.log(data);
            });
        }
      });

  }





  inicializaTabla() {

    if (!this.activa) {

      //const now = new Date(this.fecha);
      //let fecha = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      const formatted = $.datepicker.formatDate("DD d/M/yy", new Date(this.fecha.replace(/-/g, '\/')));
      let fecha = this.fecha;

      this.tabla = $('#data-table-1').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        "order": [],
        'buttons': [
          {
            extend: 'excelHtml5',
            text: '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [0, 1],
              //stripHtml: false
            },
            title: "AGENDA DE GOBIERNO (" + formatted + ")<br>C.P. Antonio Echevarría García",
            //title: "",

          },
          {
            extend: 'print',
            text: '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function (win) {
              $(win.document.body).css('font-size', '8pt').prepend('<img src="https://agenda.nayarit.gob.mx//ssai_v2_5/logoGobierno.png" width="60" style="position:absolute; top:0; left:0;" />');
              $(win.document.body).find('table').addClass('compact').css('font-size', 'inherit');
            },
            title: "<h3>AGENDA DE GOBIERNO (" + formatted + ")<br>C.P. Antonio Echevarría García</h3>",
            //title: "",
            exportOptions: {
              columns: [0, 1],
              stripHtml: false,
              body: function (data) {
                //Antes de mandarse al PDF cada valor pasa por aqui y es evaluado
                let valor = data.toString(); //El campo debe ser STRING para que funcione
                valor = valor.replace("<br/>", "\n");  //Aqui es donde le digo al JavaScript que reemplace <br/> el salto de linea HTML por el salto de linea \n
                console.log(valor);
                return valor;

              }
            }
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
          }
        }
      });
      this.activa = true;
    }
  }



  getAgendaGuardada(event: agendaModel) {
    //alert('Agenda Modificada');
    //this.agendas = [];

    this.fecha = this.agenda.fecha;
    this.consultaAgendas();
  }

  editarAgenda(index: number) {
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    this.tituloFormulario == 'Editar Evento';

    $('#altaInvitacionModal').modal('show');
    //$('body').removeClass('modal-open');
    //$('.modal-backdrop').remove();
  }

  duplicaAgenda(index: number) {
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    this.agenda.idagendaOld = this.agenda.idagenda;
    this.agenda.idagenda = '';
    this.agenda.evento += ' (COPIA)';
    this.duplicarEvento = '1';
    this.tituloFormulario == 'Duplicar Evento';
    this.agenda.duplicado = '1';
    this.agenda.idoficio = '';
    this.agenda.representacion = '';
    this.agenda.asiste = '';

    $('#altaInvitacionModal').modal('show');
    //$('body').removeClass('modal-open');
    //$('.modal-backdrop').remove();
  }

  modificaFecha() {

    $('#modificaFecha').modal('show');
    //$('body').removeClass('modal-open');
    //$('.modal-backdrop').remove();
  }

  cerrarModificaFecha() {

    $('#modificaFecha').modal('hide');
    //$('body').removeClass('modal-open');
    //$('.modal-backdrop').remove();
  }

  guardaNuevaFecha() {
    console.log(this.fechaMod);

    this._agendaService.modificaFechaEventos(this.fechaMod, this.agendasSeleccionadas.toString()).subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        swal("Modificar Fecha!", this.resp.mensaje, "info");
        $('#modificaFecha').modal('hide');
        this.consultaAgendas();


      }
    );
  }

  nuevaAgenda() {
    this.agenda = new agendaModel();
    this.agenda.fecha = this.fecha;
    $('#altaInvitacionModal').modal('show');
  }

  nuevoCumpleanos() {
    this.agenda = new agendaModel();
    this.agenda.fecha = this.fecha;
    $('#altaInvitacionModal').modal('show');
  }

  altaAcompanantes(index: number) {
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    this.tipoAcompa = "Acompañante";
    $('#acompanantesModal').modal('show');
  }

  altaRepresentacion(index: number) {
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    this.tipoAcompa = "Representante";
    $('#acompanantesModal').modal('show');
  }


  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }


  fechaEspanol() {
    $.datepicker.regional['es'] = {
      closeText: 'Cerrar',
      prevText: '< Ant',
      nextText: 'Sig >',
      currentText: 'Hoy',
      monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
      dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
      dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Juv', 'Vie', 'Sáb'],
      dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sá'],
      weekHeader: 'Sm',
      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      isRTL: false,
      showMonthAfterYear: false,
      yearSuffix: ''
    };
    $.datepicker.setDefaults($.datepicker.regional['es']);
  }


  cerrarAcompanantes() {
    $('#acompanantesModal').modal('hide');
    this.getAgendas();
  }

  notificarCumpleanos(id) {
    this._agendaService.subcontrol = this.subcontrol;
    this._agendaService.nofiticaCumplenos().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          swal("Error", this.resp.mensaje, "error");
        } else {
          swal("Notificación", this.resp.mensaje, "info");
        }
      }
    );

  }

  imprimirReporte() {
    this.impresion = !this.impresion;
  }

  cargarArregloAgendas() {
    this.agendas.forEach(agenda => {
      this.agendasSeleccionadas.push(agenda.idagenda);
    });
    this.descargar = GLOBAL.urlPDF + "?conocimiento=0&descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString() + "&leyenda=" + this.leyenda;
    this.descargarC = GLOBAL.urlPDF + "?conocimiento=1&descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString() + "&leyenda=" + this.leyenda;
    //console.error("Agendas Seleccionadas: " + this.agendasSeleccionadas);
  }

  checkValue(event: any) {
    console.log(event);
  }

  changed = (evt) => {
    //this.isChecked = evt.target.checked;
    let index = -1;
    if (!evt.target.checked) {
      index = this.agendasSeleccionadas.indexOf(evt.target.defaultValue);
      if (index > -1) {
        this.agendasSeleccionadas.splice(index, 1);
      }
    } else {
      this.agendasSeleccionadas.push(evt.target.defaultValue);
    }
    //console.log(evt.target.checked);
    //console.log(evt.target.defaultValue);
    //console.error("Agendas Seleccionadas: " + this.agendasSeleccionadas);
    this.descargar = GLOBAL.urlPDF + "?descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString() + "&leyenda=" + this.leyenda;
    this.descargarC = GLOBAL.urlPDF + "?conocimiento=1&descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString() + "&leyenda=" + this.leyenda;
  }

  autorizarEvento = (evt) => {
    //alert('Indice: ' + evt.target.defaultValue);
    this.agenda.idagenda = this.agendas[evt.target.defaultValue].idagenda;
    if (evt.target.checked) {
      //alert('confirmar ' + this.agendas[evt.target.defaultValue].idagenda);
      this.agendas[evt.target.defaultValue].estatus_evento = '1';
      this.agenda.estatus_evento = '1';
      this.autorizaEvento();
    } else {
      //alert('no confirmar' + this.agendas[evt.target.defaultValue].idagenda);
      this.agendas[evt.target.defaultValue].estatus_evento = '0';
      this.agenda.estatus_evento = '0';
      this.autorizaEvento();
    }

  }

  autorizaEvento() {

    this._agendaService.objAgenda = this.agenda;
    this._agendaService.autorizaEvento().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          swal("Error", this.resp.mensaje, "error");
        } else {
          swal("Notificación", this.resp.mensaje, "info");
        }
      }
    );

  }

  paraConocimientoEvento = (evt) => {
    //alert('Indice: ' + evt.target.defaultValue);
    this.agenda.idagenda = this.agendas[evt.target.defaultValue].idagenda;
    if (evt.target.checked) {
      //alert('confirmar ' + this.agendas[evt.target.defaultValue].idagenda);
      this.agendas[evt.target.defaultValue].para_conocimiento = '1';
      this.agenda.para_conocimiento = '1';
      this.conocimientoEvento();
    } else {
      //alert('no confirmar' + this.agendas[evt.target.defaultValue].idagenda);
      this.agendas[evt.target.defaultValue].para_conocimiento = '0';
      this.agenda.para_conocimiento = '0';
      this.conocimientoEvento();
    }

  }

  ajustarTiempoEvento = (evt) => {
    //alert('Indice: ' + evt.target.defaultValue);
    this.agenda.idagenda = this.agendas[evt.target.defaultValue].idagenda;
    if (evt.target.checked) {
      //alert('confirmar ' + this.agendas[evt.target.defaultValue].idagenda);
      this.agendas[evt.target.defaultValue].ajustar_tiempo = '1';
      this.agenda.ajustar_tiempo = '1';
      this.ajustarEvento();
    } else {
      //alert('no confirmar' + this.agendas[evt.target.defaultValue].idagenda);
      this.agendas[evt.target.defaultValue].ajustar_tiempo = '0';
      this.agenda.ajustar_tiempo = '0';
      this.ajustarEvento();
    }

  }

  conocimientoEvento() {

    this._agendaService.objAgenda = this.agenda;
    this._agendaService.conocimientoEvento().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          swal("Error", this.resp.mensaje, "error");
        } else {
          swal("Notificación", this.resp.mensaje, "info");
        }
      }
    );

  }

  ajustarEvento() {

    this._agendaService.objAgenda = this.agenda;
    this._agendaService.ajustarTiempoEvento().subscribe(
      data => {
        this.resp = data;

        if (this.resp.error) {
          swal("Error", this.resp.mensaje, "error");
        } else {
          swal("Notificación", this.resp.mensaje, "info");
        }
      }
    );

  }


  cambiarLeyenda() {
    $('#modificaLeyenda').modal('show');

  }

  actualizaLeyenda(event) {
    console.log(event);
    this.descargar = GLOBAL.urlPDF + "?descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString() + "&leyenda=" + this.leyenda;
    this.descargarC = GLOBAL.urlPDF + "?conocimiento=1&descarga=1&fecha=" + this.fecha + "&agendas=" + this.agendasSeleccionadas.toString() + "&leyenda=" + this.leyenda;
  }




}
