import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';
import { respuesta } from '../../models/respuesta';
import { UsuarioService } from '../../services/usuario.service';

@Component({
  selector: 'app-seguimiento-oficios',
  templateUrl: './seguimiento-oficios.component.html'
})
export class SeguimientoOficiosComponent implements OnInit {

  usuario: Usuario;
  url: string;
  url2: string;
  titulo = "Atención Ciudadana";
  resp: respuesta;

  constructor(private _usuarioService: UsuarioService) {
    this.usuario = new Usuario();
    this.resp = new respuesta();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    this.url = "https://seguimiento.nayarit.gob.mx/oficios?t=" + this.usuario.AccessToken;
    //this.url = "http://10.20.5.57:8000/oficios/?_token=" + this.usuario.AccessToken;
    //this.url = "http://acconsulta.nayarit.gob.mx/login.aspx?idd=22&tk=" + this.usuario.tk;

    this._usuarioService.getTokenSeguimiento(this.usuario.AccessToken).subscribe(
      data => {
        //this.resp = data;
        //console.log(this.resp);
        console.log(data);
        this.url2 = "data:text/html;charset=utf-8," + data._body;
        //this.url = "http://acconsulta.nayarit.gob.mx/login.aspx?idd=" + this.usuario.idatencion + "&tk=" + this.usuario.tk;
      }
    );

  }

  ngOnInit() {
  }


}
