
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { contactoModel } from '../models/contacto';
import { respuesta } from '../models/respuesta';

import { GLOBAL } from './global';

@Injectable()
export class ContactoService {
  url: string = "";
  objContacto: contactoModel;

  resp: respuesta;
  headers: Headers = new Headers;

  //login
  email: string;
  psw: string;

  constructor(private http: Http) {
    this.url = GLOBAL.url;
    this.objContacto = new contactoModel();
    this.resp = new respuesta();

    this.headers.append('Content-Type', 'application/x-www-form-urlencoded');
  }

  //Entradas en Agenda
  getContactos(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objContacto.idagenda;
      console.log(params);
      return this.http.post(this.url + "Contactos/getContactos", params, { headers: this.headers }).pipe(map(
        res => {

          console.log(res);
          //this.cct = res.json();
          this.resp = res.json();

          //console.log(this.centros);
          return this.resp;
        }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  agregarContacto(): Observable<respuesta> {
    try {
      let params = "idagenda=" + this.objContacto.idagenda;
      params += "&idciudadano=" + this.objContacto.idcontacto;

      return this.http.post(this.url + "Contactos/guardaContacto", params, { headers: this.headers }).pipe(map(res => {

        //console.log(res);
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }

  eliminaContacto(): Observable<respuesta> {
    try {
      let params = "idcontacto=" + this.objContacto.idcontacto;

      return this.http.post(this.url + "Contactos/eliminaContacto", params, { headers: this.headers }).pipe(map(res => {

        //console.log(res);
        this.resp = res.json();
        return this.resp;
      }));
    } catch (err) {
      console.log("Error: " + err);
    }
  }




  /*
    guardarCentroEscolar(): Observable<respuesta>{
      try {
        let params = "nombre=" + this.centroEscolar.nombre;
        return this.http.post(this.url + "Centros/insertaCentro", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }

    modificaCentroEscolar(): Observable<respuesta>{
      try {
        let params = "nombre=" + this.centroEscolar.nombre;
        params = params  + "&idcentro=" + this.centroEscolar.idcentro;
        return this.http.post(this.url + "Centros/modificaCentro", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }

    eliminaCentroEscolar(): Observable<respuesta>{

      try {
        let params = "idcentro=" + this.centroEscolar.idcentro;
        return this.http.post(this.url + "Centros/cancelarCentro", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }

    login(): Observable<respuesta>{
      try {
        //alert("Email: " +this.email + " PSW: " + this.psw);
        let params = "email=" + this.email;
        params += "&psw=" + this.psw;
        params += "&tipolog=WEB";
        params += "&tipo=ADMINISTRADOR";
        params += "&token=token";
        params += "&sistema=WEB";

        return this.http.post(this.url + "Usuarios/login", params, {headers: this.headers}).map(res => {
          this.resp = res.json();
          console.log(res);
          return res.json();
        });
      } catch (err) {
        console.log("Error: " + err);
      }
    }
  */

}
