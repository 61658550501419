import { Component, OnInit } from '@angular/core';
import { Gira } from '../../models/Gira';
import { respuesta } from '../../models/respuesta';
import { GiraService } from '../../services/gira.service';

@Component({
  selector: 'app-giras',
  templateUrl: './giras.component.html'
})
export class GirasComponent implements OnInit {

  titulo: string = 'Giras de Gobierno';
  gira: Gira;
  giras: Gira[] = [];
  resp: respuesta;
  
  activa: boolean = false;
  tabla: any = null;

  tituloModal: string;
  modificar: boolean;


  constructor(private _giraService: GiraService) {
    this.resp = new respuesta();
    this.gira = new Gira();
  }

  ngOnInit() {
    this.activa=false;
    if(this.inicializaTabla()){
      this.getGiras();
    }
  }

  getGiras() {
    this.activa = false;
    if(this.tabla){
      this.tabla.destroy();
    }
    this._giraService.getGiras().subscribe(
      data => {
        this.resp = data;
        this.giras = this.resp.data;
        console.log(this.giras);
        
        setTimeout(() => {
          this.inicializaTabla();
        }, 1000);
      }
    );
  }

  inicializaTabla() {
    //console.log('Activa al Inicializar:' + this.activa);
    if ( !this.activa ) {
      //alert('Inicializando Tabla');
      this.tabla = $('#data-table-1').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        "order": [],
        'buttons': [
          {
            extend:    'excelHtml5',
            text:      '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [ 0, 1, 2, 3 ]
            },
            title:  "Rutas de Gobierno"
          },
          {
            extend:    'print',
            text:      '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function ( win ) {
              //$(win.document.body).css( 'font-size', '10pt' );
              $(win.document.body).find( 'table' ).addClass( 'compact' ).css( 'font-size', 'inherit' );
            },
            exportOptions: {
              columns: [ 0, 1, 2, 3 ]
            },
            title:  "<i class='fas fa-route'></i> Rutas de Gobierno"
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
      return true;
    }
    
  }

  nuevaGira(){
    this.gira = new Gira();
    this.modificar=false;
    this.tituloModal="Nueva Gira de Gobierno";
    $('#altaModal').modal('show');
  }

  guardaGira() {
    this.gira.fechaInicio = new Date(this.gira.fechaInicio).toJSON().split('T')[0];
    this.gira.fechaFin = new Date(this.gira.fechaFin).toJSON().split('T')[0];
    this._giraService.gira = this.gira;
    this._giraService.guardarGira().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if(!this.resp.error){
          $('#altaModal').modal('hide');
        }

        this.getGiras();
        //console.log(data);
      }
    );
  }

  modificaGira(index) {
    this.modificar=true;
    this.gira = this.giras[index];
    this.tituloModal = "Modifica Gira de Gobierno";
    $('#altaModal').modal('show');

  }


  guardarModificacionGira() {
    this._giraService.gira = this.gira;
    this._giraService.modificarGira().subscribe(
      data => {
        this.resp = data;
        alert(this.resp.mensaje);
        if(!this.resp.error){
          $('#altaModal').modal('hide');
        }

        this.getGiras();
        //console.log(data);
      }
    );
  }

  cancelarGira(index) {
    this._giraService.gira = this.giras[index];
    if (confirm("¿Desea CANCELAR la Gira de Gobierno?")) {
      this._giraService.cancelarGira().subscribe(
        data => {
          this.resp = data;
          alert(this.resp.mensaje);
          //$('#altaModal').modal('hide');
          this.getGiras();
          //console.log(data);
        }
      );
    }
  }

  activarGira(index) {
    
    this._giraService.gira = this.giras[index];
    if (confirm("¿Desea ACTIVAR la Gira de Gobierno?")) {
      this._giraService.activarGira().subscribe(
        data => {
          this.resp = data;
          alert(this.resp.mensaje);
          //$('#altaModal').modal('hide');
          this.getGiras();
          //console.log(data);
        }
      );
    }
  }

}
