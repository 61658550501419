import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AmazingTimePickerModule } from 'amazing-time-picker'; // this line you need

import { LOCALE_ID } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs);
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { CookieService } from 'ngx-cookie-service';



import { AppComponent } from './app.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { MainbarComponent } from './shared/mainbar/mainbar.component';
import { CatalogoComponent } from './components/catalogo/catalogo.component';
import { LoginComponent } from './components/login/login.component';
import { Error404Component } from './components/error404/error404.component';
import { HomeComponent } from './components/home/home.component';


// Servicios
import { CctService } from './services/cct.service';
import { OficiosService } from './services/oficio.service';
import { AgendaService } from './services/agenda.service';
import { EstadoService } from './services/estado.service';
import { MunicipioService } from './services/municipio.service';
import { LocalidadService } from './services/localidad.service';
import { FuncionarioService } from './services/funcionario.service';
import { UsuarioService } from './services/usuario.service';
import { SecretariaService } from './services/secretaria.service';
import { GiraService } from './services/gira.service';

// import { PdfmakeService } from 'ng-pdf-make/pdfmake/pdfmake.service';

// Rutas
import { app_routing } from './app.routes';
import { OficialiaComponent } from './components/oficialia/oficialia.component';
import { SecretariosComponent } from './components/secretarios/secretarios.component';
import { SecretarioService } from './services/secretario.service';
import { PrivadaComponent } from './components/privada/privada.component';
import { OficialiaPageComponent } from './pages/oficialia-page/oficialia-page.component';
import { CalendarioComponent } from './components/calendario/calendario.component';
import { PrincipalPageComponent } from './pages/principal-page/principal-page.component';
import { ConfiguracionPageComponent } from './pages/configuracion-page/configuracion-page.component';
import { CatalogosPageComponent } from './pages/catalogos-page/catalogos-page.component';
import { EstadosComponent } from './components/estados/estados.component';
import { MunicipiosComponent } from './components/municipios/municipios.component';
import { LocalidadesComponent } from './components/localidades/localidades.component';
import { ConfiguracionComponent } from './components/configuracion/configuracion.component';
import { PerfilComponent } from './components/perfil/perfil.component';
import { PerfilPageComponent } from './pages/perfil-page/perfil-page.component';
import { PersonaComponent } from './components/persona/persona.component';
import { GetLocalidadesComponent } from './components/get-localidades/get-localidades.component';
import { EstadoCivilService } from './services/estadocivil.service';
import { AutocompletPersonaComponent } from './components/autocomplet-persona/autocomplet-persona.component';
import { CiudadanoService } from './services/ciudadano.service';
import { AltaPersonaComponent } from './components/forms/alta-persona/alta-persona.component';
import { SelectTipoInvitacionComponent } from './components/select-tipo-invitacion/select-tipo-invitacion.component';
import { AgendaPageComponent } from './pages/agenda-page/agenda-page.component';
import { DetallediaPageComponent } from './pages/detalledia-page/detalledia-page.component';
import { AgendaDiaComponent } from './components/agenda-dia/agenda-dia.component';
import { AgendaprivadaPageComponent } from './pages/privada/agendaprivada-page/agendaprivada-page.component';
import { AgendatecnicaPageComponent } from './pages/tecnica/agendatecnica-page/agendatecnica-page.component';
import { OficiosTecnicaPageComponent } from './pages/tecnica/oficios-tecnica-page/oficios-tecnica-page.component';
import { OficiosPrivadaPageComponent } from './pages/privada/oficios-privada-page/oficios-privada-page.component';
import { PrivadaPageComponent } from './pages/privada/privada-page/privada-page.component';
import { TecnicaPageComponent } from './pages/tecnica/tecnica-page/tecnica-page.component';
import { AgendaPrivadaContenedorComponent } from './pages/privada/agenda-privada-contenedor/agenda-privada-contenedor.component';
import { AgendaTecnicaContenedorComponent } from './pages/tecnica/agenda-tecnica-contenedor/agenda-tecnica-contenedor.component';
import { DetalleDiaTecnicaContenedorComponent } from './pages/tecnica/detalle-dia-tecnica-contenedor/detalle-dia-tecnica-contenedor.component';
import { DetalleDiaPrivadaContenedorComponent } from './pages/privada/detalle-dia-privada-contenedor/detalle-dia-privada-contenedor.component';
import { DependenciaService } from './services/dependencia.service';
import { DependenciaComponent } from './components/dependencias/dependencia.component';
import { DetalleAgendaComponent } from './components/detalle-agenda/detalle-agenda.component';
import { ContactosAgendaComponent } from './components/contactos-agenda/contactos-agenda.component';
import { ContactoService } from './services/contacto.service';
import { AdjuntosAgendaComponent } from './components/adjuntos-agenda/adjuntos-agenda.component';
import { AdjuntoService } from './services/adjunto.service';
import { TipoarchivoService } from './services/tipoarchivo.service';
import { OficioInvitacionComponent } from './components/oficio-invitacion/oficio-invitacion.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';


import { LoginGuard } from './services/guards/login.guard';
import { PrivadaGuard } from './services/guards/privada.guard';
import { TecnicaGuard } from './services/guards/tecnica.guard';
import { OficialiaGuard } from './services/guards/oficialia.guard';
import { AltaAgendaComponent } from './components/forms/alta-agenda/alta-agenda.component';
import { PrensaGuard } from './services/guards/prensa.guard';


import { PrensaPageComponent } from './pages/prensa/prensa-page/prensa-page.component';
import { AgendaPrensaContenedorComponent } from './pages/prensa/agenda-prensa-contenedor/agenda-prensa-contenedor.component';
import { DetalleDiaPrensaContenedorComponent } from './pages/prensa/detalle-dia-prensa-contenedor/detalle-dia-prensa-contenedor.component';
import { AgendaprensaPageComponent } from './pages/prensa/agendaprensa-page/agendaprensa-page.component';
import { OficiosPrensaPageComponent } from './pages/prensa/oficios-prensa-page/oficios-prensa-page.component';
import { ColoresAgendaComponent } from './components/colores-agenda/colores-agenda.component';
import { SelectRutaComponent } from './components/select-ruta/select-ruta.component';
import { GirasComponent } from './components/giras/giras.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { AcompanantesComponent } from './components/acompanantes/acompanantes.component';
import { ListaAcompanantesComponent } from './components/lista-acompanantes/lista-acompanantes.component';
import { Acompanantes2Component } from './components/acompanantes2/acompanantes2.component';
import { CapitalizarPipe } from './pipes/capitalizar.pipe';
import { CalendarioMovilComponent } from './components/calendario-movil/calendario-movil.component';
import { MovilComponent } from './pages/movil/movil.component';
import { Acompanantes3Component } from './components/acompanantes3/acompanantes3.component';

import { AgendaeventosPageComponent } from './pages/eventos/agendaeventos-page/agendaeventos-page.component';
import { DetalleDiaEventoContenedorComponent } from './pages/eventos/detalle-dia-eventos-contenedor/detalle-dia-eventos-contenedor.component';
import { EventosPageComponent } from './pages/eventos/eventos-page/eventos-page.component';
import { AgendaEventoContenedorComponent } from './pages/eventos/agenda-evento-contenedor/agenda-evento-contenedor.component';
import { AtencioncComponent } from './components/atencionc/atencionc.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { EventosContenedorComponent } from './pages/eventos/eventos-contenedor/eventos-contenedor.component';
import { AgendaeventosHoyPageComponent } from './pages/eventos/agendaeventoshoy-page/agendaeventoshoy-page.component';
import { AgendaeventosMananaPageComponent } from './pages/eventos/agendaeventosmanana-page/agendaeventosmanana-page.component';
import { SeguimientoOficiosComponent } from './components/seguimiento-oficios/seguimiento-oficios.component';
import { SeguimientoGuard } from './services/guards/seguimiento.guard';
import { ListaAcompanantesExternosComponent } from './components/lista-acompanantes-externos/lista-acompanantes-externos.component';
import { AltaOficioComponent } from './components/forms/alta-oficio/alta-oficio.component';
import { LoaderComponent } from './components/loader/loader.component';






@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MainbarComponent,
    CatalogoComponent,
    LoginComponent,
    Error404Component,
    HomeComponent,
    OficialiaComponent,
    DependenciaComponent,
    SecretariosComponent,
    PrivadaComponent,
    OficialiaPageComponent,
    PrivadaPageComponent,
    TecnicaPageComponent,
    CalendarioComponent,
    PrincipalPageComponent,
    ConfiguracionPageComponent,
    CatalogosPageComponent,
    EstadosComponent,
    MunicipiosComponent,
    LocalidadesComponent,
    ConfiguracionComponent,
    PerfilComponent,
    PerfilPageComponent,
    PersonaComponent,
    GetLocalidadesComponent,
    AutocompletPersonaComponent,
    AltaPersonaComponent,
    SelectTipoInvitacionComponent,
    AgendaPageComponent,
    DetallediaPageComponent,
    AgendaDiaComponent,
    AgendaprivadaPageComponent,
    AgendatecnicaPageComponent,
    OficiosTecnicaPageComponent,
    OficiosPrivadaPageComponent,
    AgendaPrivadaContenedorComponent,
    AgendaTecnicaContenedorComponent,
    DetalleDiaTecnicaContenedorComponent,
    DetalleDiaPrivadaContenedorComponent,
    DetalleAgendaComponent,
    EventosPageComponent,
    AgendaEventoContenedorComponent,
    AgendaeventosPageComponent,
    AgendaeventosHoyPageComponent,
    AgendaeventosMananaPageComponent,
    DetalleDiaEventoContenedorComponent,
    ContactosAgendaComponent,
    AdjuntosAgendaComponent,
    OficioInvitacionComponent,
    UsuariosComponent,
    AltaAgendaComponent,

    PrensaPageComponent,
    AgendaPrensaContenedorComponent,
    DetalleDiaPrensaContenedorComponent,
    AgendaprensaPageComponent,
    OficiosPrensaPageComponent,
    ColoresAgendaComponent,
    SelectRutaComponent,
    GirasComponent,
    ReportesComponent,
    AcompanantesComponent,
    ListaAcompanantesComponent,
    Acompanantes2Component,
    Acompanantes3Component,
    CapitalizarPipe,
    CalendarioMovilComponent,
    MovilComponent,
    AtencioncComponent,
    SafeUrlPipe,
    EventosContenedorComponent,
    SeguimientoOficiosComponent,
    ListaAcompanantesExternosComponent,
    AltaOficioComponent,
    LoaderComponent


  ],
  imports: [
    BrowserModule,
    HttpModule,
    FormsModule,
    app_routing,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    CalendarModule,
    AutoCompleteModule,
    AmazingTimePickerModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: "es"},
    CctService,
    OficiosService,
    DependenciaService,
    SecretariaService,
    SecretarioService,
    AgendaService,
    EstadoService,
    MunicipioService,
    LocalidadService,
    FuncionarioService,
    CiudadanoService,
    EstadoCivilService,
    UsuarioService,
    ContactoService,
    AdjuntoService,
    TipoarchivoService,
    GiraService,
    LoginGuard,
    PrivadaGuard,
    TecnicaGuard,
    OficialiaGuard,
    PrensaGuard,
    SeguimientoGuard,
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
