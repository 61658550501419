export class adjuntoModel{
  idadjuntos: string;
  idtipoarchivo: string;
  idagenda: string;
  descripcion: string;
  archivo: string;
  tipoarchivo:string;
  tipoarchivo2: string;
  idarchivo: string;
}
