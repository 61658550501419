// tslint:disable-next-line:class-name
export class notificacion {

  tiempo = 4000;

  success(titulo: string, mensaje: string) {
    noty({ title: titulo, text: mensaje, layout: 'topRight', type: 'success', timeout: this.tiempo });
  }

  simpleSuccess(mensaje: string) {
    noty({ text: mensaje, layout: 'topRight', type: 'success', timeout: this.tiempo });
  }

  info(titulo: string, mensaje: string) {
    noty({ title: titulo, text: mensaje, layout: 'topRight', type: 'info', timeout: this.tiempo });
  }

  simpleInfo(mensaje: string) {
    noty({ text: mensaje, layout: 'topRight', type: 'info', timeout: this.tiempo });
  }

  error(titulo: string, mensaje: string) {
    noty({ title: titulo, text: mensaje, layout: 'topRight', type: 'error', timeout: this.tiempo });
  }

  simpleError(mensaje: string) {
    // tslint:disable-next-line:no-unused-expression
    noty({ text: mensaje, layout: 'topRight', type: 'error', timeout: this.tiempo });
  }

}
