import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { respuesta } from '../../models/respuesta';
import { agendaModel } from '../../models/agenda';
import { Funcionario } from '../../models/Funcionario';
import { AgendaService } from '../../services/agenda.service';
import { Usuario } from '../../models/Usuario';


@Component({
  selector: 'app-acompanantes3',
  templateUrl: './acompanantes3.component.html'
})
export class Acompanantes3Component implements OnInit, OnChanges {

  @Input() agenda: agendaModel;
  @Input() titulo: string = 'Acompañante';

  resp: respuesta;
  acompanante: Funcionario;
  acompanantes: Funcionario[] = [];
  tipoPersona = 'FUNCIONARIO';

  constructor(private _agendaService: AgendaService) { }

  ngOnInit() {
    this.resp = new respuesta();
    this. acompanante = new Funcionario();
  }

  ngOnChanges(){
    if(this.titulo==='Acompañante'){
      this.getAcompanantes();
    }else{
      this.getRepresentantes();
    }
  }


  getAcompanantes() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.getAcompanantes3().subscribe(
      data => {
        this.resp = data;

        this.acompanantes = this.resp.data;
        console.log(data);
      }
    );
  }

  getRepresentantes() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.getRepresentante().subscribe(
      data => {
        this.resp = data;

        this.acompanantes = this.resp.data;
        console.log(data);
      }
    );
  }
  
  guardaAcompanamiento() {
    if(this.titulo==='Acompañante'){
      this.guardaAcompanante();
    }else{
      this.guardaRepresentante();
    }
  }

  guardaAcompanante() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.idfuncionario = this.acompanante.idpersona;
    this._agendaService.guardaAcompanantes3().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          swal("Guardada", this.resp.mensaje, "info");
        }else{
          swal("Error", this.resp.mensaje, "error");
        }
        this.getAcompanantes();
        
      }
    );
  }

  guardaRepresentante() {
    this._agendaService.objAgenda = this.agenda;
    this._agendaService.idfuncionario = this.acompanante.idpersona;
    this._agendaService.guardaRepresentante().subscribe(
      data => {
        this.resp = data;
        if (!this.resp.error) {
          swal("Guardada", this.resp.mensaje, "info");
        }else{
          swal("Error", this.resp.mensaje, "error");
        }
        this.getRepresentantes();
        
      }
    );
  }

  eliminaAcompanante(id){
    if (confirm("Estas seguro de eliminar este Acompañante de Evento?")) {
      this._agendaService.idfuncionario = id;
      this._agendaService.objAgenda = this.agenda;
      this._agendaService.eliminaAcompanante3().subscribe(
        data => {
          this.resp = data;
          if (this.resp.error) {
            alert(this.resp.mensaje);
          }
          this.getAcompanantes();
          console.log(data);
        }
      );
    }
  }


  getFuncionarioSeleccionado(funcionario: Usuario) {
    console.log('Persona Seleccionada: ' + funcionario);
    this.acompanante = funcionario;
    
  }

}
