import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detalledia-page',
  templateUrl: './detalledia-page.component.html'
})
export class DetallediaPageComponent implements OnInit {

  fecha: string;

  constructor() {
    this.fecha = localStorage.getItem("fechaA");
  }

  ngOnInit() {
  }

}
