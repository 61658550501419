export class Gira {
    idgira: string;
    gira: string;
    fechaInicio: string;
    fechaFin: string;
    estatus: string;

    constructor(){
        this.idgira='';
        this.gira = '';
        this.fechaInicio = '';
        this.fechaFin = '';
        this.estatus = '';

    }
}
