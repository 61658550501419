import { Component, OnInit } from '@angular/core';
import { Usuario } from '../../models/Usuario';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class NavbarComponent implements OnInit {

  usuario: Usuario;
  fecha: Date;

  constructor(private router: Router, private cookieService: CookieService) {
    this.usuario = new Usuario();
    this.fecha = new Date();
  }

  ngOnInit() {
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
    //console.log(this.usuario);
  }

  logout(){
    localStorage.clear();
    this.cookieService.delete( 'SAG_Atencion');
    this.cookieService.delete( 'SAG');
    this.cookieService.delete( 'Test');

    window.location.reload();
    //this.router.navigate(['/']);
  }

}
