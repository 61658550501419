import { Component, OnInit, Input } from '@angular/core';
import { agendaModel } from '../../models/agenda';
import { respuesta } from '../../models/respuesta';
import { AgendaService } from '../../services/agenda.service';
import { Router } from '@angular/router';
import { Usuario } from '../../models/Usuario';
import { notificacion } from '../../models/notificacion';
import { Gira } from '../../models/Gira';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html'
})
export class ReportesComponent implements OnInit {

  titulo: string = 'Reportes por Fecha';

  @Input() fechaIni: string;
  @Input() fechaFin: string;
  @Input() tipoReporte: string;
  @Input() gira: Gira;

  activa: boolean = false;
  tabla: any;
  escogeGira: boolean = false;
  girasActivas: boolean = false;

  txt: string = '';

  agendasSeleccionadas: string [] = [];

  tituloReporte: string;

  agenda: agendaModel;
  agendas: agendaModel[] = [];
  resp: respuesta;

  usuario: Usuario;

  descargaPDF = 'https://agenda.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga2.php?descarga=1&fechaI='+this.fechaIni+'&fechaF=' + this.fechaFin;
  puedeDes = false;

  constructor(private _agendaService: AgendaService, private router: Router) {
    this.agenda = new agendaModel();
    console.log(this.agenda);
    this.resp = new respuesta();

    this.usuario = new Usuario();
    this.usuario = JSON.parse(localStorage.getItem('usuario'));
  }

  ngOnInit() {
    this.fechaIni = new Date().toJSON().split('T')[0];
    this.fechaFin = new Date().toJSON().split('T')[0];


  }


  getReporte(){
    this.puedeDes = true;
    //this.descargaPDF = 'https://agenda.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga2.php?descarga=1&fechaI='+this.fechaIni+'&fechaF=' + this.fechaFin;
    //console.log(this.fechaIni);
    //this.agenda.fecha = this.fecha;
    this.tituloReporte = 'Del ' + this.fechaIni + " al " + this.fechaFin;
    this._agendaService.fechaIni = this.fechaIni;
    this._agendaService.fechaFin = this.fechaFin;
    this._agendaService.txt = this.txt;

    this.agendas = [];
    this.activa = false;
    if(this.tabla){
      this.tabla.destroy();
    }

    this._agendaService.getReporte().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        this.cargarArregloAgendas();
        if (this.resp.error) {
          // alert(this.resp.mensaje);
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }
        /*if (this.agendas.length === 0) {
          this.inicializaTabla();
        }*/
        setTimeout(() => {
          this.inicializaTabla();
        }, 200);

      }
    );
  }

  getReporteGira(){
    this._agendaService.gira = this.gira;
    this.tituloReporte = this.gira.gira;
    this.agendas = [];
    this.activa = false;
    if(this.tabla){
      this.tabla.destroy();
    }

    this._agendaService.getReporteGira().subscribe(
      data => {
        this.resp = data;
        this.agendas = this.resp.data;
        console.log(this.agendas);
        if (this.resp.error) {
          new notificacion().simpleError(this.resp.mensaje);
          //swal("Cargado con Exito!", this.resp.mensaje, "success");
        }

        setTimeout(() => {
          this.inicializaTabla();
        }, 100);

      }
    );
  }



  detalleAgenda(index: number){
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    console.log('Agenda:' + this.agenda);

    $('#detalleAgendaModal').modal('show');
    $('body').removeClass('modal-open');
    $('.modal-backdrop').remove();
  }


  cancelarAgenda(id) {
    swal({
      title: "Quieres cancelar este Evento?",
      text: "Una vez cancelado no podras recuperarlo!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        this.agenda = this.agendas[id];
        $('#observacionCancelarModal').modal('show');
      }
    });

  }





  inicializaTabla() {
    if ( !this.activa ) {
      let titulo = this.tituloReporte;

      this.tabla = $('#data-table-1').DataTable({
        'sPaginationType': 'full_numbers',
        'dom': 'Blfrtip',
        "order": [],
        'buttons': [
          {
            extend:    'excelHtml5',
            text:      '<i class="fa fa-file-excel-o"></i> Excel',
            titleAttr: 'Excel',
            exportOptions: {
              columns: [ 0, 1 ]
            },
            title: "C.P. Antonio Echevarría García <br> Agenda de Gobieno (" + this.tituloReporte + ")",

          },
          {
            extend:    'print',
            text:      '<i class="fa fa-print"></i> Imprimir',
            titleAttr: 'Imprimir',
            customize: function ( win ) {
              $(win.document.body).css( 'font-size', '8pt' ).prepend('<img src="https://agenda.nayarit.gob.mx//ssai_v2_5/logoGobierno.png" width="60" style="position:absolute; top:0; left:0;" />');
              $(win.document.body).find( 'table' ).addClass( 'compact' ).css( 'font-size', 'inherit' );
            },
            title: "<h3>C.P. Antonio Echevarría García <br> Agenda de Gobieno (" + this.tituloReporte + ")</h3>",
            exportOptions: {
              columns: [ 0, 1 ],
              stripHtml: false
            }
          }
        ],
        'language': {
          'search': 'Buscar:',
          'info': 'Mostrando del _START_ al _END_ de _TOTAL_ registros',
          'infoEmpty': 'Mostrando del 0 al 0 de 0 Registros',
          'lengthMenu': 'Mostrar _MENU_ registros',
          'paginate': {
            'first': 'Primero',
            'last': 'Ultimo',
            'next': 'Siguiente',
            'previous': 'Anterior'
        }
      }
      });
      this.activa = true;
    }
  }



  getAgendaGuardada(event: agendaModel){
    //alert('Agenda Modigicada');
    //this.agendas = [];
    this.getReporte();
  }

  editarAgenda(index: number){
    this.agenda = new agendaModel();
    this.agenda = this.agendas[index];
    console.log(this.agenda);


    $('#altaInvitacionModal').modal('show');
    //$('body').removeClass('modal-open');
    //$('.modal-backdrop').remove();
  }

  tomaFechaIni(){
    console.log('Fech Inicio: ' + new Date(this.fechaIni).getTime());
    console.log('Fech Fin: ' + new Date(this.fechaFin).getTime());
    if(new Date(this.fechaIni).getTime() > new Date(this.fechaFin).getTime()){
      this.fechaFin = this.fechaIni;
    }
  }

  getGiraSeleccionada(event: Gira){
    this.gira = event;
    console.log(event);
  }

  formatDate(obj) {
    return obj.toString().replace(/\s/g, "T");
  }


  changed = (evt) => {
    //this.isChecked = evt.target.checked;
    let index = -1;
    if(!evt.target.checked){
      index = this.agendasSeleccionadas.indexOf(evt.target.defaultValue);
      if(index>-1){
        this.agendasSeleccionadas.splice(index, 1);
      }
    }else{
      this.agendasSeleccionadas.push(evt.target.defaultValue);
    }
    //console.log(evt.target.checked);
    //console.log(evt.target.defaultValue);
    //console.error("Agendas Seleccionadas: " + this.agendasSeleccionadas);
    this.descargaPDF = 'https://agenda.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga2.php?descarga=1&fechaI=' + this.fechaIni + '&fechaF=' + this.fechaFin + "&agendas=" + this.agendasSeleccionadas.toString();
  }

  cargarArregloAgendas(){
    this.agendas.forEach(agenda => {
      this.agendasSeleccionadas.push(agenda.idagenda);
    });
    this.descargaPDF = 'https://agenda.nayarit.gob.mx/SAG_V4/restful/reporteAgenda/descarga2.php?descarga=1&fechaI=' + this.fechaIni + '&fechaF=' + this.fechaFin + "&agendas=" + this.agendasSeleccionadas.toString();
    console.error("Agendas Seleccionadas: " + this.agendasSeleccionadas);
  }

}
