import { Component, OnInit, Input } from '@angular/core';
import { agendaModel } from '../../models/agenda';

@Component({
  selector: 'app-detalle-agenda',
  templateUrl: './detalle-agenda.component.html'
})
export class DetalleAgendaComponent implements OnInit{
  @Input() agenda: agendaModel;

  constructor() {
    console.log("Agenda Detalle: " + this.agenda);
    
  }

  ngOnInit() {
  }


}
